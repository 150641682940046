import { gql } from '@apollo/client';

export const GET_ARGUMENTS = gql`
  query ArgumentsAdmin(
    $filter: ListArgumentsFilter
    $sort: [ListArgumentsSort!]
  ) {
    argumentsAdmin(filter: $filter, sort: $sort) {
      count
      data {
        category
        id
        description
        createdAt
        issuePeriod
        pdfUrl
        region
        slug
        status
        title
        startDate
        endDate
      }
    }
  }
`;

export const GET_ARGUMENT = gql`
  query ArgumentAdmin($where: ArgumentIdWhereUniqueInput!) {
    argumentAdmin(where: $where) {
      category
      category
      createdAt
      description
      id
      issuePeriod
      pdfUrl
      region
      slug
      status
      title
      startDate
      endDate
    }
  }
`;

export const ARGUMENT_PDF_SIGNED_URL = gql`
  query GetArgumentPdfFileUploadSignedUrl(
    $data: GetPdfFileUploadSignedUrlInput!
  ) {
    getArgumentPdfFileUploadSignedUrl(data: $data) {
      key
      signedUrl
    }
  }
`;
