import { ArrowLeftOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Form } from 'antd';
import { React, useEffect, useState } from 'react';
import { ROUTES, TRANSACTION_TYPE } from '../../common/constants';
import useRouter from '../../hooks/useRouter';
import TransactionForm from './components/TransactionForm';
import { EDIT_TRANSACTION } from './graphql/mutations';
import { GET_TRANSACTION } from './graphql/queries';

const initialValues = {
  userId: {},
  type: '',
  itemId: {},
  status: '',
  amount: '',
  billing: {},
};

export default function EditTransaction() {
  const { params, navigate } = useRouter();
  const { id, userId } = params;
  const [role, setRole] = useState(null);

  const [form] = Form.useForm();
  const [isPaymentCanceled, setIsPaymentCanceled] = useState(false);

  const getBillingFrom = ({ transactionDetail, type }) => {
    switch (type) {
      case TRANSACTION_TYPE.TEMPLATE:
        return transactionDetail?.template?.title;
      case TRANSACTION_TYPE.SUBSCRIPTION:
        return transactionDetail?.subscriptionPlan?.title;
      case TRANSACTION_TYPE.CALL:
        return transactionDetail?.consultationCall?.title;
      default:
        break;
    }
  };

  const [getTransactionDetails, { data }] = useLazyQuery(GET_TRANSACTION, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const transactionDetail = res?.transactionLogAdmin;
      if (transactionDetail?.reason) {
        setIsPaymentCanceled(true);
      }
      const formValues = {
        userId: transactionDetail?.user?.firstName
          ? `${transactionDetail?.user?.firstName} ${transactionDetail?.user?.lastName}`
          : `${transactionDetail?.billing?.firstName} ${transactionDetail?.billing?.lastName}`,
        itemId: getBillingFrom({
          transactionDetail,
          type: transactionDetail?.type,
        }),
        status: transactionDetail?.status,
        type: transactionDetail?.type,
        amount: transactionDetail?.amount,
        reason: transactionDetail?.reason,
        billing: transactionDetail?.billing,
      };
      setRole(transactionDetail?.user?.roles);
      form.setFieldsValue(formValues);
    },
    onError() {},
  });

  const [editTransaction, { loading: updateLoading }] = useMutation(
    EDIT_TRANSACTION,
    {
      fetchPolicy: 'network-only',
      onCompleted: () => {
        navigate(ROUTES?.TRANSACTION_LOGS);
      },
      onError() {},
    },
  );

  useEffect(() => {
    getTransactionDetails({
      variables: {
        data: {
          id,
          userId,
        },
      },
    });
  }, [id, userId]);

  const onFinish = (values) => {
    if (id) {
      editTransaction({
        variables: {
          where: { id },
          data: {
            status: values?.status,
            reason: values?.status === 'CANCELED' ? values?.reason : '',
          },
        },
      });
    }
  };

  return (
    <>
      <div className="title">
        <Button
          type="text"
          shape="circle"
          onClick={() => navigate(ROUTES.TRANSACTION_LOGS)}
          icon={<ArrowLeftOutlined />}
        />
        Edit Transaction
      </div>
      {data && (
        <TransactionForm
          role={role}
          form={form}
          id={id}
          onFinish={onFinish}
          loading={updateLoading}
          initialValues={initialValues}
          setIsPaymentCanceled={setIsPaymentCanceled}
          isPaymentCanceled={isPaymentCanceled}
        />
      )}
    </>
  );
}
