import { gql } from '@apollo/client';

export const UPDATE_CONFIG = gql`
  mutation UpdateConfig(
    $data: UpdateConfigInput!
    $where: ConfigWhereUniqueInput!
  ) {
    updateConfig(data: $data, where: $where) {
      data {
        id
        key
        value
      }
      message
    }
  }
`;

export const ADD_PAYMENT_DISTRIBUTION = gql`
  mutation UpdatePaymentDistribution(
    $data: updatePaymentDistributionInput!
    $where: PaymentDistributionWhereInput!
  ) {
    updatePaymentDistribution(data: $data, where: $where) {
      data {
        consultationCall {
          type
          value
        }
        paidPremiumBasedCommission
        service
        referral
        subscriptionBasedCommission
        type
      }
      message
    }
  }
`;
