import { useLazyQuery } from '@apollo/client';
import { Button, Table, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  LIMIT,
  MODULES,
  PAGE_SIZE_OPTIONS,
  ROUTES,
} from '../../common/constants';
import SearchComponent from '../../components/SearchComponent';
import { LIST_SUBSCRIPTION_PLANS } from './graphql/queries';

const { Title } = Typography;
function SubscriptionPlansList() {
  const [filter, setFilter] = useState({ limit: LIMIT, skip: 1, search: '' });
  const [sort, setSort] = useState([{ sortBy: 'DESC', sortOn: 'title' }]);

  const [listPlans, { data: plansData, loading }] = useLazyQuery(
    LIST_SUBSCRIPTION_PLANS,
    {
      fetchPolicy: 'network-only',
      onError() {},
    },
  );

  useEffect(() => {
    const { skip, limit, search, status } = filter;
    listPlans({
      variables: {
        filter: {
          skip: (skip - 1) * limit,
          limit,
          search,
          status,
        },
        sort,
      },
    });
  }, [filter, sort]);

  const handleTableChange = (pagination, filters, sorter) => {
    const prepareFilter = filter;
    if (filters.status === null) {
      delete prepareFilter?.status;
    } else {
      prepareFilter.status = filters?.status?.[0];
    }
    setFilter({
      ...prepareFilter,
      skip: pagination?.current,
      limit: pagination?.pageSize,
    });
    setSort({
      sortOn: sorter?.order ? sorter?.field : 'title',
      sortBy: sorter?.order === 'ascend' ? 'ASC' : 'DESC',
    });
  };

  const handleSearchChange = (value) => {
    setFilter({ ...filter, skip: 1, search: value });
  };

  const sortOrder = sort.sortBy === 'ASC' ? 'ascend' : 'descend';

  const columns = [
    {
      title: 'Title',
      sorter: true,
      sortOrder: sort.sortOn === 'title' ? sortOrder : null,
      dataIndex: 'title',
    },

    {
      title: 'Price',
      sorter: true,
      sortOrder: sort.sortOn === 'price' ? sortOrder : null,
      dataIndex: 'price',
      render: (item) => `₹ ${item}`,
    },
    {
      title: 'Features',
      render: (item) => item?.featureList?.join(', '),
    },
    {
      title: 'Type',
      dataIndex: 'type',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'action',
      render: (item) => (
        <Button type="link">
          <Link to={`${ROUTES.SUBSCRIPTION_PLANS}/edit/${item?.id}`}>Edit</Link>
        </Button>
      ),
    },
  ];

  return (
    <>
      <Title className="site-page-header p-0 mb-8 mt-0" level={3}>
        {MODULES.SUBSCRIPTION_PLANS}
        {plansData?.subscriptionPlansAdmin?.count
          ? ` (${plansData?.subscriptionPlansAdmin?.count})`
          : ''}
      </Title>
      <div className="filter-input mb-12">
        <SearchComponent className="list-search" getData={handleSearchChange} />
      </div>
      <Table
        columns={columns}
        rowKey={(record) => record?.id}
        dataSource={plansData?.subscriptionPlansAdmin?.data}
        pagination={{
          total: plansData?.subscriptionPlansAdmin?.count,
          current: filter.skip,
          pageSize: filter.limit,
          pageSizeOptions: PAGE_SIZE_OPTIONS,
        }}
        loading={loading}
        scroll={{ y: `calc(100vh - 342px)` }}
        onChange={handleTableChange}
      />
    </>
  );
}

export default SubscriptionPlansList;
