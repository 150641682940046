import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const GET_NOTIFICATIONS = gql(`
query notifications($sort: [ListNotificationsSort!], $filter: ListNotificationsFilter) {
  notifications(sort: $sort, filter: $filter) {
    count
    data {
      id
      title
      content
      receiver {
        id
        firstName
        email
        lastName
        phoneNumber
        roles
        profileImage
      }
      sender {
        id
        firstName
        phoneNumber
        lastName
        roles
        profileImage
        email
      }
      refData
      readAt
      senderType
      createdAt
    }
  }
}`);

export const UPDATE_SHOW_ON_PAGE = gql`
  mutation UpdateShowOnWeb(
    $data: UpdateShowOnWebInput!
    $where: ShowOnWhereInput!
  ) {
    updateShowOnWeb(data: $data, where: $where) {
      message
    }
  }
`;
