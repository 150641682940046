import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import KeywordsList from './KeywordsList';

const KeywordWrapper = () => (
  <Routes>
    <Route path={ROUTES.MAIN} exact element={<KeywordsList />} />
  </Routes>
);
export default KeywordWrapper;
