import { gql } from '@apollo/client';

export const CREATE_KEYWORD = gql`
  mutation CreateKeywordAdmin($data: CreateKeywordInput!) {
    createKeywordAdmin(data: $data) {
      message
    }
  }
`;

export const UPDATE_KEYWORD = gql`
  mutation UpdateKeywordAdmin(
    $data: UpdateKeywordInput!
    $where: KeywordIdWhereUniqueInput!
  ) {
    updateKeywordAdmin(data: $data, where: $where) {
      message
    }
  }
`;
