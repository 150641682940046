import { gql } from '@apollo/client';

export const GET_CATEGORIES = gql`
  query TemplateCategoriesAdmin(
    $filter: AdminListTemplateCategoriesFilter
    $sort: [ListTemplateCategoriesSort!]
    $where: TemplateCategoryWhereUniqueInput
  ) {
    templateCategoriesAdmin(filter: $filter, sort: $sort, where: $where) {
      data {
        id
        key
        name
        slug
        order
      }
      count
    }
  }
`;

export const GET_CATEGORY = gql`
  query TemplateCategory($where: TemplateCategorySlugWhereInput!) {
    templateCategory(where: $where) {
      id
      key
      name
      slug
    }
  }
`;
