import { SearchOutlined } from '@ant-design/icons';
import { Badge, Input, Layout, Tabs } from 'antd';
import React, { useState } from 'react';
import { CHAT_TABS } from '../../common/constants';
import './chat.less';
import ChatHeader from './components/ChatHeader';
import ChatList from './components/ChatList';
import MemberList from './components/MemberList';
import MessageRenderer from './components/MessageRenderer';

const { Sider, Content } = Layout;
function Chat() {
  const [selectedId, setSelectedId] = useState();
  const [selectedService, setSelectedService] = useState({
    id: '',
    name: '',
  });
  const [selectedTab, setSelectedTab] = useState(CHAT_TABS.SERVICE);
  const [search, setSearch] = useState('');
  const [isOpenInfoPopup, setIsOpenInfoPopup] = useState(false);
  const [notificationCount, setNotificationCount] = useState({
    service: 0,
    consultant: 0,
  });

  const tabItems = [
    {
      label: (
        <>
          Services{' '}
          <Badge
            count={notificationCount.service}
            className="pl-8 badge-success"
          />
        </>
      ),
      key: 'Services',
      children: (
        <ChatList
          setSelectedId={setSelectedId}
          selectedId={selectedId}
          type="SERVICE"
          key={CHAT_TABS.SERVICE}
          setNotificationCount={setNotificationCount}
          notificationCount={notificationCount}
          search={search}
          selectedTab={selectedTab}
          setSelectedService={setSelectedService}
        />
      ),
    },
    {
      label: (
        <>
          Consultation{' '}
          <Badge
            count={notificationCount?.consultant}
            className="pl-8 badge-success"
          />
        </>
      ),
      key: 'Consultation',
      children: (
        <ChatList
          setSelectedId={setSelectedId}
          selectedId={selectedId}
          type="CONSULTATION"
          key={CHAT_TABS.CALL}
          setNotificationCount={setNotificationCount}
          notificationCount={notificationCount}
          search={search}
          selectedTab={selectedTab}
          setSelectedService={setSelectedService}
        />
      ),
    },
  ];

  const handleTabChange = (e) => {
    setSelectedTab(
      e === CHAT_TABS.SERVICE ? CHAT_TABS.SERVICE : CHAT_TABS.CALL,
    );
  };

  return (
    <Layout className="chat-wrapper">
      <Sider width={300} className="chat-sider">
        <div className="full-width d-flex flex-vertical align-center gap-12 pt-16">
          <div className="full-width pr-8 pl-8">
            <Input
              placeholder="Search.."
              suffix={<SearchOutlined className="chat-search" />}
              onChange={(e) => setSearch(e?.target?.value)}
            />
          </div>
          <Tabs
            className="full-width pr-8 pl-8"
            defaultActiveKey="1"
            items={tabItems}
            size="middle"
            onChange={(e) => handleTabChange(e)}
          />
        </div>
      </Sider>
      <Content className="d-flex flex-vertical">
        {selectedId && (
          <div>
            <ChatHeader
              selectedTab={selectedTab}
              conversationId={selectedId}
              selectedService={selectedService}
            />
            <MessageRenderer />
          </div>
        )}
      </Content>
      <MemberList
        isOpen={isOpenInfoPopup}
        handleClosePopup={() => setIsOpenInfoPopup(false)}
      />
    </Layout>
  );
}

export default Chat;
