import { gql } from '@apollo/client';

export const EXPORT_REPORTS = gql`
  query exportReport(
    $data: ExportReportData!
    $where: ExportReportWhereUnique
  ) {
    exportReport(data: $data, where: $where)
  }
`;

export const ADMIN_REPORTS = gql`
  query adminReports(
    $filter: ListAdminReportsFilter!
    $sort: [ListAdminReportsSort!]!
  ) {
    adminReports(filter: $filter, sort: $sort) {
      count
      data {
        fileKey
        id
        status
        filter {
          where {
            consultantId
          }
          data
          consultant {
            firstName
            lastName
            email
          }
        }
      }
    }
  }
`;
