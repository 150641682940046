import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import PaymentDistributionList from './PaymentDistributionList';

const PaymentDistributionWrapper = () => (
  <Routes>
    <Route path={ROUTES.MAIN} exact element={<PaymentDistributionList />} />
  </Routes>
);
export default PaymentDistributionWrapper;
