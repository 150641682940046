import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import LawyersList from './LawyersList';

const LawyerWrapper = () => (
  <Routes>
    <Route path={ROUTES.MAIN} exact element={<LawyersList />} />
  </Routes>
);
export default LawyerWrapper;
