import { useMutation } from '@apollo/client';
import { Button, Form, Input, Modal } from 'antd';
import React from 'react';
import { CREATE_MILESTONE_MUTATION } from '../graphql/mutations';

function MilestonePaymentForm({
  setMileStonePaymentPopup,
  mileStonePaymentPopup,
  refetch,
  conversationId,
  selectedService,
}) {
  const [createMilestonePayment] = useMutation(CREATE_MILESTONE_MUTATION, {
    fetchPolicy: 'network-only',
  });
  const [form] = Form?.useForm();

  const onFinish = (values) => {
    createMilestonePayment({
      variables: {
        data: {
          amount: Number(values?.amount),
          chatId: conversationId,
          description: values?.description,
          servicePurchaseId: selectedService?.id,
        },
      },
      onCompleted: () => {
        form?.resetFields();
        setMileStonePaymentPopup(false);
        refetch();
      },
      onError: () => {
        form?.resetFields();
      },
    });
  };
  return (
    <Modal
      onOk={() => form?.submit()}
      open={mileStonePaymentPopup}
      width={512}
      title="Milestone payment"
      onCancel={() => {
        setMileStonePaymentPopup(false);
        form?.resetFields();
      }}
      footer={[
        <Button
          className=" milestone-popup-btn-width"
          size="small"
          key="cancel"
          onClick={() => {
            setMileStonePaymentPopup(false);
            form?.resetFields();
          }}
        >
          Cancel
        </Button>,
        <Button
          className=" milestone-popup-btn-width"
          size="small"
          type="primary"
          key="send"
          onClick={() => form?.submit()}
        >
          Send
        </Button>,
      ]}
    >
      <div className="d-flex flex-vertical gap-24 ">
        <Form
          onFinish={onFinish}
          form={form}
          layout="vertical"
          className="submit-template-form"
        >
          <Form.Item
            label="Payment Amount"
            name="amount"
            rules={[
              {
                required: true,
                message: 'Please enter Payment Amount',
              },
            ]}
          >
            <Input type="number" placeholder="Enter Payment Amount" />
          </Form.Item>
          <Form.Item
            label="Payment Description"
            name="description"
            rules={[
              {
                required: true,
                message: 'Please enter Payment Description',
              },
            ]}
          >
            <Input.TextArea rows={5} placeholder="Enter Description" />
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
}

export default MilestonePaymentForm;
