import { gql } from '@apollo/client';

export const CREATE_CATEGORY = gql`
  mutation CreateTemplateCategory($data: CreateTemplateCategoryInput!) {
    createTemplateCategory(data: $data) {
      data {
        id
        key
        name
        slug
      }
      message
    }
  }
`;

export const UPDATE_CATEGORY_AND_SUB_CATEGORY = gql`
  mutation UpdateTemplateCategory(
    $data: UpdateTemplateCategoryInput!
    $where: TemplateCategoryWhereUniqueInput!
  ) {
    updateTemplateCategory(data: $data, where: $where) {
      message
    }
  }
`;

export const CREATE_SUB_CATEGORY = gql`
  mutation CreateTemplateSubCategory($data: CreateSubTemplateCategoryInput!) {
    createTemplateSubCategory(data: $data) {
      message
    }
  }
`;

export const DELETE_CATEGORY = gql`
  mutation DeleteTemplateCategory(
    $where: DeleteTemplateCategoryWhereUniqueInput!
  ) {
    deleteTemplateCategory(where: $where) {
      message
    }
  }
`;

export const UPDATE_CATEGORY_ORDER = gql`
  mutation UpdateTemplateCategoryOrder(
    $where: UpdateTemplateCategoryOrderWhereUnique!
    $data: UpdateTemplateCategoryOrderData!
  ) {
    updateTemplateCategoryOrder(where: $where, data: $data) {
      message
    }
  }
`;
