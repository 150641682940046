import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import CreateEditNotification from './CreateEditNotification';
import NotificationList from './NotificationList';

const NotificationsWrapper = () => (
  <Routes>
    <Route path={ROUTES.MAIN} exact element={<NotificationList />} />
    <Route path={ROUTES.CREATE} exact element={<CreateEditNotification />} />
    <Route path={ROUTES.EDIT} exact element={<CreateEditNotification />} />
  </Routes>
);
export default NotificationsWrapper;
