import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Form } from 'antd';
import React, { useEffect, useState } from 'react';
import { mongoClient } from '../../apollo';
import { ROUTES } from '../../common/constants';
import useRouter from '../../hooks/useRouter';
import AssesseeForm from './components/AssesseeForm';
import { EDIT_ASSESSEE_DETAILS } from './graphql/mutations';
import { GET_ASSESSEE_DETAILS } from './graphql/queries';

const defaultFormValue = {
  name: '',
  initialOfSurnameOrTitle: '',
  pancard: '',
  type: '',
  category: [],
  slug: '',
  court: [],
};

export default function EditAssesses() {
  const { params, navigate } = useRouter();
  const { id } = params;
  const [updateLoading, setUpdateLoading] = useState(false);

  const [form] = Form.useForm();

  const getAssessee = async ({ variables }) => {
    await mongoClient
      ?.query({
        query: GET_ASSESSEE_DETAILS,
        fetchPolicy: 'network-only',
        variables,
      })
      ?.then((res) => {
        form.setFieldsValue(res?.data?.assesseeAdmin);
      });
  };

  const updateAssessee = async ({ variables }) => {
    await mongoClient
      ?.mutate({
        mutation: EDIT_ASSESSEE_DETAILS,
        variables,
      })
      ?.then(() => {
        setUpdateLoading(false);
        navigate(ROUTES.ASSESSES);
      })
      ?.catch(() => {
        setUpdateLoading(false);
      });
  };

  useEffect(() => {
    if (id) {
      getAssessee({ variables: { where: { id } } });
    }
  }, [id]);

  const onFinish = (values) => {
    setUpdateLoading(true);
    if (id) {
      updateAssessee({
        variables: {
          where: { id },
          data: { ...values },
        },
      });
    }
  };
  return (
    <>
      <div className="title">
        <Button
          type="text"
          shape="circle"
          onClick={() => navigate(ROUTES.ASSESSES)}
          icon={<ArrowLeftOutlined />}
        />
        Edit Assessee
      </div>
      <AssesseeForm
        form={form}
        onFinish={onFinish}
        loading={updateLoading}
        defaultFormValue={defaultFormValue}
      />
    </>
  );
}
