import { gql } from '@apollo/client';

export const CREATE_SECTION = gql`
  mutation CreateSectionAdmin($data: CreateSectionInput!) {
    createSectionAdmin(data: $data) {
      message
    }
  }
`;

export const UPDATE_SECTION = gql`
  mutation UpdateSectionAdmin(
    $where: SectionIdWhereUniqueInput!
    $data: UpdateSectionInput!
  ) {
    updateSectionAdmin(where: $where, data: $data) {
      message
    }
  }
`;
