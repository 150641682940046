import {
  CheckCircleFilled,
  FileFilled,
  PaperClipOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Divider, Dropdown, Input, Upload } from 'antd';
import dayjs from 'dayjs';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { AppContext } from '../../../AppContext';
import {
  FileAttachIcon,
  MessageSendIcon,
  UploadTrashIcon,
} from '../../../assets/svg';
import {
  CALENDER_DATE_FORMATE,
  CONSULTANT_TYPE_ENUM,
  defaultDateFormat,
  LIMIT,
  MESSAGE_TYPE_ENUM,
  MILESTONE_STATUS_ENUM,
  SORT_OPTION,
  TIME_FORMAT,
} from '../../../common/constants';
import { beforeUploadDocFile, getFileSizeInMb } from '../../../common/utils';
import { SEND_MESSAGE_MUTATION } from '../graphql/mutations';
import {
  FETCH_CHAT_DETAILS,
  FETCH_MESSAGES,
  GET_ATTACHMENT_SIGNED_URL,
} from '../graphql/queries';
import AddConsultant from './AddConsultant';
import MilestonePaymentForm from './MilestonePaymentForm';
import ServiceCompletedPopUp from './ServiceCompletedPopUp';

function MessageRenderer({ conversationId, selectedTab, selectedService }) {
  const { currentUser } = useContext(AppContext);
  const userIdFromContext = currentUser?.id;

  const [messageInput, setMessageInput] = useState({
    text: '',
    file: null,
  });
  const [mileStonePaymentPopup, setMileStonePaymentPopup] = useState(false);
  const [messageList, setMessageList] = useState([]);
  const [isEnds, setIsEnds] = useState(false);
  const [loading, setLoading] = useState(true);
  const [menuBtnLoader, setMenuBtnLoader] = useState(true);
  const [isOpenServiceCompletePopup, setIsOpenServiceCompletePopup] = useState(
    false,
  );
  const [
    isOpenConsultantMemberManagePopup,
    setIsOpenConsultantMemberManagePopup,
  ] = useState(false);
  const [chatDetails, setChatDetails] = useState({});
  const [filters, setFilters] = useState({
    limit: LIMIT,
    search: null,
    skip: 0,
    type: selectedTab,
    chatId: conversationId,
  });

  const [getAttachmentSignedUrlCall] = useLazyQuery(GET_ATTACHMENT_SIGNED_URL);
  const [fetchChatDetails] = useLazyQuery(FETCH_CHAT_DETAILS);
  const [fetchMessages] = useLazyQuery(FETCH_MESSAGES, {
    fetchPolicy: 'network-only',
  });
  const chatContainerRef = useRef(null);
  const scrollPosition = (positionValue = null) => {
    if (chatContainerRef?.current) {
      chatContainerRef.current.scrollTop =
        positionValue || chatContainerRef?.current?.scrollHeight;
    }
  };
  const fetchMessagesCall = (filterVar = filters, isScroll = false) => {
    setLoading(true);
    fetchMessages({
      variables: {
        filter: filterVar,
        sort: [
          {
            sortBy: SORT_OPTION.DESC,
            sortOn: 'createdAt',
          },
        ],
      },
      onCompleted: ({ messages }) => {
        const msgList = messages?.data;
        const prepareMessages = msgList;
        if (isScroll) {
          setMessageList((prevMessages) => [
            ...prepareMessages,
            ...prevMessages,
          ]);
          if (messages?.count <= prepareMessages?.length) {
            setIsEnds(true);
          } else {
            scrollPosition(200);
          }
        } else {
          setMessageList([...prepareMessages]);
        }
        setLoading(false);
      },
    });
  };

  const [sendMessage] = useMutation(SEND_MESSAGE_MUTATION, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (filters?.skip === 0 && messageList?.length > 0) {
      scrollPosition();
    }
  }, [filters?.skip, messageList?.length]);

  useEffect(() => {
    fetchMessagesCall({
      limit: LIMIT,
      search: null,
      skip: 0,
      type: selectedTab,
      chatId: conversationId,
    });
    fetchChatDetails({
      variables: {
        data: {
          chatId: conversationId,
        },
      },
      onCompleted: ({ chat }) => {
        setChatDetails(chat);
        setMenuBtnLoader(false);
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversationId]);

  const handleScroll = async (event) => {
    const { target } = event;
    const { scrollTop } = target || {};
    const scrolledToTop = scrollTop < 20;
    if (scrolledToTop && !isEnds) {
      setLoading(true);
      const prepareFilter = filters;
      prepareFilter.skip = messageList?.length;
      setFilters({ ...{}, ...prepareFilter });
      fetchMessagesCall(prepareFilter, true);
    }
  };

  const items = [
    {
      key: '1',
      label: (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a
          rel="noopener noreferrer"
          onClick={() => setMileStonePaymentPopup(true)}
        >
          Milestone Payment
        </a>
      ),
      disabled: Boolean(
        chatDetails?.currentMember?.serviceConsultant?.type &&
          ![CONSULTANT_TYPE_ENUM.PRIMARY].includes(
            chatDetails?.currentMember?.serviceConsultant?.type,
          ),
      ),
    },
    {
      key: '2',
      label: (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a
          onClick={() => setIsOpenConsultantMemberManagePopup(true)}
          rel="noopener noreferrer"
        >
          Add Member
        </a>
      ),
      disabled: Boolean(
        chatDetails?.currentMember?.serviceConsultant?.type &&
          ![CONSULTANT_TYPE_ENUM.PRIMARY]?.includes(
            chatDetails?.currentMember?.serviceConsultant?.type,
          ),
      ),
    },
    {
      key: '3',
      label: (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a
          onClick={() => setIsOpenServiceCompletePopup(true)}
          rel="noopener noreferrer"
        >
          Service completed
        </a>
      ),
      disabled: Boolean(
        chatDetails?.currentMember?.serviceConsultant?.type &&
          ![CONSULTANT_TYPE_ENUM.PRIMARY].includes(
            chatDetails?.currentMember?.serviceConsultant?.type,
          ),
      ),
    },
  ];

  const sendMessageCall = (messageVariableObj) => {
    sendMessage({
      variables: { data: messageVariableObj },
      onCompleted: () => {
        const prepareInitialFilter = {
          limit: LIMIT,
          search: null,
          skip: 0,
          type: selectedTab,
          chatId: conversationId,
        };
        setMessageInput({ ...{}, text: '', file: null });
        fetchMessagesCall(prepareInitialFilter);
        setFilters({ ...{}, ...prepareInitialFilter });
        setIsEnds(false);
      },
      onError: () => {},
    });
  };

  const handleMessageSubmit = async (event) => {
    event.preventDefault();
    const preparePayload = {
      text: messageInput?.text,
      type: MESSAGE_TYPE_ENUM.TEXT,
      chatId: conversationId,
      attachmentKeys: null,
    };
    try {
      if (messageInput?.file && Object.keys(messageInput?.file)?.length > 0) {
        const uploadImagePromise = new Promise((resolve, reject) => {
          getAttachmentSignedUrlCall({
            fetchPolicy: 'network-only',
            variables: {
              data: {
                fileName: messageInput?.file?.name,
              },
            },
            onCompleted: async ({ getMessageAttachmentSignedUrl }) => {
              try {
                const response = await fetch(
                  `${getMessageAttachmentSignedUrl?.signedUrl}`,
                  {
                    method: 'PUT',
                    body: messageInput?.file,
                  },
                );
                if (response?.status === 200) {
                  preparePayload.attachmentKeys = [
                    {
                      fileName: messageInput?.file?.name,
                      key: getMessageAttachmentSignedUrl?.fileName,
                      fileSizeUnit: 'MB',
                      fileSize: getFileSizeInMb(messageInput?.file?.size ?? 0),
                    },
                  ];
                  resolve();
                }
              } catch (error) {
                reject();
              }
            },
            onError(error) {
              reject(error);
            },
          });
        });
        await uploadImagePromise;
      }
      sendMessageCall(preparePayload);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  useEffect(() => {
    const layoutContentHolder =
      // eslint-disable-next-line no-undef
      document?.getElementsByClassName('chat-box')?.[0];
    if (layoutContentHolder) {
      layoutContentHolder?.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (layoutContentHolder) {
        layoutContentHolder?.removeEventListener('scroll', handleScroll);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageList?.length]);

  const handleFileChange = async (info) => {
    const fileObj = info?.file?.originFileObj;
    setMessageInput({ ...messageInput, file: fileObj });
  };

  const messageRender = (item) => {
    switch (item?.messageType) {
      case MESSAGE_TYPE_ENUM.TEXT:
        return (
          <>
            {!item?.attachmentKeys ? (
              <div className="message-type-one p-16">
                <div className="message-user-info ">
                  {`${item?.user?.firstName}`}
                  {item?.user?.roles?.[0]}
                </div>
                {item?.text}
              </div>
            ) : (
              <div className="message-type-two p-16">
                <div className="message-user-info ">
                  {`${item?.user?.firstName}`}
                  {item?.user?.roles?.[0]}
                </div>
                <div className="body-base regular">{item?.text}</div>
                <div className="message-attachment d-flex flex-horizontal align-center gap-8 mt-8">
                  <div>
                    <FileFilled className="msg-file" />
                  </div>
                  <div className="d-flex flex-vertical justify-start">
                    <div className="body-base regular">
                      {item?.attachmentKeys?.[0]?.fileName}
                    </div>
                    <div className="body-meta regular file-size">{`${item?.attachmentKeys?.[0]?.fileSize}${item?.attachmentKeys?.[0]?.fileSizeUnit}`}</div>
                  </div>
                </div>
              </div>
            )}
          </>
        );
      case MESSAGE_TYPE_ENUM.MILESTONE:
        return (
          <>
            {item?.Milestone?.status === MILESTONE_STATUS_ENUM.PENDING ||
            item?.Milestone?.status === MILESTONE_STATUS_ENUM.FAIL ? (
              <div className="message-type-three p-16">
                <div className="d-flex flex-vertical gap-4">
                  <div className="message-user-info ">
                    {`${item?.user?.firstName}`}
                    {item?.user?.roles?.[0]}
                  </div>
                  <div className="body-base regular pb-12">{item?.text}</div>
                </div>
                <div className="message-button">
                  <Button disabled className="button-b" type="primary">
                    Pay now
                  </Button>
                </div>
              </div>
            ) : (
              <div className="message-type-four p-16">
                <div className="d-flex flex-vertical gap-4">
                  <div className="message-user-info ">
                    {`${item?.user?.firstName}`}
                    {item?.user?.roles?.[0]}
                  </div>
                  <div className="body-base regular pb-12">{item?.text}</div>
                </div>
                <div className="message-success d-flex align-center p-12">
                  <CheckCircleFilled className="pr-6" /> Payment Received
                </div>
              </div>
            )}
          </>
        );
      case MESSAGE_TYPE_ENUM.DISPUTE:
        return (
          <div className="message-type-three p-16">
            <div className="d-flex flex-vertical gap-4">
              <div className="message-user-info ">
                {item?.userName} | {item?.roles?.[0]}
              </div>
              <div className="body-base regular pb-12">{item?.message}</div>
            </div>
            <div className="message-button">
              <Button className="button-b" type="primary">
                Pay now
              </Button>
            </div>
          </div>
        );
      case 4:
        return (
          <div className="message-type-four p-16">
            <div className="d-flex flex-vertical gap-4">
              <div className="message-user-info ">
                {item?.userName} | {item?.roles?.[0]}
              </div>
              <div className="body-base regular pb-12">{item?.message}</div>
            </div>
            <div className="message-success d-flex align-center p-12">
              <CheckCircleFilled className="pr-6" /> Payment Received
            </div>
          </div>
        );
      default:
    }
  };
  return (
    <div className="chat-container">
      <div
        className="d-flex gap-12 flex-vertical chat-box"
        ref={chatContainerRef}
      >
        {messageList?.map((item, index, arr) => (
          <>
            {(index === 0 ||
              (arr[index - 1]?.createdAt &&
                dayjs(item?.createdAt).format(defaultDateFormat) !==
                  dayjs(arr[index - 1]?.createdAt).format(
                    defaultDateFormat,
                  ))) && (
              <Divider key={`divider${item?.id}`} className="date-divider">
                <div className="msg-date">
                  {dayjs(arr[index + 1]?.createdAt).format(
                    CALENDER_DATE_FORMATE,
                  )}
                </div>
              </Divider>
            )}
            <div
              key={item?.id}
              className={`${
                item?.user?.id === userIdFromContext ? 'sender' : 'receiver'
              }`}
            >
              <div
                className={`${
                  item?.user?.id === userIdFromContext
                    ? 'sender-msg'
                    : 'receiver-msg'
                }`}
              >
                {messageRender(item)}
              </div>
              <div className="time-info align-self-end">
                {dayjs(item?.createdAt).format(TIME_FORMAT)}
              </div>
            </div>
          </>
        ))}
      </div>
      <div className="chat-input-container d-flex align-center">
        <Dropdown
          trigger={['click']}
          menu={{ items }}
          placement="top"
          disabled={menuBtnLoader}
        >
          <Button className="mr-8" type="text" icon={<PlusCircleOutlined />} />
        </Dropdown>
        <div
          className={`input-controller-wrapper ${
            messageInput?.file ? 'file-input' : 'normal-text-input'
          } full-width d-flex flex-vertical gap-16`}
        >
          {messageInput?.file && Object?.keys(messageInput?.file)?.length && (
            <div>
              <div className="d-flex flex-horizontal gap-8 align-center selected-upload-file">
                <div className="d-flex align-center">
                  <FileAttachIcon />
                </div>
                <div>{messageInput?.file?.name}</div>
                <div
                  className="pointer d-flex align-center"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setMessageInput({ ...messageInput, file: null });
                  }}
                >
                  <UploadTrashIcon />
                </div>
              </div>
            </div>
          )}
          <div className="d-flex flex-horizontal align-center gap-8">
            <div>
              <Upload
                name="avatar"
                className="full-width"
                showUploadList={false}
                beforeUpload={beforeUploadDocFile}
                onChange={handleFileChange}
                accept=".doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              >
                <Button
                  type="text"
                  className="not-hoverable"
                  icon={<PaperClipOutlined className="input-icon" />}
                />
              </Upload>
            </div>
            <Input
              placeholder="Type a message"
              className="chat-text d-flex align-center"
              value={messageInput?.text}
              type="textarea"
              onChange={(e) =>
                setMessageInput({ ...messageInput, text: e?.target?.value })
              }
            />
            <Button
              className="not-hoverable"
              onClick={(e) => handleMessageSubmit(e)}
              type="text"
              disabled={
                !(
                  (messageInput.file &&
                    Object?.keys(messageInput?.file)?.length) ||
                  messageInput?.text !== ''
                ) || loading
              }
              icon={<MessageSendIcon />}
            />
          </div>
        </div>
      </div>
      <MilestonePaymentForm
        mileStonePaymentPopup={mileStonePaymentPopup}
        setMileStonePaymentPopup={setMileStonePaymentPopup}
        refetch={fetchMessagesCall}
        conversationId={conversationId}
        selectedService={selectedService}
      />
      {isOpenConsultantMemberManagePopup && (
        <AddConsultant
          serviceId={selectedService?.id}
          conversationId={conversationId}
          isOpenConsultantMemberManagePopup={isOpenConsultantMemberManagePopup}
          handleClose={() => setIsOpenConsultantMemberManagePopup(false)}
        />
      )}
      {isOpenServiceCompletePopup && (
        <ServiceCompletedPopUp
          serviceId={selectedService?.id}
          isOpenServiceCompletePopup={isOpenServiceCompletePopup}
          handleClose={() => setIsOpenServiceCompletePopup(false)}
        />
      )}
    </div>
  );
}

export default MessageRenderer;
