import { Button, Form, Input } from 'antd';
import React from 'react';
import { RemoveServiceRoundIcon } from '../../../assets/svg';

const DynamicFields = ({
  items,
  itemValue,
  maxItems,
  placeholder,
  setItemValue,
  setItem,
  defaultValue,
  fieldNames,
}) => {
  const [form] = Form.useForm();

  const handleAdd = () => {
    form
      .validateFields()
      .then(() => {
        if (items?.length < 10) {
          setItem([...items, itemValue]);
          setItemValue(defaultValue);
          form.resetFields();
        }
      })
      .catch((errorInfo) => {
        // eslint-disable-next-line no-console
        console.log('Validation Failed:', errorInfo);
      });
  };

  const handleRemove = (index) => {
    const updatedItem = items?.filter((_, i) => i !== index);
    setItem(updatedItem);
  };

  const handleChange = (field, value) => {
    setItemValue({ ...itemValue, [field]: value });
  };
  return (
    <div className="dynamic-form">
      <div className="d-flex align-start gap-8">
        <div className="full-width">
          {items?.length < maxItems && (
            <Form form={form} layout="vertical">
              <Form.Item
                name="title"
                rules={[
                  {
                    required: true,
                    message: `${placeholder}${fieldNames?.title} is required`,
                  },
                ]}
              >
                <Input
                  placeholder={`Enter ${placeholder}${fieldNames?.title}...`}
                  value={itemValue[fieldNames?.title]}
                  onChange={(e) =>
                    handleChange(fieldNames?.title, e?.target?.value)
                  }
                />
              </Form.Item>
              {fieldNames?.description && (
                <Form.Item
                  name="description"
                  rules={[
                    {
                      required: true,
                      message: `${placeholder}${fieldNames?.description} is required`,
                    },
                  ]}
                >
                  <Input.TextArea
                    rows={3}
                    placeholder={`Enter ${placeholder}${fieldNames?.description}...`}
                    value={itemValue[fieldNames?.description]}
                    onChange={(e) => {
                      if (fieldNames?.description) {
                        handleChange(fieldNames?.description, e?.target?.value);
                      }
                    }}
                  />
                </Form.Item>
              )}
              <Button
                className="btn-line-height"
                type="default"
                onClick={handleAdd}
                size="small"
              >
                Submit
              </Button>
            </Form>
          )}
          {items?.map((item, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={i} className="dynamic-form mt-8">
              <div className="d-flex align-center justify-between">
                <p>{item?.[fieldNames?.title]}</p>
                <span onClick={() => handleRemove(i)}>
                  <RemoveServiceRoundIcon />
                </span>
              </div>
              {fieldNames?.description && (
                <p>{item?.[fieldNames?.description]}</p>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DynamicFields;
