import { gql } from '@apollo/client';

export const CREATE_ACT = gql`
  mutation CreateActAdmin($data: CreateActInput!) {
    createActAdmin(data: $data) {
      message
    }
  }
`;

export const UPDATE_ACT = gql`
  mutation UpdateActAdmin(
    $data: UpdateActInput!
    $where: ActIdWhereUniqueInput!
  ) {
    updateActAdmin(data: $data, where: $where) {
      message
    }
  }
`;

export const DELETE_ACT = gql`
  mutation DeleteActAdmin($where: ActIdWhereUniqueInput!) {
    deleteActAdmin(where: $where) {
      message
    }
  }
`;
