import { useLazyQuery } from '@apollo/client';
import { Avatar, List, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { LIMIT, MILESTONE_STATUS_ENUM } from '../../../common/constants';
import { FETCH_MILESTONE_PAYMENTS } from '../graphql/queries';

import { avtarPlaceholder } from '../../../common/utils';

function MemberList({
  isOpen,
  handleClosePopup,
  list,
  serviceId,
  serviceName,
}) {
  const [paymentData, setPaymentData] = useState({
    list: [],
    count: 0,
  });
  const [fetchMileStonePaymentCall] = useLazyQuery(FETCH_MILESTONE_PAYMENTS, {
    fetchPolicy: 'network-only',
    variables: {
      filter: {
        status: null,
        skip: 0,
        servicePurchaseId: serviceId,
        search: null,
        limit: LIMIT,
      },
      sort: [
        {
          sortBy: 'DESC',
          sortOn: 'createdAt',
        },
      ],
    },
    onCompleted: (res) => {
      setPaymentData({
        list: res?.milestones?.data,
        count: res?.milestones?.count,
      });
    },
  });

  useEffect(() => {
    if (serviceId) {
      fetchMileStonePaymentCall();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceId]);

  return (
    <Modal
      className="chat-member-info"
      title={
        <div className="d-flex flex-horizontal align-center pt4">
          <div className="h4 medium pl-10">Info</div>
        </div>
      }
      open={isOpen}
      footer={null}
      onCancel={handleClosePopup}
    >
      <div className="d-flex flex-horizontal justify-center">
        <div className="h4 service-title">{serviceName}</div>
      </div>
      <h5 className="mb-16">Member list</h5>
      <List
        itemLayout="horizontal"
        dataSource={list}
        renderItem={(item) => (
          <div className="d-flex gap-16 flex-horizontal align-center">
            <div>
              {item?.user?.profileImage ? (
                <Avatar
                  className="p-4"
                  size={50}
                  src={
                    <img
                      width={50}
                      height={50}
                      src={item?.user?.profileImage}
                      alt={`${item?.user?.firstName} ${item?.user?.lastName}`}
                    />
                  }
                />
              ) : (
                <Avatar size={50}>
                  {avtarPlaceholder(
                    item?.user?.firstName,
                    item?.user?.lastName,
                  )}
                </Avatar>
              )}
            </div>
            <div className="body-m medium text-dark pt-13 pb-13">
              {item?.name} | {item.role}
            </div>
          </div>
        )}
      />
      {paymentData.list.length ? (
        <>
          <h5 className="mb-16 pt-48">
            Milestone Payments{' '}
            {paymentData?.count ? `(${paymentData?.count})` : ''}
          </h5>
          <List
            itemLayout="horizontal"
            dataSource={paymentData?.list}
            renderItem={(item) => (
              <div className="d-flex gap-16 flex-horizontal align-center p-4 justify-between">
                <div className="d-flex flex-vertical">
                  <div className="text-ellipsis one-line-clamp">
                    {item?.description}
                  </div>
                  {item?.status === MILESTONE_STATUS_ENUM.FAIL ||
                  item?.status === MILESTONE_STATUS_ENUM.PENDING ? (
                    <div className="payment-pending">Pending</div>
                  ) : (
                    <div className="payment-success">Completed</div>
                  )}
                </div>
                <div className=" pt-13 pb-13">
                  <div>₹{item?.amount}</div>
                </div>
              </div>
            )}
          />
        </>
      ) : (
        ''
      )}
    </Modal>
  );
}

export default MemberList;
