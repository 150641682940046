import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import SectionsList from './SectionsList';

const SectionWrapper = () => (
  <Routes>
    <Route path={ROUTES.MAIN} exact element={<SectionsList />} />
  </Routes>
);
export default SectionWrapper;
