import { gql } from '@apollo/client';

export const LIST_SUBSCRIPTION_PLANS = gql`
  query SubscriptionPlansAdmin(
    $filter: AdminListSubscriptionPlansFilter
    $sort: [ListSubscriptionPlansSort!]
  ) {
    subscriptionPlansAdmin(filter: $filter, sort: $sort) {
      count
      data {
        id
        description
        featureList
        price
        status
        title
        type
        createdAt
      }
    }
  }
`;

export const GET_SUBSCRIPTION_PLAN = gql`
  query SubscriptionPlanAdmin($where: SubscriptionPlanAdminWhereUniqueInput!) {
    subscriptionPlanAdmin(where: $where) {
      description
      featureList
      price
      status
      title
      type
      showOnWeb {
        JUDGEMENT {
          INDIRECT_TAX {
            visible
          }
          DIRECT_TAX {
            visible
          }
          COMPANY_LAW {
            visible
          }
        }
        HOME {
          INDIRECT_TAX {
            visible
          }
          DIRECT_TAX {
            visible
          }
          COMPANY_LAW {
            visible
          }
        }
        BLOG {
          INDIRECT_TAX {
            visible
          }
          DIRECT_TAX {
            visible
          }
          COMPANY_LAW {
            visible
          }
        }
      }
    }
  }
`;
