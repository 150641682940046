import { gql } from '@apollo/client';

export const SEND_MESSAGE_MUTATION = gql(`mutation CreateMessage($data: CreateMessageInput!) {
    createMessage(data: $data) {
      message
    }
  }`);

export const CREATE_MILESTONE_MUTATION = gql(`mutation CreateMilestone($data: CreateMilestoneInput!) {
    createMilestone(data: $data) {
      message
    }
  }`);

export const CREATE_MILESTONE_ORDER_MUTATION = gql(`mutation CreateMilestoneOrder($filter: CreateMilestoneOrderFilter!) {
    createMilestoneOrder(filter: $filter) {
      message
      data {
      id
      amount
    }
    }
  }`);

export const UPDATE_CHAT_MEMBER_MUTATION = gql(`mutation UpdateChatMembers($input: UpdateChatMemberInput!) {
    updateChatMembers(input: $input) {
      message
    }
  }`);

export const UPDATE_SERVICE_PURCHASE_MUTATION = gql(`mutation UpdateServicePurchase($where: ServicePurchaseWhereUniqueId!, $data: UpdateServicePurchaseInputData!) {
    updateServicePurchase(where: $where, data: $data) {
      message
    }
  }`);
