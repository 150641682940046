import { gql } from '@apollo/client';

export const CREATE_ARGUMENT = gql`
  mutation CreateArgumentAdmin($data: CreateArgumentInput!) {
    createArgumentAdmin(data: $data) {
      message
    }
  }
`;

export const UPDATE_ARGUMENT = gql`
  mutation UpdateArgumentAdmin(
    $data: UpdateArgumentInput!
    $where: ArgumentIdWhereUniqueInput!
  ) {
    updateArgumentAdmin(data: $data, where: $where) {
      message
    }
  }
`;

export const DELETE_ARGUMENT = gql`
  mutation DeleteArgumentAdmin($where: ArgumentIdWhereUniqueInput!) {
    deleteArgumentAdmin(where: $where) {
      message
    }
  }
`;
