import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import EditJudgement from './EditJudgement';
import ImportJudgement from './ImportJudgement';
import JudgementsList from './JudgementList';
import './judgements.less';

const JudgementWrapper = () => (
  <Routes>
    <Route path={ROUTES.MAIN} exact element={<JudgementsList />} />
    <Route path={ROUTES.EDIT} exact element={<EditJudgement />} />
    {process.env.REACT_APP_ALLOW_IMPORT_JUDGEMENT === 'true' && (
      <Route
        path={ROUTES.IMPORT_JUDGEMENT}
        exact
        element={<ImportJudgement />}
      />
    )}
  </Routes>
);
export default JudgementWrapper;
