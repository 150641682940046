import { gql } from '@apollo/client';

export const FETCH_CHAT_LIST = gql(`query Chats($filter: ListChatsFilter!, $sort: [ListChatsSort!]) {
    chats(filter: $filter, sort: $sort) {
      data {
        description
        id
        itemId
        lastMessage {
          text
          createdAt
        }
        title
        servicePurchase {
        service {
          id
        }
      }
        unreadMessageCount
        hasDispute
      }
      count
      unreadChatCount {
        consultation
        service
      }
    }
  }`);

export const FETCH_MESSAGES = gql(`query Messages($filter: MessagesFilter!, $sort: [ListMessagesSort!]) {
    messages(filter: $filter, sort: $sort) {
      count
      data {
        id
        Milestone {
          id
          amount
          status
        }
        attachmentKeys {
          url
          key
          fileName
          fileSize
          fileSizeUnit
        }
        text
        type
        createdAt
        user {
          id
          lastName
          firstName
          roles
        }
      }
    }
  }`);

export const FETCH_MEMBERS = gql(`query ChatMembers($filter: ChatMembersFilter!, $sort: [ListChatMembersSort!]) {
    chatMembers(filter: $filter, sort: $sort) {
      count
      data {
        memberType
        user {
          id
          firstName
          lastName
          profileImage
        }
      }
    }
  }`);

export const FETCH_CONSULTANTS = gql(`query Consultants($where: ServiceWhereUniqueIdForConsultants!, $filter: ConsultantsFilterInput!) {
    consultants(where: $where, filter: $filter) {
      count
      data {
        id
        user {
          id
          lastName
          firstName
          profileImage
        }
        chatMembers {
          id
          memberType
          createdAt
        }
      }
    }
  }`);

export const FETCH_MILESTONE_PAYMENTS = gql(`query Milestones($filter: ListMilestonesFilter!, $sort: [ListMilestonesSort!]) {
    milestones(filter: $filter, sort: $sort) {
      count
      data {
        amount
        description
        status
      }
    }
  }`);

export const GET_ATTACHMENT_SIGNED_URL = gql(`query GetMessageAttachmentSignedUrl($data: GetMessageAttachmentSignedUrlInput!) {
    getMessageAttachmentSignedUrl(data: $data) {
      fileName
      signedUrl
    }
  }`);

export const FETCH_CHAT_DETAILS = gql(`query Chat($data: ChatDetailsInput!) {
  chat(data: $data) {
    id
    currentMember {
      id
      serviceConsultant {
        id
        type
      }
    }
  }
}`);
