import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import EditSubscriptionPlan from './EditSubscriptionPlan';
import SubscriptionPlansList from './SubscriptionPlansList';
import './subscription-plan.less';

const SubscriptionPlansWrapper = () => (
  <Routes>
    <Route path={ROUTES.MAIN} exact element={<SubscriptionPlansList />} />
    <Route path={ROUTES.EDIT} exact element={<EditSubscriptionPlan />} />
  </Routes>
);
export default SubscriptionPlansWrapper;
