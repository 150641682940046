import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import ArgumentsList from './ArgumentsList';
import CreateEditArgument from './CreateEditArgument';

const ArgumentsWrapper = () => (
  <Routes>
    <Route path={ROUTES.MAIN} exact element={<ArgumentsList />} />
    <Route path={ROUTES.CREATE} exact element={<CreateEditArgument />} />
    <Route path={ROUTES.EDIT} exact element={<CreateEditArgument />} />
  </Routes>
);
export default ArgumentsWrapper;
