import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import AssessesList from './AssessesList';
import CreateAssesses from './CreateAssesses';
import EditAssesses from './EditAssesses';
import './assesses.less';

const AssessesWrapper = () => (
  <Routes>
    <Route path={ROUTES.MAIN} exact element={<AssessesList />} />
    <Route path={ROUTES.EDIT} exact element={<EditAssesses />} />
    <Route path={ROUTES.CREATE} exact element={<CreateAssesses />} />
  </Routes>
);
export default AssessesWrapper;
