import { Avatar, Card, Col, Row, Space, Tag, Typography } from 'antd';
import { capitalize } from 'lodash';
import React, { useState } from 'react';
import profilePlaceholder from '../../../assets/images/placeholder.jpg';
import { SUMMARY_STATUS } from '../../../common/constants';
import EditExpertSummary from './EditExpertSummary';

const { Text } = Typography;

export default function ExpertSummaryList({ summaryData = [], refetch }) {
  const [modalData, setModalData] = useState(null);

  const handleModalClose = () => {
    setModalData(null);
  };

  return (
    <>
      <Row gutter={[16, 16]}>
        {summaryData?.map((summary) => (
          <Col span={12} key={summary?.id}>
            <Card
              hoverable
              title={
                <Space>
                  <Avatar
                    src={summary?.user?.profileImage || profilePlaceholder}
                  />
                  <h4>
                    {summary?.user?.firstName} {summary?.user?.lastName}
                  </h4>
                </Space>
              }
              extra={
                <>
                  <Tag color={SUMMARY_STATUS?.[summary?.status]}>
                    {capitalize(summary?.status)}
                  </Tag>
                </>
              }
              onClick={() => {
                setModalData(summary);
              }}
            >
              <Text
                ellipsis={{
                  rows: 3,
                }}
              >
                {summary?.content}
              </Text>
            </Card>
          </Col>
        ))}
      </Row>
      {modalData && (
        <EditExpertSummary
          modalData={modalData}
          handleModalClose={handleModalClose}
          refetch={refetch}
        />
      )}
    </>
  );
}
