import { PlusCircleOutlined } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import { Badge, Button, Checkbox, Radio, Table, Typography } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../AppContext';
import {
  LIMIT,
  MODULES,
  PAGE_SIZE_OPTIONS,
  ROUTES,
  SERVICE_PAYMENT_MODE_TYPE,
  SERVICE_STATUS,
  SERVICE_VISIBILITY_TYPE,
  SORT,
  TEMPLATE_STATUS_COLOR,
  USER_TYPE,
  USER_TYPE_FILTER,
} from '../../common/constants';
import SearchComponent from '../../components/SearchComponent';
import useRouter from '../../hooks/useRouter';
import { GET_SERVICES } from './graphql/queries';
import './services.less';

const SERVICE_TYPE_FILTER = [
  {
    text: 'Public',
    value: SERVICE_VISIBILITY_TYPE.PUBLIC,
  },
  {
    text: 'Private',
    value: SERVICE_VISIBILITY_TYPE.PRIVATE,
  },
];

const SERVICE_STATUS_FILTER = [
  {
    text: 'Draft',
    value: SERVICE_STATUS.DRAFT,
  },
  {
    text: 'Published',
    value: SERVICE_STATUS.PUBLISHED,
  },
  {
    text: 'In-Review',
    value: SERVICE_STATUS.IN_REVIEW,
  },
];

const SERVICE_PAYMENT_MODE_FILTER = [
  {
    text: 'Public',
    value: SERVICE_PAYMENT_MODE_TYPE.PAY_NOW,
  },
  {
    text: 'Private',
    value: SERVICE_PAYMENT_MODE_TYPE.PAY_LATER,
  },
];

const { Title } = Typography;

function Services() {
  const { getCurrentUserRole } = useContext(AppContext);
  const roles = getCurrentUserRole() || {};
  const { navigate } = useRouter();
  const [params, setParams] = useState({
    filter: {
      limit: LIMIT,
      paymentType: null,
      search: null,
      skip: null,
      status: null,
      visibility: null,
    },
    sort: [
      {
        sortOn: 'createdAt',
        sortBy: 'DESC',
      },
    ],
  });
  const [serviceList, setServicerList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: LIMIT,
      pageSizeOptions: PAGE_SIZE_OPTIONS,
    },
  });

  const [fetchServices] = useLazyQuery(GET_SERVICES);

  function fetchServicesCall(filterValue = params, pageNumber = 1) {
    setLoading(true);
    fetchServices({
      variables: filterValue,
      fetchPolicy: 'network-only',
      onCompleted: ({ services }) => {
        setServicerList(services?.data);
        const count = services?.count;
        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams?.pagination,
            total: count,
            current: pageNumber,
          },
        });
        setLoading(false);
      },
      onError: () => {
        setLoading(false);
      },
    });
  }

  useEffect(() => {
    fetchServicesCall();
  }, []);
  /* keep for future use */
  // const [deleteTemplateCall] = useMutation(DELETE_TEMPLATE);

  const handleTableChange = (pagination, filters, sorter) => {
    const prepareFilter = {
      filter: {
        skip: (pagination?.current - 1) * LIMIT,
        limit: pagination?.pageSize || LIMIT,
        visibility: filters?.visibility?.length
          ? filters?.visibility?.[0]
          : null,
        status: filters?.status?.length ? filters?.status?.[0] : null,
        paymentType:
          filters?.paymentType && filters?.paymentType?.length
            ? filters?.paymentType?.[0]
            : null,
        /* this code can be used in future */
        // creatorRoles:
        //   filters?.creatorRoles && filters?.creatorRoles?.length
        //     ? filters.creatorRoles
        //     : [],
      },
      sort: [
        {
          sortOn: Object.values(sorter)?.length ? sorter?.field : 'createdAt',
          sortBy: sorter?.order === SORT.ASC ? 'ASC' : 'DESC',
        },
      ],
    };
    setTableParams({
      pagination: {
        current: pagination?.current,
        pageSize: pagination?.pageSize || LIMIT,
      },
    });
    setParams(prepareFilter);
    fetchServicesCall(prepareFilter, pagination?.current);
  };

  const handleSearchChange = (e) => {
    const prepareFilter = {
      ...params,
      filter: { ...params?.filter, search: e, skip: 0 },
    };
    setParams(prepareFilter);
    fetchServicesCall(prepareFilter);
  };

  /* keep for future use */
  /* const handleDelete = (id) => {
    confirm({
      centered: true,
      title: 'Are you sure to delete?',
      icon: <ExclamationCircleFilled />,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        deleteTemplateCall({
          variables: {
            where: {
              id,
            },
          },
          onCompleted: () => {
            fetchTemplatesCall(params);
          },
          onError: () => {},
        });
      },
      onCancel() {},
    });
  }; */

  const renderBadge = (item) => {
    switch (item) {
      case SERVICE_STATUS.DRAFT:
        return <Badge count="Draft" color={TEMPLATE_STATUS_COLOR.DRAFT} />;
      case SERVICE_STATUS.PUBLISHED:
        return (
          <Badge count="Published" color={TEMPLATE_STATUS_COLOR.PUBLISHED} />
        );
      case SERVICE_STATUS.IN_REVIEW:
        return (
          <Badge
            count="Unpublished"
            color={TEMPLATE_STATUS_COLOR.UNPUBLISHED}
          />
        );
      default:
        return <Badge count="Rejected" />;
    }
  };

  const getUserTypeFilterProps = () => ({
    filterMultiple: true,
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <div className="custom-filter-dropdown">
        {USER_TYPE_FILTER?.map((option) => (
          <div key={option?.value} className="mt-6 mb-6">
            <Checkbox
              checked={selectedKeys?.includes(option?.value)}
              onChange={(e) => {
                const updatedKeys = e?.target?.checked
                  ? [...selectedKeys, option.value]
                  : selectedKeys?.filter((key) => key !== option?.value);
                setSelectedKeys(updatedKeys);
              }}
            >
              {option?.text}
            </Checkbox>
          </div>
        ))}
        <div className="control-panel">
          <Button
            type="primary"
            size="small"
            onClick={() => {
              confirm();
            }}
            className="ok-button"
          >
            Apply
          </Button>
          <Button
            className="ml-8 reset-button"
            size="small"
            onClick={() => {
              setSelectedKeys([]);
              confirm();
            }}
          >
            Reset
          </Button>
        </div>
      </div>
    ),
  });

  const getServiceVisibilityFilterProps = () => ({
    filterMultiple: true,
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <div className="custom-filter-dropdown">
        {SERVICE_TYPE_FILTER?.map((option) => (
          <div key={option?.value} className="mt-6 mb-6">
            <Radio
              checked={selectedKeys?.includes(option?.value)}
              onChange={(e) => {
                const updatedKeys = e?.target?.checked ? [option?.value] : [];
                setSelectedKeys(updatedKeys);
              }}
            >
              {option?.text}
            </Radio>
          </div>
        ))}
        <div className="control-panel">
          <Button
            type="primary"
            size="small"
            onClick={() => {
              confirm();
            }}
            className="ok-button"
          >
            Apply
          </Button>
          <Button
            className="ml-8 reset-button"
            size="small"
            onClick={() => {
              setSelectedKeys([]);
              confirm();
            }}
          >
            Reset
          </Button>
        </div>
      </div>
    ),
  });

  const getStatusFilterProps = () => ({
    filterMultiple: true,
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <div className="custom-filter-dropdown">
        {SERVICE_STATUS_FILTER?.map((option) => (
          <div key={option?.value} className="mt-6 mb-6">
            <Radio
              checked={selectedKeys.includes(option?.value)}
              onChange={(e) => {
                const updatedKeys = e?.target?.checked ? [option?.value] : [];
                setSelectedKeys(updatedKeys);
              }}
            >
              {option?.text}
            </Radio>
          </div>
        ))}
        <div className="control-panel">
          <Button
            type="primary"
            size="small"
            onClick={() => {
              confirm();
            }}
            className="ok-button"
          >
            Apply
          </Button>
          <Button
            className="ml-8 reset-button"
            size="small"
            onClick={() => {
              setSelectedKeys([]);
              confirm();
            }}
          >
            Reset
          </Button>
        </div>
      </div>
    ),
  });

  const getServicePaymentTypeFilterProps = () => ({
    filterMultiple: true,
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <div className="custom-filter-dropdown">
        {SERVICE_PAYMENT_MODE_FILTER?.map((option) => (
          <div key={option?.value} className="mt-6 mb-6">
            <Radio
              checked={selectedKeys?.includes(option?.value)}
              onChange={(e) => {
                const updatedKeys = e?.target?.checked ? [option?.value] : [];
                setSelectedKeys(updatedKeys);
              }}
            >
              {option?.text}
            </Radio>
          </div>
        ))}
        <div className="control-panel">
          <Button
            type="primary"
            size="small"
            onClick={() => {
              confirm();
            }}
            className="ok-button"
          >
            Apply
          </Button>
          <Button
            className="ml-8 reset-button"
            size="small"
            onClick={() => {
              setSelectedKeys([]);
              confirm();
            }}
          >
            Reset
          </Button>
        </div>
      </div>
    ),
  });

  return (
    <>
      <Title className="site-page-header p-0 mb-8 mt-0" level={3}>
        {MODULES.SERVICES}
        {tableParams?.pagination?.total
          ? ` (${tableParams?.pagination?.total})`
          : ''}
      </Title>
      <div className="filter-input mb-12">
        <SearchComponent className="list-search" getData={handleSearchChange} />
        {roles !== USER_TYPE.ADMIN && (
          <Button
            className="ml-8"
            key="1"
            type="primary"
            icon={<PlusCircleOutlined />}
            onClick={() => {
              navigate(ROUTES.ADD_SERVICE);
            }}
          >
            Add Service
          </Button>
        )}
      </div>
      <Table
        columns={[
          {
            title: 'Title',
            dataIndex: 'title',
          },
          {
            title: 'Created By',
            render: (item) =>
              USER_TYPE_FILTER?.[item?.creator?.roles?.[0]]?.text,
            key: 'creatorRoles',
            ...getUserTypeFilterProps(),
          },
          {
            title: 'Service Visibility',
            render: (item) => {
              switch (item?.visibility) {
                case SERVICE_VISIBILITY_TYPE.PUBLIC:
                  return 'Public';
                case SERVICE_VISIBILITY_TYPE.PRIVATE:
                  return 'Private';
                default:
                  break;
              }
            },
            key: 'visibility',
            ...getServiceVisibilityFilterProps(),
          },
          {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            align: 'center',
            width: '20%',
            render: (item) => renderBadge(item),
            ...getStatusFilterProps(),
          },
          {
            title: 'Payment Mode',
            render: (item) => {
              switch (item?.paymentType) {
                case SERVICE_PAYMENT_MODE_TYPE.PAY_LATER:
                  return 'Pay Later';
                case SERVICE_PAYMENT_MODE_TYPE.PAY_NOW:
                  return 'Pay Now';
                default:
                  break;
              }
            },
            key: 'paymentType',
            ...getServicePaymentTypeFilterProps(),
          },
          {
            title: 'Price (INR)',
            dataIndex: 'price',
            sorter: true,
            key: 'price',
          },
        ]}
        // eslint-disable-next-line arrow-body-style
        onRow={(record) => {
          return {
            onClick: () => {
              navigate(`${ROUTES?.SERVICE}/edit/${record?.id}`);
            },
          };
        }}
        rowKey={(record) => record?.id}
        dataSource={serviceList}
        pagination={tableParams?.pagination}
        scroll={{ y: `calc(100vh - 342px)` }}
        loading={loading}
        onChange={handleTableChange}
      />
    </>
  );
}

export default Services;
