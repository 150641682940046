import { Button, Checkbox, Form, Input, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { mongoClient } from '../../../apollo';
import { CREATE_SECTION, UPDATE_SECTION } from '../graphql/mutations';

function SectionsModal({
  formPopup,
  handleCloseModal,
  handleModalForm,
  initialFormData,
}) {
  const [form] = Form?.useForm();
  const [buttonLoader, setButtonLoader] = useState(false);

  useEffect(() => {
    if (formPopup?.id && formPopup?.id !== 'new')
      form?.setFieldsValue(initialFormData);
  }, [initialFormData]);

  const handleClose = () => {
    handleCloseModal?.();
    form?.resetFields();
  };

  const handleForm = async (values) => {
    setButtonLoader(true);
    try {
      if (formPopup.id === 'new') {
        await mongoClient?.mutate({
          mutation: CREATE_SECTION,
          fetchPolicy: 'network-only',
          variables: {
            data: values,
          },
        });
      } else {
        await mongoClient?.mutate({
          mutation: UPDATE_SECTION,
          fetchPolicy: 'network-only',
          variables: {
            data: values,
            where: {
              id: formPopup?.id,
            },
          },
        });
      }
      handleModalForm?.(values);
    } catch (error) {
      return;
    } finally {
      handleClose();
      setButtonLoader(false);
    }
  };

  return (
    <Modal
      destroyOnClose
      title={`${formPopup?.id === 'new' ? 'Add Section' : 'Update Section'}`}
      open={formPopup?.isOpen}
      onCancel={handleClose}
      footer={[
        <Button key="submit" onClick={handleClose}>
          Cancel
        </Button>,
        <Button
          key="back"
          type="primary"
          loading={buttonLoader}
          disabled={formPopup?.id !== 'new'}
          onClick={() => form?.submit()}
        >
          Save
        </Button>,
      ]}
    >
      <div>
        <Form
          disabled={formPopup?.id !== 'new'}
          form={form}
          onFinish={handleForm}
        >
          <Form.Item
            name="label"
            label="Section Name"
            rules={[
              {
                required: true,
                message: 'Please enter Section Name',
              },
              {
                whitespace: true,
                message: 'Invalid input',
              },
            ]}
          >
            <Input placeholder="Please enter Section Name" />
          </Form.Item>
          <Form.Item name="considerCheckArray" valuePropName="checked">
            <Checkbox>Consider this Label for Sufix/Prefix match</Checkbox>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
}

export default SectionsModal;
