import { gql } from '@apollo/client';

export const GET_CIRCULARS = gql`
  query CircularsAdmin(
    $filter: ListCircularsFilter
    $sort: [ListCircularsSort!]
  ) {
    circularsAdmin(filter: $filter, sort: $sort) {
      count
      data {
        category
        id
        description
        createdAt
        issuePeriod
        pdfUrl
        region
        slug
        status
        title
      }
    }
  }
`;

export const GET_CIRCULAR = gql`
  query CircularAdmin($where: CircularIdWhereUniqueInput!) {
    circularAdmin(where: $where) {
      category
      createdAt
      description
      id
      issuePeriod
      pdfUrl
      region
      slug
      status
      title
    }
  }
`;

export const CIRCULAR_PDF_SIGNED_URL = gql`
  query GetCircularPdfFileUploadSignedUrl(
    $data: GetPdfFileUploadSignedUrlInput!
  ) {
    getCircularPdfFileUploadSignedUrl(data: $data) {
      key
      signedUrl
    }
  }
`;
