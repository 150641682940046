import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import CircularsList from './CircularList';
import CreateEditCircular from './CreateEditCircular';

const CircularsWrapper = () => (
  <Routes>
    <Route path={ROUTES.MAIN} exact element={<CircularsList />} />
    <Route path={ROUTES.CREATE} exact element={<CreateEditCircular />} />
    <Route path={ROUTES.EDIT} exact element={<CreateEditCircular />} />
  </Routes>
);
export default CircularsWrapper;
