import { gql } from '@apollo/client';

const GET_KEYWORD = gql`
  query KeywordsAdmin($filter: ListKeywordFilter, $sort: [ListKeywordSort!]) {
    keywordsAdmin(filter: $filter, sort: $sort) {
      data {
        _id
        label
        slug
        regex
      }
      count
    }
  }
`;

export default GET_KEYWORD;
