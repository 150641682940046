import { useLazyQuery } from '@apollo/client';
import { Badge, List } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { CHAT_TABS, LIMIT, TIME_FORMAT } from '../../../common/constants';
import { FETCH_CHAT_LIST } from '../graphql/queries';

function ChatList({
  type,
  selectedId,
  setSelectedId,
  setNotificationCount,
  notificationCount,
  search,
  selectedTab,
  setSelectedService,
}) {
  const [isEnds, setIsEnds] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [chatList, setChatList] = useState([]);
  const [filters, setFilters] = useState({
    limit: LIMIT,
    search: search ?? null,
    skip: 0,
    type,
  });
  const [getChatListCall] = useLazyQuery(FETCH_CHAT_LIST, {
    fetchPolicy: 'network-only',
  });

  const fetchChats = (filterObj = filters, isScroll = false) => {
    setIsLoading(true);
    getChatListCall({
      variables: {
        filter: filterObj,
        sort: [
          {
            sortBy: 'DESC',
            sortOn: 'createdAt',
          },
        ],
      },
      onCompleted: ({ chats }) => {
        setNotificationCount({
          ...notificationCount,
          service: chats?.unreadChatCount?.service ?? 0,
          consultant: chats?.unreadChatCount?.consultation ?? 0,
        });
        let prepareChats = chats?.data;
        if (isScroll) {
          prepareChats = [...chatList, ...prepareChats];
        }
        if (chats?.count === prepareChats?.length) {
          setIsEnds(true);
        }
        setChatList(prepareChats);
        setIsLoading(false);
      },
    });
  };

  useEffect(() => {
    if (selectedTab?.toUpperCase() === type) {
      fetchChats();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, selectedTab]);

  const handleScroll = async (event) => {
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};
    const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 1;
    if (scrolledToBottom && !isEnds) {
      const prepareFilter = filters;
      prepareFilter.skip = chatList?.length;
      setFilters({ ...{}, ...prepareFilter });
      fetchChats(prepareFilter, true);
    }
  };

  useEffect(() => {
    const elementIndex = type === CHAT_TABS.CALL ? 0 : 1;
    // eslint-disable-next-line no-undef
    const layoutContentHolder = document?.getElementsByClassName(
      'chat-list-services',
    )?.[elementIndex];
    if (layoutContentHolder) {
      layoutContentHolder?.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (layoutContentHolder) {
        layoutContentHolder?.removeEventListener('scroll', handleScroll);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatList?.length]);

  return (
    <>
      <List
        loading={isLoading}
        itemLayout="horizontal"
        className="chat-list-services"
        dataSource={chatList}
        renderItem={(item) => (
          <div
            key={item?.id}
            onClick={() => {
              setSelectedId(item?.id ?? '');
              setSelectedService({
                ...{},
                name: item?.title ?? '',
                id:
                  type === CHAT_TABS.CALL
                    ? item?.itemId
                    : item?.servicePurchase?.service?.id,
              });
            }}
            className={`pl-16 pr-16 pt-12 pb-12 d-flex flex-vertical pointer ${
              item?.id === selectedId ? 'active-chat' : ''
            }`}
          >
            <div className="d-flex flex-horizontal justify-between align-center">
              <div>{item?.title}</div>
              <div>
                {dayjs(item?.lastMessage?.createdAt)?.format(TIME_FORMAT)}
              </div>
            </div>
            <div className="d-flex flex-horizontal justify-between align-center">
              <div className="text-ellipsis one-line-clamp">
                {item?.description}
              </div>
              <div>
                <Badge
                  count={item?.unreadMessageCount}
                  className="pl-8 badge-success"
                />
              </div>
            </div>
          </div>
        )}
      />
    </>
  );
}

export default ChatList;
