import React from 'react';
import EditJudgement from '../../judgements/EditJudgement';
import '../review-appeals.less';

function AppealDetails({
  id,
  getFormData,
  getJudgementLoadingValues,
  onUpdateJudgement,
  onAssesseeUpdate,
  fetchInitialList,
}) {
  return (
    <EditJudgement
      isReviewAppeal
      appealId={id}
      key={id}
      getFormData={getFormData}
      getJudgementLoadingValues={getJudgementLoadingValues}
      onUpdateJudgement={onUpdateJudgement}
      onAssesseeUpdate={onAssesseeUpdate}
      fetchInitialList={fetchInitialList}
    />
  );
}
export default AppealDetails;
