import React from 'react';
import { LIMIT } from '../common/constants';
import { LIST_BENCH } from '../modules/review-appeals/graphql/queries';
import MongoCommonSelect from './MongoCommonSelect';

const commonInitialVariable = {
  filter: {
    skip: 0,
    limit: LIMIT,
    search: '',
  },
  sort: { sortBy: 'DESC', sortOn: 'createdAt' },
};

function BenchListSelect({ onChange, values, mode }) {
  return (
    <MongoCommonSelect
      getPopupContainer={(trigger) => trigger?.parentNode}
      placeholder="Select Bench"
      className="role-select-in-calls ml-8"
      showSearch
      allowClear
      query={LIST_BENCH}
      fetchPolicy="network-only"
      responsePath="data.benchesAdmin.data"
      valuePath="_id"
      labelPath="value"
      onChange={onChange}
      variables={commonInitialVariable}
      value={values}
      mode={mode}
    />
  );
}

export default BenchListSelect;
