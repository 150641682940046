import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import CreateJudge from './CreateJudge';
import EditJudge from './EditJudge';
import JudgesList from './JudgesList';
import './judges.less';

const JudgesWrapper = () => (
  <Routes>
    <Route path={ROUTES.MAIN} exact element={<JudgesList />} />
    <Route path={ROUTES.EDIT} exact element={<EditJudge />} />
    <Route path={ROUTES.CREATE} exact element={<CreateJudge />} />
  </Routes>
);
export default JudgesWrapper;
