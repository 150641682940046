import { gql } from '@apollo/client';

export const CREATE_LAWYER = gql`
  mutation CreateLawyerAdmin($data: CreateLawyerInput!) {
    createLawyerAdmin(data: $data) {
      message
    }
  }
`;

export const UPDATE_LAWYER = gql`
  mutation UpdateLawyerAdmin(
    $data: UpdateLawyerInput!
    $where: LawyerIdWhereUniqueInput!
  ) {
    updateLawyerAdmin(data: $data, where: $where) {
      message
    }
  }
`;
