import { Select } from 'antd';
import { debounce, get, map, trim, uniqBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { mongoClient } from '../apollo';
import { LIMIT } from '../common/constants';

const { Option } = Select;
let searchDebounce = null;

const MongoCommonSelect = (props) => {
  const {
    placeholder = 'Search',
    query,
    fetchPolicy = 'network-only',
    variables = {},
    responsePath,
    valuePath,
    labelPath,
    optionalLabelPath = '',
    showSearch,
    conditionToCheckBeforeQuery = true,
    isDataDependent = [],
    customOptions = [],
    useEffectDeps = [],
    inputRef = null,
    provider = null,
    onSelect,
    onChange,
    onSelectData,
    ...rest
  } = props;
  const [listData, setListData] = useState([...customOptions] || []);
  const [hasMore, setHasMore] = useState(true);
  const [skipData, setSkipData] = useState(0);
  const [searchText, setSearchText] = useState('');

  const fetchListData = async (newVariables, isClear = true) => {
    const res = await mongoClient?.query({
      query,
      fetchPolicy,
      variables: newVariables?.variables ?? variables,
    });
    const data = get(res, responsePath, []);
    const records = isClear ? [...data] : [...listData, ...data];
    setListData(records);
    setHasMore(data?.length >= LIMIT);
  };

  useEffect(() => {
    if (isDataDependent) {
      setListData([]);
      setSkipData(0);
    }
  }, isDataDependent);

  useEffect(() => {
    if (conditionToCheckBeforeQuery) {
      fetchListData({
        fetchPolicy,
        variables: {
          ...variables,
          filter: {
            ...(variables?.filter || {}),
            skip: 0,
            limit: LIMIT,
          },
        },
      });
    }
  }, useEffectDeps);

  const searchQuery = (search) => {
    setSearchText(trim(search));
    fetchListData({
      fetchPolicy: 'network-only',
      variables: {
        ...variables,
        filter: {
          ...(variables?.filter || {}),
          skip: 0,
          limit: LIMIT,
          search: trim(search),
        },
      },
    });
  };

  const handleSearch = (value) => {
    setSkipData(0);
    if (searchDebounce) {
      searchDebounce.cancel();
      searchDebounce = null;
    }
    searchDebounce = debounce(searchQuery, 500);
    searchDebounce(trim(value));
  };

  const afterSearch = () => {
    setSearchText('');
    fetchListData(
      {
        fetchPolicy,
        variables: {
          ...variables,
          filter: {
            ...(variables?.filter || {}),
            skip: 0,
            limit: LIMIT,
          },
        },
      },
      true,
    );
  };

  const handleClear = () => {
    if (searchText) {
      afterSearch();
    }
  };
  const handleScroll = async (event) => {
    const { target } = event;
    const { scrollTop, clientHeight, scrollHeight } = target || {};
    const scrolledToBottom = scrollTop + clientHeight >= scrollHeight - 5;
    if (scrolledToBottom && hasMore) {
      const newSkip = skipData + LIMIT;
      await fetchListData(
        {
          variables: {
            ...variables,
            filter: {
              ...variables?.filter,
              skip: newSkip,
              limit: LIMIT,
              search: trim(searchText),
            },
          },
        },
        false,
      );
      setSkipData(newSkip);
    }
  };

  return (
    <Select
      ref={inputRef}
      placeholder={placeholder}
      showSearch={showSearch}
      onSearch={showSearch && handleSearch}
      onClear={handleClear}
      onChange={(val, record) => {
        if (searchText) afterSearch();
        onChange?.(val, record);
      }}
      onBlur={handleClear}
      onPopupScroll={handleScroll}
      filterOption={(inputValue, option) =>
        option?.children?.toLowerCase()?.includes(inputValue?.toLowerCase())
      }
      onSelect={(value, record) => {
        if (searchText) afterSearch();
        onSelect?.(value, record);
        onSelectData?.(
          listData?.find((item) => item?.[valuePath] === value) ?? null,
        );
      }}
      {...rest}
    >
      <>
        {map(
          uniqBy(provider ? [provider, ...listData] : [...listData], valuePath),
          (item) => {
            const optionValue = get(item, valuePath);
            const optionLabel = get(item, labelPath);
            const optionalLabel = get(item, optionalLabelPath);
            return (
              <Option
                key={`option-key:${optionValue}`}
                value={optionValue}
                label={optionLabel}
              >
                {optionalLabel
                  ? `${optionLabel} ${optionalLabel}`
                  : `${optionLabel}`}
              </Option>
            );
          },
        )}
      </>
    </Select>
  );
};

export default MongoCommonSelect;
