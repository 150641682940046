import { Col, Input, Modal, Row } from 'antd';
import React from 'react';

const { TextArea } = Input;

export default function QuotationDetails({
  setShowModal,
  showModal,
  orderDetails,
}) {
  return (
    <>
      <Modal
        title="Quotation Details"
        centered
        closable
        open={showModal}
        onCancel={() => setShowModal(false)}
        footer={[]}
      >
        <Row gutter={[16, 16]} className="mt-24">
          <Col span={24}>
            Service Name: <Input disabled value={orderDetails?.serviceName} />
          </Col>
          <Col span={24}>
            Service Description:{' '}
            <TextArea disabled value={orderDetails?.description} />
          </Col>
          <Col span={24}>
            Default Note:
            <TextArea disabled value={orderDetails?.defaultNote} />
          </Col>
          <Col span={24}>
            Price: <Input disabled prefix="₹" value={orderDetails?.price} />
          </Col>
        </Row>
      </Modal>
    </>
  );
}
