import { DownloadOutlined } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import { DatePicker, Table, Tag, Typography } from 'antd';
import dayjs from 'dayjs';
import { capitalize } from 'lodash';
import React, { useEffect, useState } from 'react';
import {
  LIMIT,
  PAGE_SIZE_OPTIONS,
  REPORT_STATUS_COLOR,
  transactionDate,
} from '../../common/constants';
import { ADMIN_REPORTS } from './graphql/queries';

const { Title } = Typography;
const { RangePicker } = DatePicker;

function Reports() {
  const [filter, setFilter] = useState({
    skip: 1,
    limit: LIMIT,
  });

  const [fetchReports, { data: reports, loading }] = useLazyQuery(
    ADMIN_REPORTS,
    {
      fetchPolicy: 'network-only',
      onError() {},
    },
  );

  useEffect(() => {
    const { startDate, endDate, skip, limit } = filter;
    fetchReports({
      variables: {
        filter: { startDate, endDate, skip: (skip - 1) * limit, limit },
        sort: [{ sortBy: 'DESC', sortOn: 'createdAt' }],
      },
    });
  }, [filter]);

  const handleTableChange = (pagination) => {
    setFilter({
      ...filter,
      skip: pagination?.current,
      limit: pagination?.pageSize,
    });
  };

  const handleDatePicker = (date) => {
    setFilter({
      ...filter,
      skip: 1,
      startDate: date && dayjs(date?.[0])?.startOf('day'),
      endDate: date && dayjs(date?.[1])?.endOf('day'),
    });
  };

  const columns = [
    {
      title: 'Job Filters',
      dataIndex: 'filter',
      align: 'center',
      render: (_, record) => (
        <>
          <div>
            {dayjs(record?.filter?.data?.startTime).format(transactionDate)} -{' '}
            {dayjs(record?.filter?.data?.endTime).format(transactionDate)}
          </div>
          <span>
            {record?.filter?.consultant?.firstName}{' '}
            {record?.filter?.consultant?.lastName}
          </span>
        </>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      align: 'center',
      render: (status) => (
        <Tag color={REPORT_STATUS_COLOR?.[status]}>{capitalize(status)}</Tag>
      ),
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'action',
      align: 'center',
      render: (record) =>
        record?.status !== 'FAILED' ? (
          <a href={record?.fileKey} aria-label="Download file">
            <DownloadOutlined />
          </a>
        ) : null,
    },
  ];

  return (
    <>
      <Title className="site-page-header p-0 mb-8 mt-0" level={3}>
        Reports
        {reports?.adminReports?.count
          ? ` (${reports?.adminReports?.count})`
          : ''}
      </Title>
      <div className="d-flex gap-12 filter-input mb-12">
        <div className="d-flex gap-12">
          <RangePicker onChange={handleDatePicker} />
        </div>
      </div>
      <Table
        columns={columns}
        rowKey={(record) => record?.id}
        dataSource={reports?.adminReports?.data}
        onChange={handleTableChange}
        scroll={{ y: `calc(100vh - 342px)` }}
        loading={loading}
        pagination={{
          total: reports?.adminReports?.count,
          current: filter?.skip,
          pageSize: filter?.limit,
          pageSizeOptions: PAGE_SIZE_OPTIONS,
        }}
      />
    </>
  );
}

export default Reports;
