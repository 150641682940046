import { gql } from '@apollo/client';

const CREATE_TEMPLATE = gql`
  mutation CreateTemplate($data: CreateTemplateInput!) {
    createTemplate(data: $data) {
      message
    }
  }
`;

const UPDATE_TEMPLATE = gql`
  mutation UpdateTemplate(
    $data: UpdateTemplateInput!
    $where: TemplateWhereUniqueInput!
  ) {
    updateTemplate(data: $data, where: $where) {
      message
    }
  }
`;

const DELETE_TEMPLATE = gql`
  mutation DeleteTemplate($where: TemplateWhereUniqueInput!) {
    deleteTemplate(where: $where) {
      message
    }
  }
`;

const UPDATE_TEMPLATE_STATUS = gql`
  mutation UpdateTemplateStatus(
    $data: UpdateTemplateStatusInput!
    $where: TemplateWhereUniqueInput!
  ) {
    updateTemplateStatus(data: $data, where: $where) {
      message
    }
  }
`;

export {
  CREATE_TEMPLATE,
  DELETE_TEMPLATE,
  UPDATE_TEMPLATE,
  UPDATE_TEMPLATE_STATUS,
};
