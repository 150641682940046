import { gql } from '@apollo/client';

export const CREATE_SERVICE = gql`
  mutation CreateService($data: ServiceInputData!) {
    createService(data: $data) {
      message
    }
  }
`;

export const UPDATE_SERVICE = gql`
  mutation UpdateService(
    $where: ServiceWhereUniqueId!
    $data: UpdateServiceInputData!
  ) {
    updateService(where: $where, data: $data) {
      message
    }
  }
`;

export const DELETE_SERVICE = gql`
  mutation DeleteService($where: ServiceWhereUniqueId!) {
    deleteService(where: $where) {
      message
    }
  }
`;
