import { gql } from '@apollo/client';

const USER_ANALYTICS = gql`
  query UserCounts($filter: UserCountsFilter!) {
    userCounts(filter: $filter) {
      activeUsers
      inactiveUsers
      phoneNumberVerifiedUsers
      emailVerifiedUsers
      totalUsers
      phoneAndEmailVerifiedUsers
    }
  }
`;
export default USER_ANALYTICS;
