import { UploadOutlined, WarningOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Modal, Upload } from 'antd';
import React, { useState } from 'react';
import { UploadTrashIcon } from '../../../assets/svg';
import { beforeUploadDocFile } from '../../../common/utils';
import { UPDATE_SERVICE_PURCHASE_MUTATION } from '../graphql/mutations';
import { GET_ATTACHMENT_SIGNED_URL } from '../graphql/queries';

function ServiceCompletedPopUp({
  serviceId,
  handleClose,
  isOpenServiceCompletePopup,
}) {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [updateServicePurchaseCall] = useMutation(
    UPDATE_SERVICE_PURCHASE_MUTATION,
    {
      onError() {},
    },
  );
  const [getAttachmentSignedUrlCall] = useLazyQuery(GET_ATTACHMENT_SIGNED_URL);

  const handleFileChange = async (info) => {
    const fileObj = info?.file?.originFileObj;
    setFile(fileObj);
  };

  const handleCompleteService = async () => {
    try {
      let fileKey;
      if (file && Object?.keys(file)?.length > 0) {
        const uploadImagePromise = new Promise((resolve, reject) => {
          getAttachmentSignedUrlCall({
            fetchPolicy: 'network-only',
            variables: {
              data: {
                fileName: file?.name,
              },
            },
            onCompleted: async ({ getMessageAttachmentSignedUrl }) => {
              try {
                const response = await fetch(
                  `${getMessageAttachmentSignedUrl?.signedUrl}`,
                  {
                    method: 'PUT',
                    body: file,
                  },
                );
                if (response?.status === 200) {
                  fileKey = getMessageAttachmentSignedUrl?.fileName;
                  resolve();
                }
              } catch (error) {
                setLoading(false);
                reject();
              }
            },
            onError(error) {
              setLoading(false);
              reject(error);
            },
          });
        });
        await uploadImagePromise;
      }
      updateServicePurchaseCall({
        variables: {
          where: {
            id: serviceId,
          },
          data: {
            isCompleted: true,
            summaryKey: fileKey,
          },
        },
        onCompleted: () => {
          handleClose();
        },
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  return (
    <Modal
      open={isOpenServiceCompletePopup}
      footer={[
        <Button
          key="cancel"
          className="payment-complete-btn-width"
          size="small"
          onClick={handleClose}
        >
          Cancel
        </Button>,
        <Button
          key="service_completed"
          className="payment-complete-btn-width"
          size="small"
          type="primary"
          onClick={() => handleCompleteService()}
          disabled={!file || loading}
        >
          Service Completed
        </Button>,
      ]}
      width={512}
      onCancel={handleClose}
    >
      <div className="d-flex flex-vertical gap-24 service-complete-popup">
        <div className="d-flex flex-horizontal gap-8 align-center">
          <div className="d-flex align-center">
            <WarningOutlined className="warning-icon" />
          </div>
          <div>Attention</div>
        </div>
        <div>
          Completing this service, chat will disabled and service will be marked
          as completed
        </div>
        <div>
          <div>Summary Document</div>
          <div>
            <Upload
              name="avatar"
              className="full-width"
              showUploadList={false}
              beforeUpload={beforeUploadDocFile}
              onChange={handleFileChange}
              accept=".doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            >
              {!file ? (
                <button className="complete-service-upload-btn" type="button">
                  <div className="d-flex flex-vertical">
                    <div>
                      <UploadOutlined className="upload-icon" />
                    </div>
                    <div className="upload-text ">Upload</div>
                  </div>
                </button>
              ) : (
                <div className="d-flex flex-horizontal gap-8 justify-between align-center selected-upload-file">
                  <div>document.pdf</div>
                  <div
                    className="pointer d-flex align-center"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setFile(null);
                    }}
                  >
                    <UploadTrashIcon />
                  </div>
                </div>
              )}
            </Upload>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ServiceCompletedPopUp;
