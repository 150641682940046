import { gql } from '@apollo/client';

export const CREATE_CIRCULAR = gql`
  mutation CreateCircularAdmin($data: CreateCircularInput!) {
    createCircularAdmin(data: $data) {
      message
    }
  }
`;

export const UPDATE_CIRCULAR = gql`
  mutation UpdateCircularAdmin(
    $data: UpdateCircularInput!
    $where: CircularIdWhereUniqueInput!
  ) {
    updateCircularAdmin(data: $data, where: $where) {
      message
    }
  }
`;

export const DELETE_CIRCULAR = gql`
  mutation DeleteCircularAdmin($where: CircularIdWhereUniqueInput!) {
    deleteCircularAdmin(where: $where) {
      message
    }
  }
`;
