import { Modal } from 'antd';
import React from 'react';

function CallRecordingViewer({ videoUrl, handleClose }) {
  return (
    <Modal
      title="Recorded video"
      centered
      open={Boolean(videoUrl)}
      onOk={handleClose}
      onCancel={handleClose}
      footer={null}
      width="80%"
    >
      {/*  eslint-disable-next-line jsx-a11y/media-has-caption */}
      <video height="100%" autoPlay width="100%" src={videoUrl} controls />
    </Modal>
  );
}

export default CallRecordingViewer;
