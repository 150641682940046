import { useMutation } from '@apollo/client';
import { useEffect } from 'react';
import { ROUTES } from '../../common/constants';
import useRouter from '../../hooks/useRouter';
import { LOGOUT_USER } from './graphql/queries';

const Logout = () => {
  const { navigate } = useRouter();
  const [logoutCall] = useMutation(LOGOUT_USER);
  useEffect(() => {
    logoutCall({
      onCompleted: () => {
        // eslint-disable-next-line no-undef
        localStorage.clear();
        navigate(ROUTES?.LOGIN);
      },
      onError: () => {
        // eslint-disable-next-line no-undef
        localStorage.clear();
        navigate(ROUTES?.LOGIN);
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default Logout;
