import { gql } from '@apollo/client';

export const LIST_JUDGES = gql`
  query JudgesAdmin($filter: ListJudgeFilter, $sort: [ListJudgeSort!]) {
    judgesAdmin(filter: $filter, sort: $sort) {
      count
      data {
        _id
        name
        slug
        type
        description
        category
        court
        isVisible
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_JUDGE_DETAILS = gql`
  query JudgeAdmin($where: JudgeIdWhereUniqueInput!) {
    judgeAdmin(where: $where) {
      _id
      id
      name
      slug
      type
      description
      category
      court
      noSpaceName
      mergedNoSpaceNames
      isVisible
      createdAt
      updatedAt
    }
  }
`;
