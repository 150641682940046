import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import ActsList from './ActsList';
import CreateEditAct from './CreateEditAct';

const ActsWrapper = () => (
  <Routes>
    <Route path={ROUTES.MAIN} exact element={<ActsList />} />
    <Route path={ROUTES.CREATE} exact element={<CreateEditAct />} />
    <Route path={ROUTES.EDIT} exact element={<CreateEditAct />} />
  </Routes>
);
export default ActsWrapper;
