import { gql } from '@apollo/client';

export const GET_PAYMENT_DISTRIBUTION_CONFIG = gql`
  query Configs($filter: ListConfigFilter, $sort: [ListConfigSort!]) {
    configs(filter: $filter, sort: $sort) {
      count
      data {
        id
        key
        value
      }
    }
  }
`;

export const GET_PAYMENT_DISTRIBUTION_FOR_USER = gql`
  query PaymentDistributionAdmin($where: PaymentDistributionWhereInput!) {
    paymentDistributionAdmin(where: $where) {
      type
      paidPremiumBasedCommission
      subscriptionBasedCommission
      consultationCall {
        type
        value
      }
      service
      referral
    }
  }
`;
