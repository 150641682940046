import {
  ArrowLeftOutlined,
  CloseCircleOutlined,
  DownOutlined,
} from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Alert,
  Button,
  Card,
  Col,
  Dropdown,
  Form,
  Image,
  Input,
  Row,
  Select,
  Space,
  Upload,
} from 'antd';
import { omit } from 'lodash';
import React, { useEffect, useState } from 'react';
import {
  ROUTES,
  SERVICE_PAYMENT_OPTION,
  SERVICE_STATUS,
  SERVICE_STATUS_OPTION,
  SERVICE_VISIBILITY_TYPE,
} from '../../common/constants';
import {
  beforeUpload,
  formValidatorRules,
  getBase64,
} from '../../common/utils';
import useRouter from '../../hooks/useRouter';
import DynamicFields from './components/DynamicFields';
import { CREATE_SERVICE, UPDATE_SERVICE } from './graphql/mutations';
import { CONSULTANT_SERVICE, GET_SERVICE_SIGNED_URL } from './graphql/queries';
import './services.less';

const defaultFormValue = {
  serviceName: '',
  serviceDescription: '',
  image: '',
  status: '',
  serviceMode: '',
  price: '',
  paymentMode: '',
};
function EditService() {
  const { params, navigate } = useRouter();
  const { id } = params;
  const [form] = Form?.useForm();

  const [loading, setLoading] = useState(false);

  const [isFieldsChanged, setIsFieldsChanged] = useState(false);

  const [benefits, setBenefits] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [timelines, setTimelines] = useState([]);
  const [faqs, setFaqs] = useState([]);

  const [benefitsValue, setBenefitsValue] = useState({
    title: '',
    description: '',
  });
  const [documentsValue, setDocumentsValue] = useState({
    title: '',
    description: '',
  });
  const [timelinesValue, setTimelinesValue] = useState({
    title: '',
  });
  const [faqsValue, setFaqsValue] = useState({
    question: '',
    answer: '',
  });
  const [file, setFile] = useState({
    imageFileObject: null,
    previewUrl: null,
    isPreviewUrlChange: false,
  });

  const [fetchServiceCall] = useLazyQuery(CONSULTANT_SERVICE, {
    fetchPolicy: 'network-only',
  });
  const [getServiceImageSignedUrlCall] = useLazyQuery(GET_SERVICE_SIGNED_URL);
  const [createServiceCall] = useMutation(CREATE_SERVICE);
  const [updateServiceCall] = useMutation(UPDATE_SERVICE);
  const [serviceStatus, setServiceStatus] = useState(SERVICE_STATUS.DRAFT);

  useEffect(() => {
    if (id) {
      fetchServiceCall({
        fetchPolicy: 'network-only',
        skip: !id,
        variables: {
          where: { id: id ?? '' },
        },
        onCompleted: ({ service }) => {
          const omitTypename = (items) =>
            items?.map((item) => omit(item, '__typename'));
          setServiceStatus(service?.status);
          setBenefits(omitTypename(service?.benefits));
          setDocuments(omitTypename(service?.requiredDocuments));
          setFaqs(omitTypename(service?.FAQs));
          setTimelines(omitTypename(service?.timeline));
          setFile({ ...file, previewUrl: service?.thumbnailUrl ?? '' });
          form.setFieldsValue(service);
        },
      });
    }
  }, []);

  const onFinish = async (values) => {
    setLoading(true);
    const prepareData = {
      ...values,
      visibility: SERVICE_VISIBILITY_TYPE.PUBLIC,
      status: serviceStatus,
      deliveryTime: Number(values?.deliveryTime),
      price: Number(values?.price),
      benefits,
      requiredDocuments: documents,
      timeline: timelines?.map((item, i) => ({ ...item, stepNo: i + 1 })),
      FAQs: faqs,
    };
    if (file.isPreviewUrlChange) {
      const uploadImagePromise = new Promise((resolve, reject) => {
        getServiceImageSignedUrlCall({
          fetchPolicy: 'network-only',
          variables: {
            data: {
              contentType: file?.imageFileObject?.type,
              fileName: file?.imageFileObject?.name,
              fileSize:
                file?.imageFileObject?.size &&
                file?.imageFileObject?.size / 1024 / 1024,
              height: 200,
              width: 200,
            },
          },
          onCompleted: async ({ getServiceThumbnailSignedPutUrl }) => {
            try {
              const response = await fetch(
                `${getServiceThumbnailSignedPutUrl?.signedUrl}`,
                {
                  method: 'PUT',
                  body: file?.imageFileObject,
                },
              );
              if (response?.status === 200) {
                prepareData.thumbnailUrl =
                  getServiceThumbnailSignedPutUrl?.fileName ?? '';
                resolve();
              }
            } catch (error) {
              setLoading(false);
              reject();
            }
          },
          onError(error) {
            setLoading(false);
            reject(error);
          },
        });
      });
      await uploadImagePromise;
    }

    if (id) {
      updateServiceCall({
        variables: {
          where: { id: id ?? '' },
          data: prepareData,
        },
        onCompleted: (res) => {
          if (res?.updateService?.message) {
            setLoading(false);
            navigate(ROUTES.SERVICE);
          }
        },
      });
    } else {
      createServiceCall({
        variables: {
          data: prepareData,
        },
        onCompleted: (res) => {
          if (res?.createService?.message) {
            setLoading(false);
            navigate(ROUTES.SERVICE);
          }
        },
      });
    }
  };

  const { required } = formValidatorRules;

  const handleFileChange = async (e) => {
    const fileObj = e?.file?.originFileObj;
    const previewImg = await getBase64(fileObj);
    setFile({
      previewUrl: previewImg,
      isPreviewUrlChange: true,
      imageFileObject: fileObj,
    });
    form?.setFieldsValue({
      thumbnailUrl: previewImg,
    });
  };

  const handleServiceStatusChange = (e) => {
    setServiceStatus(e.key);
  };

  return (
    <>
      <div className="title">
        <Button
          type="text"
          shape="circle"
          onClick={() => navigate(ROUTES.SERVICE)}
          icon={<ArrowLeftOutlined />}
        />
        {id ? 'Edit Service' : 'Add Service'}
      </div>
      <Card className="ant-body-scroll">
        <div className="card-body-wrapper d-flex flex-vertical gap-20 ">
          {id &&
            [SERVICE_STATUS?.DRAFT, SERVICE_STATUS?.IN_REVIEW].includes(
              serviceStatus,
            ) && (
              <Alert
                message="Service Status"
                type="info"
                showIcon
                action={
                  <>
                    <Dropdown
                      menu={{
                        items: SERVICE_STATUS_OPTION,
                        onClick: handleServiceStatusChange,
                      }}
                      trigger={['click']}
                    >
                      <Button type="primary">
                        <Space>
                          Select Status
                          <DownOutlined />
                        </Space>
                      </Button>
                    </Dropdown>
                  </>
                }
              />
            )}
          <Form
            form={form}
            name="register"
            onFinish={onFinish}
            onValuesChange={() => setIsFieldsChanged(true)}
            initialValues={defaultFormValue}
            layout="vertical"
          >
            <Row gutter={16}>
              <Col xs={24} sm={12} md={24}>
                <Row>
                  <Col xs={24} sm={12} md={10}>
                    <Form.Item
                      name="thumbnailUrl"
                      rules={[
                        { required, message: 'Please upload Preview Image' },
                      ]}
                    >
                      <div className="upload-preview-img">
                        {file?.previewUrl === null ? (
                          <Upload
                            listType="picture-card"
                            showUploadList={false}
                            beforeUpload={(e) => beforeUpload(e, 10)}
                            onChange={(e) => handleFileChange(e)}
                            accept=".jpeg,.jpg,.png"
                          >
                            Add Preview Image
                          </Upload>
                        ) : (
                          <div className="remove-img-btn">
                            <Image
                              height={150}
                              width={150}
                              src={file?.previewUrl}
                            />
                            <div className="close-icon">
                              <Button
                                className="custom-remove-btn"
                                shape="rounded"
                                type="primary"
                                danger
                                onClick={() => {
                                  setFile({ ...file, previewUrl: null });
                                  form?.setFieldValue('thumbnailUrl', null);
                                }}
                                icon={<CloseCircleOutlined />}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item
                  name="title"
                  label="Service Name"
                  rules={[{ required, message: 'Please enter Service Name' }]}
                >
                  <Input placeholder="Enter your Service Name" />
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item
                  label="Short Description"
                  name="shortDescription"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter Short Description',
                    },
                  ]}
                >
                  <Input.TextArea rows={5} placeholder="Short Description..." />
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item
                  label="Long Description"
                  name="longDescription"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter Long Description',
                    },
                  ]}
                >
                  <Input.TextArea rows={5} placeholder="Long Description..." />
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item
                  label="Delivery Time"
                  name="deliveryTime"
                  className="duration-input"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter Delivery Time',
                    },
                  ]}
                >
                  <Input
                    type="number"
                    className="common-input-addon"
                    addonAfter={
                      <span className="body-base text-dark">day(s)</span>
                    }
                    placeholder="10"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item
                  label="Payment"
                  name="paymentType"
                  rules={[
                    {
                      required: true,
                      message: 'Please select Payment type',
                    },
                  ]}
                >
                  <Select
                    placeholder="Choose type"
                    options={SERVICE_PAYMENT_OPTION}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item
                  label="Price"
                  name="price"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter Price',
                    },
                  ]}
                >
                  <Input
                    addonBefore={<span className="body-base text-dark">₹</span>}
                    placeholder="Price"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item label="Embed Link" name="embeddedUrl">
                  <Input placeholder="Embed Link" />
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item
                  label="Consultation Call Link"
                  name="consultationCallUrl"
                >
                  <Input placeholder="Consultation Call Link" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24}>
                <p className="body-m text-dark mb-2">Benefits (Max. 10)</p>
                <DynamicFields
                  items={benefits}
                  maxItems={10}
                  itemValue={benefitsValue}
                  placeholder="Benefits "
                  setItemValue={setBenefitsValue}
                  setItem={setBenefits}
                  defaultValue={{ title: '', description: '' }}
                  fieldNames={{ title: 'title', description: 'description' }}
                />
              </Col>
              <Col xs={24} sm={24} md={24}>
                <p className="body-m text-dark mb-2">
                  Documents Required (Max. 10)
                </p>
                <DynamicFields
                  items={documents}
                  maxItems={10}
                  itemValue={documentsValue}
                  placeholder="Document "
                  setItemValue={setDocumentsValue}
                  setItem={setDocuments}
                  defaultValue={{ title: '', description: '' }}
                  fieldNames={{ title: 'title', description: 'description' }}
                />
              </Col>
              <Col xs={24} sm={24} md={24}>
                <p className="body-m text-dark mb-2">
                  Timeline of the Service (Max. 10)
                </p>
                <DynamicFields
                  items={timelines}
                  maxItems={10}
                  itemValue={timelinesValue}
                  placeholder="Step "
                  setItemValue={setTimelinesValue}
                  setItem={setTimelines}
                  defaultValue={{ title: '' }}
                  fieldNames={{ title: 'title' }}
                />
              </Col>
              <Col xs={24} sm={24} md={24}>
                <p className="body-m text-dark mb-2">
                  Frequently Asked Questions (Max. 6)
                </p>
                <DynamicFields
                  items={faqs}
                  maxItems={6}
                  itemValue={faqsValue}
                  placeholder=""
                  setItemValue={setFaqsValue}
                  setItem={setFaqs}
                  defaultValue={{ question: '', answer: '' }}
                  fieldNames={{ title: 'question', description: 'answer' }}
                />
              </Col>
            </Row>
            <Form.Item>
              <Space size={8}>
                <Button
                  onClick={() => navigate(ROUTES.TEMPLATE)}
                  htmlType="submit"
                >
                  Cancel
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={!isFieldsChanged}
                  loading={loading}
                  onClick={() => form?.handleSubmit}
                >
                  Save
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </div>
      </Card>
    </>
  );
}

export default EditService;
