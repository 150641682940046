import { gql } from '@apollo/client';

export const GET_SERVICES = gql`
  query Services($filter: ListServicesFilter!, $sort: [ListServicesSort!]!) {
    services(filter: $filter, sort: $sort) {
      count
      data {
        id
        title
        createdBy
        status
        visibility
        paymentType
        price
      }
    }
  }
`;

export const CONSULTANT_SERVICE = gql`
  query ConsultantService($where: ServiceWhereUniqueId!) {
    service(where: $where) {
      id
      price
      title
      timeline {
        id
        stepNo
        title
      }
      FAQs {
        id
        answer
        question
      }
      benefits {
        id
        description
        title
      }
      requiredDocuments {
        id
        title
        description
      }
      consultationCallUrl
      deliveryTime
      embeddedUrl
      longDescription
      paymentType
      shortDescription
      thumbnailUrl
      status
    }
  }
`;

export const GET_SERVICE_SIGNED_URL = gql`
  query GetServiceThumbnailSignedPutUrl(
    $data: GetServiceThumbnailSignedPutUrlInput!
  ) {
    getServiceThumbnailSignedPutUrl(data: $data) {
      fileName
      signedUrl
    }
  }
`;

export default GET_SERVICES;
