import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import ReviewAppeals from './ReviewAppeals';

const ReviewAppealsWrapper = () => (
  <Routes>
    <Route path={ROUTES.MAIN} exact element={<ReviewAppeals />} />
  </Routes>
);
export default ReviewAppealsWrapper;
