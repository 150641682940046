import { gql } from '@apollo/client';

export const LOGIN = gql`
  mutation LoginAdmin($data: LoginAdminInput!) {
    loginAdmin(data: $data) {
      message
      data {
        isPasswordSet
        email
        firstName
        lastName
        phoneNumber
        state
        city
        companyName
        roles
        profession
        profileImage
        isActive
        lastLogin
        pinCode
        address
        unreadNotifications
        userBenches {
          benchId
          benchKey
          benchSlug
        }
      }
      accessToken
      refreshToken
    }
  }
`;

export const SIGNUP = gql`
  mutation emailPasswordSignUp($data: EmailPasswordSignUpData!) {
    emailPasswordSignUp(data: $data) {
      message
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation ForgotPasswordAdmin($data: ForgotPasswordAdminInput!) {
    forgotPasswordAdmin(data: $data) {
      message
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation emailPasswordResetPassword($data: EmailPasswordResetPasswordData!) {
    emailPasswordResetPassword(data: $data) {
      message
    }
  }
`;

export const VERIFY_TOKEN = gql`
  mutation emailPasswordVerifyResetToken($token: String!) {
    emailPasswordVerifyResetToken(data: { token: $token }) {
      message
      data {
        isValidToken
      }
    }
  }
`;

export const UPDATE_CURRENT_USER = gql`
  mutation UpdateUserProfile($data: UserProfileData!) {
    updateUserProfile(data: $data) {
      message
      data {
        lastName
        email
        isPasswordSet
        isActive
        lastLogin
        firstName
        phoneNumber
        phoneNumberVerifiedAt
        roles
        tempEmail
        profileImage
        userBenches {
          benchId
          benchKey
          benchSlug
        }
      }
    }
  }
`;

export const SET_PASSWORD = gql`
  mutation setPasswordAdmin($data: AdminPasswordData!) {
    setPasswordAdmin(data: $data) {
      message
      token
      user {
        tempEmail
        phoneNumberVerifiedAt
        phoneNumber
        lastName
        lastLogin
        isPasswordSet
        isActive
        id
        firstName
        emailVerifiedAt
        email
        countryCode
        roles
      }
    }
  }
`;

export const VERIFY_EMAIL = gql`
  mutation verifyEmail($data: VerifyEmailInputData!) {
    verifyEmail(data: $data) {
      isPasswordSet
      message
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation ChangePassword($data: ChangePasswordData!) {
    changePassword(data: $data) {
      message
    }
  }
`;
