import { ArrowLeftOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import { Button, Card, Form, Modal, Pagination } from 'antd';
import { isObject } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { mongoClient } from '../../apollo';
import { LIMIT, ROUTES } from '../../common/constants';
import LoaderComponent from '../../components/LoaderComponent';
import useRouter from '../../hooks/useRouter';
import ExpertSummaryList from './components/ExpertSummaryList';
import JudgementForm from './components/JudgementForm';
import { DELETE_JUDGEMENT, EDIT_JUDGEMENT_DETAILS } from './graphql/mutations';
import {
  GET_JUDGEMENT_DETAILS,
  LIST_EXPERT_SUMMARIES,
} from './graphql/queries';

const { confirm: deleteConfirm } = Modal;

export default function EditJudgement({
  isReviewAppeal = false,
  appealId = null,
  getFormData,
  getJudgementLoadingValues,
  onUpdateJudgement,
  onAssesseeUpdate,
  fetchInitialList,
}) {
  const { params, navigate } = useRouter();
  const [form] = Form.useForm();
  const [showSummary, setShowSummary] = useState(false);
  const [paginationFilter, setPaginationFilter] = useState({ currentPage: 1 });
  const [judgementData, setJudgementData] = useState();
  const [loadingDetails, setLoadingDetails] = useState();
  const [updateLoading, setUpdateLoading] = useState();
  const [id, setId] = useState(null);
  const styling = isReviewAppeal
    ? { sm: 24, md: 24, lg: 24 }
    : { sm: 8, md: 8, lg: 8 };
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setId(appealId ?? params?.id);
  }, [appealId, params]);

  const getJudgement = async ({ variables }) => {
    await mongoClient
      ?.query({
        query: GET_JUDGEMENT_DETAILS,
        fetchPolicy: 'network-only',
        variables,
      })
      .then((res) => {
        const formData = res?.data?.judgementAdmin;
        setJudgementData(formData);
        getFormData?.(formData);
        setLoadingDetails(false);
        getJudgementLoadingValues?.(false);
      })
      ?.catch(() => {
        setLoadingDetails(false);
        getJudgementLoadingValues?.(false);
      });
  };

  const filterVariables = {
    filter: { where: { id }, limit: LIMIT, skip: 0 },
    sort: [{ sortOn: 'createdAt', sortBy: 'ASC' }],
  };

  const [
    getExpertSummaries,
    { data: summaryData, loading: loadingSummaries, refetch },
  ] = useLazyQuery(LIST_EXPERT_SUMMARIES, {
    fetchPolicy: 'network-only',
    variables: filterVariables,
    onError() {},
  });

  const updateJudgement = async ({ variables }) => {
    await mongoClient
      ?.mutate({
        mutation: EDIT_JUDGEMENT_DETAILS,
        variables,
      })
      ?.then(() => {
        setUpdateLoading(false);
        if (!isReviewAppeal) navigate(ROUTES.APPEALS);
        if (isReviewAppeal) {
          onUpdateJudgement?.();
        }
      })
      ?.catch(() => {
        setUpdateLoading(false);
      });
  };

  useEffect(() => {
    if (id) {
      getJudgement({ variables: { where: { id } } });
      getExpertSummaries();
    }
  }, [id]);
  const isFirstRender = useRef(true);
  useEffect(() => {
    if (isFirstRender?.current) {
      isFirstRender.current = false;
      return;
    }
    const { skip, limit } = paginationFilter;
    getExpertSummaries({
      variables: {
        filter: { where: { id }, skip, limit },
        sort: [{ sortOn: 'createdAt', sortBy: 'ASC' }],
      },
    });
  }, [paginationFilter]);

  const isArrayOfObjects = (arr) => {
    if (!Array.isArray(arr)) {
      return false;
    }
    return arr?.every((item) => typeof item === 'object' && item !== null);
  };

  const onFinish = (values) => {
    const prepareValues = values;
    prepareValues.dateOfPronouncement = prepareValues?.dateOfPronouncement
      ? prepareValues?.dateOfPronouncement?.toISOString()
      : '';
    prepareValues.appealFiledOn = prepareValues?.appealFiledOn
      ? prepareValues?.appealFiledOn?.toISOString()
      : '';
    prepareValues.dateOfFirstHearing = prepareValues?.dateOfFirstHearing
      ? prepareValues?.dateOfFirstHearing?.toISOString()
      : '';
    prepareValues.dateOfLastHearing = prepareValues?.dateOfLastHearing
      ? prepareValues?.dateOfLastHearing?.toISOString()
      : '';
    prepareValues.dateOfNextHearing = prepareValues?.dateOfNextHearing
      ? prepareValues?.dateOfNextHearing?.toISOString()
      : '';
    prepareValues.dateOfFinalHearing = prepareValues?.dateOfFinalHearing
      ? prepareValues?.dateOfFinalHearing?.toISOString()
      : '';
    prepareValues.dateOfTribunalOrder = prepareValues?.dateOfTribunalOrder
      ? prepareValues?.dateOfTribunalOrder?.toISOString()
      : '';
    prepareValues.dateOfOrder = prepareValues?.dateOfOrder
      ? prepareValues?.dateOfOrder?.toISOString()
      : '';
    // eslint-disable-next-line no-nested-ternary
    prepareValues.assessee = prepareValues?.assessee
      ? isObject(prepareValues?.assessee)
        ? prepareValues?.assessee?.value
        : prepareValues?.assessee
      : '';
    // eslint-disable-next-line no-underscore-dangle
    prepareValues.bench = prepareValues?.bench?._id
      ? // eslint-disable-next-line no-underscore-dangle
        prepareValues?.bench?._id
      : [];
    // eslint-disable-next-line no-nested-ternary
    prepareValues.judges = prepareValues?.judges?.length
      ? isArrayOfObjects(prepareValues?.judges)
        ? prepareValues?.judges?.map((item) => item.value)
        : prepareValues?.judges
      : [];
    // eslint-disable-next-line no-nested-ternary
    prepareValues.keywords = prepareValues?.keywords?.length
      ? isArrayOfObjects(prepareValues?.keywords)
        ? prepareValues?.keywords?.map((item) => item.value)
        : prepareValues?.keywords
      : [];
    // eslint-disable-next-line no-nested-ternary
    prepareValues.sections = prepareValues?.sections?.length
      ? isArrayOfObjects(prepareValues?.sections)
        ? prepareValues?.sections?.map((item) => item.value)
        : prepareValues?.sections
      : [];
    // eslint-disable-next-line no-nested-ternary
    prepareValues.assesseeLawyers = prepareValues?.assesseeLawyers?.length
      ? isArrayOfObjects(prepareValues?.assesseeLawyers)
        ? prepareValues?.assesseeLawyers?.map((item) => item.value)
        : prepareValues?.assesseeLawyers
      : [];
    // eslint-disable-next-line no-nested-ternary
    prepareValues.departmentLawyers = prepareValues?.departmentLawyers?.length
      ? isArrayOfObjects(prepareValues?.departmentLawyers)
        ? prepareValues?.departmentLawyers?.map((item) => item.value)
        : prepareValues?.departmentLawyers
      : [];
    prepareValues.downloadCounter = Number(values?.downloadCounter);
    if (id) {
      updateJudgement({
        variables: {
          where: { id },
          data: { ...prepareValues },
        },
      });
    }
  };

  const handlePagination = (page, pageSize) => {
    setPaginationFilter({
      ...paginationFilter,
      skip: (page - 1) * pageSize,
      limit: pageSize,
      currentPage: page,
    });
  };

  const deleteJudgement = async () => {
    setLoading(true);
    await mongoClient
      ?.mutate({
        mutation: DELETE_JUDGEMENT,
        variables: {
          where: {
            id,
          },
        },
      })
      .then(() => {
        if (isReviewAppeal) {
          fetchInitialList?.();
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDelete = () => {
    deleteConfirm({
      centered: true,
      title: 'Are you sure to delete?',
      icon: <ExclamationCircleFilled />,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      okButtonProps: { loading },
      onOk() {
        deleteJudgement();
      },
      onCancel() {},
    });
  };

  if (loadingDetails || loadingSummaries) return <LoaderComponent />;

  return (
    <>
      <Card
        className="ant-body-scroll"
        title={
          !isReviewAppeal ? (
            <>
              <div className="title">
                <Button
                  type="text"
                  shape="circle"
                  onClick={() => navigate(ROUTES.APPEALS)}
                  icon={<ArrowLeftOutlined />}
                />
                Edit Appeal
              </div>
            </>
          ) : null
        }
        extra={
          !isReviewAppeal ? (
            <>
              <Button onClick={() => setShowSummary(!showSummary)}>
                {showSummary ? 'Edit Appeal' : 'View Expert Summary'}
              </Button>
            </>
          ) : null
        }
      >
        {!isReviewAppeal &&
          showSummary &&
          (summaryData?.adminExpertSummaries?.data?.length > 0 ? (
            <div className="card-body-wrapper">
              <ExpertSummaryList
                summaryData={summaryData?.adminExpertSummaries?.data}
                refetch={refetch}
              />
              <div className="d-flex justify-end mt-32">
                <Pagination
                  defaultCurrent={1}
                  current={paginationFilter?.currentPage}
                  onChange={handlePagination}
                  total={summaryData?.adminExpertSummaries?.count}
                  pageSize={LIMIT}
                />
              </div>
            </div>
          ) : (
            <div className="no-summary-block d-flex justify-center align-center">
              <h3>No Expert summary available</h3>
            </div>
          ))}
        {(isReviewAppeal || !showSummary) && (
          <JudgementForm
            form={form}
            onFinish={onFinish}
            loading={updateLoading}
            initialValues={judgementData}
            loadingJudgementDetails={loadingDetails}
            styling={styling}
            hideAddButtons={!isReviewAppeal}
            onAssesseeUpdate={onAssesseeUpdate}
            handleDelete={handleDelete}
          />
        )}
      </Card>
    </>
  );
}
