import {
  AccountBookOutlined,
  AuditOutlined,
  BookOutlined,
  ContactsOutlined,
  ContainerOutlined,
  FileDoneOutlined,
  FileSyncOutlined,
  FileZipOutlined,
  FolderOpenOutlined,
  GoldOutlined,
  HomeOutlined,
  ProfileOutlined,
  SnippetsOutlined,
  TeamOutlined,
  UserOutlined,
  WalletOutlined,
  WechatOutlined,
} from '@ant-design/icons';
import { Menu } from 'antd';
import React, { useMemo } from 'react';
import { ReactComponent as ServiceIcon } from '../../../assets/svg/service-icon.svg';
import { MODULES, ROLE_KEYS, ROUTES, USER } from '../../../common/constants';
import useRouter from '../../../hooks/useRouter';

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

function Sidebar() {
  // eslint-disable-next-line no-undef
  const user = JSON.parse(localStorage.getItem(USER));
  const {
    navigate,
    location: { pathname },
  } = useRouter();
  const onMenuSelect = (e) => {
    navigate(e?.key);
  };

  const menuItems = useMemo(() => {
    const baseItems = [
      getItem(MODULES.DASHBOARD, ROUTES.MAIN, <HomeOutlined />),
      getItem(MODULES.USER, ROUTES.USERS, <TeamOutlined />),
      getItem(
        MODULES.TEMPLATE_CATEGORY,
        ROUTES.TEMPLATE_CATEGORY,
        <SnippetsOutlined />,
      ),
      getItem(MODULES.TEMPLATE, ROUTES.TEMPLATE, <FileSyncOutlined />),
      getItem(MODULES.JUDGES, ROUTES.JUDGES, <UserOutlined />),
      getItem(MODULES.ASSESSES, ROUTES.ASSESSES, <BookOutlined />),
      getItem(MODULES.JUDGEMENTS, ROUTES.JUDGEMENTS, <AuditOutlined />, [
        getItem(MODULES.APPEALS, ROUTES.APPEALS),
        getItem(MODULES.LAWYERS, ROUTES.LAWYERS),
        getItem(MODULES.KEYWORDS, ROUTES.KEYWORDS),
        getItem(MODULES.SECTIONS, ROUTES.SECTIONS),
      ]),
      getItem(MODULES.JUDGEMENT_AI, '', <ContainerOutlined />, [
        getItem(MODULES.ACTS, ROUTES.ACTS),
        getItem(MODULES.NOTIFICATIONS, ROUTES.NOTIFICATIONS),
        getItem(MODULES.CIRCULARS, ROUTES.CIRCULARS),
        getItem(MODULES.SUBMISSIONS, ROUTES.SUBMISSIONS),
        getItem(MODULES.ARGUMENTS, ROUTES.ARGUMENTS),
      ]),
      getItem(
        MODULES.CONSULTATION_CALLS,
        ROUTES.CONSULTATION_CALLS,
        <ContactsOutlined />,
      ),
      getItem(MODULES.QUOTATIONS, ROUTES.QUOTATIONS, <FolderOpenOutlined />),
      getItem(
        MODULES.SUBSCRIPTION_PLANS,
        ROUTES.SUBSCRIPTION_PLANS,
        <GoldOutlined />,
      ),
      getItem(
        MODULES.PAYMENT_DISTRIBUTION,
        ROUTES.PAYMENT_DISTRIBUTION,
        <AccountBookOutlined />,
      ),
      getItem(
        MODULES.TRANSACTION_LOGS,
        ROUTES.TRANSACTION_LOGS,
        <ProfileOutlined />,
      ),
      getItem(MODULES.REPORTS, ROUTES.REPORTS, <FileZipOutlined />),
      getItem(
        MODULES.REVIEW_APPEALS,
        ROUTES.REVIEW_APPEALS,
        <FileDoneOutlined />,
      ),
      getItem(MODULES.PAYMENTS, ROUTES.PAYMENTS, <WalletOutlined />),
      getItem(MODULES.SUPPORT, ROUTES.SUPPORT, <WechatOutlined />),
      getItem(MODULES.SERVICES, ROUTES.SERVICE, <ServiceIcon />),
    ];

    if (user?.roles?.[0] === ROLE_KEYS.SUPER_ADMIN) {
      return [...baseItems];
    }
    if (user?.roles?.[0] === ROLE_KEYS.ADMIN) {
      return [...baseItems];
    }
    if (user?.roles?.[0] === ROLE_KEYS.STAFF) {
      return [
        getItem(MODULES.DASHBOARD, ROUTES.MAIN, <HomeOutlined />),
        getItem(MODULES?.TEMPLATE, ROUTES?.TEMPLATE, <FileSyncOutlined />),
        getItem(MODULES.APPEALS, ROUTES.APPEALS, <AuditOutlined />),
        getItem(
          MODULES.REVIEW_APPEALS,
          ROUTES.REVIEW_APPEALS,
          <FileDoneOutlined />,
        ),
      ];
    }
    return baseItems;
  }, [user]);

  return (
    <Menu
      theme="light"
      mode="inline"
      selectedKeys={[`/${pathname?.split('/')?.[1]}`]}
      defaultSelectedKeys={[ROUTES.MAIN]}
      onSelect={onMenuSelect}
      onClick={onMenuSelect}
      items={menuItems}
    />
  );
}

export default Sidebar;
