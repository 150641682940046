import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import ConsultationCallDetails from './ConsultationCallDetails';
import './ConsultationCalls.less';
import ConsultationCallsList from './ConsultationCallsList';

const ConsultationCallWrapper = () => (
  <Routes>
    <Route path={ROUTES.MAIN} exact element={<ConsultationCallsList />} />
    <Route
      path={`${ROUTES.MAIN}/view/:id`}
      exact
      element={<ConsultationCallDetails />}
    />
  </Routes>
);
export default ConsultationCallWrapper;
