import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Form } from 'antd';
import React, { useState } from 'react';
import { mongoClient } from '../../apollo';
import { ROUTES } from '../../common/constants';
import useRouter from '../../hooks/useRouter';
import AssesseeForm from './components/AssesseeForm';
import { CREATE_ASSESSEE } from './graphql/mutations';

function CreateAssesses() {
  const { navigate } = useRouter();
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();

  const createAssessee = async ({ variables }) => {
    await mongoClient
      ?.mutate({
        mutation: CREATE_ASSESSEE,
        variables,
      })
      ?.then(() => {
        navigate(ROUTES.ASSESSES);
        setLoading(false);
      })
      ?.catch(() => {
        setLoading(false);
      });
  };

  const onFinish = (values) => {
    createAssessee({
      variables: {
        data: { ...values },
      },
    });
  };
  return (
    <>
      <div className="title">
        <Button
          type="text"
          shape="circle"
          onClick={() => navigate(ROUTES.ASSESSES)}
          icon={<ArrowLeftOutlined />}
        />
        Add Assessee
      </div>
      <AssesseeForm form={form} onFinish={onFinish} loading={loading} />
    </>
  );
}

export default CreateAssesses;
