import { InfoCircleOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import { Tooltip } from 'antd';
import React, { useState } from 'react';
import { LIMIT, SORT_OPTION } from '../../../common/constants';
import { FETCH_MEMBERS } from '../graphql/queries';
import MemberList from './MemberList';

function ChatHeader({ conversationId, selectedTab, selectedService }) {
  const [isOpenInfoPopup, setIsOpenInfoPopup] = useState(false);
  const { data, loading } = useQuery(FETCH_MEMBERS, {
    fetchPolicy: 'network-only',
    variables: {
      filter: {
        limit: LIMIT,
        search: null,
        skip: 0,
        type: selectedTab,
        chatId: conversationId,
      },
      sort: [
        {
          sortBy: SORT_OPTION.DESC,
          sortOn: 'createdAt',
        },
      ],
    },
  });

  return (
    <div className="chat-header d-flex flex-horizontal align-center gap-16">
      <div className="d-flex flex-vertical justify-center full-width">
        <div className="body-m medium">{selectedService?.name}</div>
        <div className="body-base regular sub-details ">
          {data?.chatMembers?.data
            ?.slice(0, 3)
            ?.map(
              (member, index, arr) =>
                `${member?.user?.firstName}${
                  arr?.length !== index + 1 ? ', ' : ''
                }`,
            )}
        </div>
      </div>
      <div>
        <Tooltip>
          <InfoCircleOutlined
            onClick={() => setIsOpenInfoPopup(true)}
            className="info-icon"
          />
        </Tooltip>
      </div>
      <MemberList
        serviceId={selectedService?.id}
        serviceName={selectedService?.name}
        list={data?.chatMembers?.data}
        loading={loading}
        isOpen={isOpenInfoPopup}
        handleClosePopup={() => setIsOpenInfoPopup(false)}
      />
    </div>
  );
}

export default ChatHeader;
