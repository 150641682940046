import { gql } from '@apollo/client';

export const CREATE_USER = gql`
  mutation CreateAdmin($data: AdminSignUpInput!) {
    createAdmin(data: $data) {
      user {
        id
        firstName
        lastName
        email
        phoneNumber
        roles
        isPasswordSet
        isActive
        phoneNumberVerifiedAt
        tempEmail
        emailVerifiedAt
      }
      message
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUserAdmin($userWhere: UserWhereData!, $data: AdminUpdateData) {
    updateUserAdmin(userWhere: $userWhere, data: $data) {
      token
      message
      user {
        id
        isPasswordSet
        email
        firstName
        lastName
        phoneNumber
        roles
        isActive
        countryCode
        phoneNumberVerifiedAt
        tempEmail
        emailVerifiedAt
        lastLogin
      }
    }
  }
`;
