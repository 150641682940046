import { PlusCircleOutlined } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import { Badge, Button, Checkbox, Table, Typography } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../AppContext';
import {
  LIMIT,
  MODULES,
  PAGE_SIZE_OPTIONS,
  ROUTES,
  SORT,
  TEMPLATE_STATUS,
  TEMPLATE_STATUS_COLOR,
  TEMPLATE_TYPE,
  USER_TYPE,
  USER_TYPE_FILTER,
} from '../../common/constants';
import SearchComponent from '../../components/SearchComponent';
import useRouter from '../../hooks/useRouter';
import { FETCH_TEMPLATE_LIST } from './graphql/queries';
import './template.less';



const TEMPLATE_TYPE_FILTER = [
  {
    text: 'Free',
    value: TEMPLATE_TYPE.FREE,
  },
  {
    text: 'Paid',
    value: TEMPLATE_TYPE.PAID,
  },
  {
    text: 'Premium',
    value: TEMPLATE_TYPE.PREMIUM,
  },
];

const TEMPLATE_STATUS_FILTER = [
  {
    text: 'Draft',
    value: TEMPLATE_STATUS.DRAFT,
  },
  {
    text: 'Published',
    value: TEMPLATE_STATUS.PUBLISHED,
  },
  {
    text: 'Unpublished',
    value: TEMPLATE_STATUS.UNPUBLISHED,
  },
  {
    text: 'Rejected',
    value: TEMPLATE_STATUS.REJECTED,
  },
];
const { Title } = Typography;

function TemplateList() {
  const { getCurrentUserRole } = useContext(AppContext);
  const roles = getCurrentUserRole() || {};
  const { navigate } = useRouter();
  const [params, setParams] = useState({
    filter: {
      categoryId: null,
      limit: LIMIT,
      search: '',
      skip: null,
      status: null,
      subCategoryId: null,
      types: null,
      userId: null,
    },
    sort: [
      {
        sortOn: 'createdAt',
        sortBy: 'DESC',
      },
    ],
  });
  const [userList, setUserList] = useState();
  const [loading, setLoading] = useState(false);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: LIMIT,
      pageSizeOptions: PAGE_SIZE_OPTIONS,
    },
  });

  const [fetchTemplates] = useLazyQuery(FETCH_TEMPLATE_LIST);

  function fetchTemplatesCall(filterValue = params, pageNumber = 1) {
    setLoading(true);
    fetchTemplates({
      variables: filterValue,
      fetchPolicy: 'network-only',
      onCompleted: ({ templatesAdmin }) => {
        setUserList(templatesAdmin?.data);
        const count = templatesAdmin?.count;
        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            total: count,
            current: pageNumber,
            pageSize: filterValue?.filter?.limit,
          },
        });
        setLoading(false);
      },
      onError: () => {
        setLoading(false);
      },
    });
  }

  useEffect(() => {
    fetchTemplatesCall();
  }, []);
  /* keep for future use */
  // const [deleteTemplateCall] = useMutation(DELETE_TEMPLATE);

  const handleTableChange = (pagination, filters, sorter) => {
    const prepareFilter = {
      filter: {
        categoryId: null,
        search: '',
        skip: (pagination?.current - 1) * LIMIT,
        limit: pagination?.pageSize || LIMIT,
        status:
          filters.status && Object.values(filters.status)?.length
            ? filters.status
            : [],
        creatorRoles:
          filters?.creatorRoles && filters?.creatorRoles?.length
            ? filters.creatorRoles
            : [],
        types: filters.types && filters.types.length ? filters.types : [],
        subCategoryId: null,
        userId: null,
      },
      sort: [
        {
          sortOn:
            Object.values(sorter).length && sorter.order
              ? sorter.field
              : 'createdAt',
          sortBy: sorter.order === SORT.ASC ? 'ASC' : 'DESC',
        },
      ],
    };
    setParams(prepareFilter);
    fetchTemplatesCall(prepareFilter, pagination?.current);
  };

  const handleSearchChange = (e) => {
    const prepareFilter = {
      ...params,
      filter: { ...params.filter, search: e, skip: 0 },
    };
    setParams(prepareFilter);
    fetchTemplatesCall(prepareFilter);
  };

  /* keep for future use */
  /* const handleDelete = (id) => {
    confirm({
      centered: true,
      title: 'Are you sure to delete?',
      icon: <ExclamationCircleFilled />,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        deleteTemplateCall({
          variables: {
            where: {
              id,
            },
          },
          onCompleted: () => {
            fetchTemplatesCall(params);
          },
          onError: () => {},
        });
      },
      onCancel() {},
    });
  }; */

  const renderBadge = (item) => {
    switch (item) {
      case TEMPLATE_STATUS.DRAFT:
        return <Badge count="Draft" color={TEMPLATE_STATUS_COLOR.DRAFT} />;
      case TEMPLATE_STATUS.PUBLISHED:
        return (
          <Badge count="Published" color={TEMPLATE_STATUS_COLOR.PUBLISHED} />
        );
      case TEMPLATE_STATUS.UNPUBLISHED:
        return (
          <Badge
            count="Unpublished"
            color={TEMPLATE_STATUS_COLOR.UNPUBLISHED}
          />
        );
      default:
        return <Badge count="Rejected" />;
    }
  };

  const getUserTypeFilterProps = () => ({
    filterMultiple: true,
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <div className="custom-filter-dropdown">
        {USER_TYPE_FILTER?.map((option) => (
          <div key={option?.value} className="mt-6 mb-6">
            <Checkbox
              checked={selectedKeys.includes(option?.value)}
              onChange={(e) => {
                const updatedKeys = e.target.checked
                  ? [...selectedKeys, option.value]
                  : selectedKeys.filter((key) => key !== option.value);
                setSelectedKeys(updatedKeys);
              }}
            >
              {option?.text}
            </Checkbox>
          </div>
        ))}
        <div className="control-panel">
          <Button
            type="primary"
            size="small"
            onClick={() => {
              confirm();
            }}
            className="ok-button"
          >
            Apply
          </Button>
          <Button
            className="ml-8 reset-button"
            size="small"
            onClick={() => {
              setSelectedKeys([]);
              confirm();
            }}
          >
            Reset
          </Button>
        </div>
      </div>
    ),
  });

  const getTemplateTypeFilterProps = () => ({
    filterMultiple: true,
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <div className="custom-filter-dropdown">
        {TEMPLATE_TYPE_FILTER?.map((option) => (
          <div key={option?.value} className="mt-6 mb-6">
            <Checkbox
              checked={selectedKeys.includes(option?.value)}
              onChange={(e) => {
                const updatedKeys = e.target.checked
                  ? [...selectedKeys, option.value]
                  : selectedKeys.filter((key) => key !== option.value);
                setSelectedKeys(updatedKeys);
              }}
            >
              {option?.text}
            </Checkbox>
          </div>
        ))}
        <div className="control-panel">
          <Button
            type="primary"
            size="small"
            onClick={() => {
              confirm();
            }}
            className="ok-button"
          >
            Apply
          </Button>
          <Button
            className="ml-8 reset-button"
            size="small"
            onClick={() => {
              setSelectedKeys([]);
              confirm();
            }}
          >
            Reset
          </Button>
        </div>
      </div>
    ),
  });

  const getStatusFilterProps = () => ({
    filterMultiple: true,
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <div className="custom-filter-dropdown">
        {TEMPLATE_STATUS_FILTER.map((option) => (
          <div key={option?.value} className="mt-6 mb-6">
            <Checkbox
              checked={selectedKeys.includes(option?.value)}
              onChange={(e) => {
                const updatedKeys = e.target.checked
                  ? [...selectedKeys, option.value]
                  : selectedKeys.filter((key) => key !== option.value);
                setSelectedKeys(updatedKeys);
              }}
            >
              {option?.text}
            </Checkbox>
          </div>
        ))}
        <div className="control-panel">
          <Button
            type="primary"
            size="small"
            onClick={() => {
              confirm();
            }}
            className="ok-button"
          >
            Apply
          </Button>
          <Button
            className="ml-8 reset-button"
            size="small"
            onClick={() => {
              setSelectedKeys([]);
              confirm();
            }}
          >
            Reset
          </Button>
        </div>
      </div>
    ),
  });

  return (
    <>
      <Title className="site-page-header p-0 mb-8 mt-0" level={3}>
        {MODULES.TEMPLATE}
        {tableParams?.pagination?.total
          ? ` (${tableParams?.pagination?.total})`
          : ''}
      </Title>
      <div className="filter-input mb-12">
        <SearchComponent className="list-search" getData={handleSearchChange} />
        {roles !== USER_TYPE.ADMIN && (
          <Button
            className="ml-8"
            key="1"
            type="primary"
            icon={<PlusCircleOutlined />}
            onClick={() => {
              navigate(ROUTES.ADD_TEMPLATE);
            }}
          >
            Add Template
          </Button>
        )}
      </div>
      <Table
        columns={[
          {
            title: 'Title',
            dataIndex: 'title',
            sorter: true,
          },
          {
            title: 'Created By',
            render: (item) => {
              switch (item?.creator?.roles?.[0]) {
                case USER_TYPE.ADMIN:
                  return 'Admin';
                case USER_TYPE.STAFF:
                  return 'Staff';
                case USER_TYPE.SUPER_ADMIN:
                  return 'Super Admin';
                case USER_TYPE.ADVISER:
                  return 'Advisor';
                case USER_TYPE.PREMIUM_CONSULTANT:
                  return 'Premium Consultant';
                default:
                  break;
              }
            },
            key: 'creatorRoles',
            ...getUserTypeFilterProps(),
          },
          {
            title: 'Template Type',
            render: (item) => {
              switch (item.type) {
                case TEMPLATE_TYPE.FREE:
                  return 'Free';
                case TEMPLATE_TYPE.PAID:
                  return 'Paid';
                case TEMPLATE_TYPE.PREMIUM:
                  return 'Premium';
                default:
                  break;
              }
            },
            key: 'types',
            ...getTemplateTypeFilterProps(),
          },
          {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            align: 'center',
            width: '20%',
            render: (item) => renderBadge(item),
            ...getStatusFilterProps(),
          },
          {
            title: 'Price (INR)',
            dataIndex: 'price',
            sorter: true,
            key: 'price',
          },
        ]}
        // eslint-disable-next-line arrow-body-style
        onRow={(record) => {
          return {
            onClick: () => {
              navigate(`${ROUTES?.TEMPLATE}/edit/${record?.id}`);
            },
          };
        }}
        rowKey={(record) => record?.id}
        dataSource={userList}
        pagination={tableParams?.pagination}
        scroll={{ y: `calc(100vh - 342px)` }}
        loading={loading}
        onChange={handleTableChange}
      />
    </>
  );
}

export default TemplateList;
