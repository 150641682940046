import { ExclamationCircleFilled, PlusCircleOutlined } from '@ant-design/icons';
import { Button, Modal, Radio, Table, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { mongoClient } from '../../apollo';
import {
  COURT_LIST,
  LIMIT,
  MODULES,
  PAGE_SIZE_OPTIONS,
  ROUTES,
  TAX_CATEGORIES,
} from '../../common/constants';
import SearchComponent from '../../components/SearchComponent';
import useRouter from '../../hooks/useRouter';
import MergeDataModal from './components/MergeDataModal';
import { DELETE_ASSESSEE } from './graphql/mutations';
import { LIST_ASSESSEESS } from './graphql/queries';

const { Title } = Typography;
const { confirm: deleteConfirm } = Modal;

function AssessesList() {
  const { navigate } = useRouter();
  const [filter, setFilter] = useState({ limit: LIMIT, skip: 1, search: '' });
  const [sort, setSort] = useState([{ sortBy: 'DESC', sortOn: 'createdAt' }]);
  const [assesses, setAsseesses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isMergeData, setIsMergeData] = useState(false);

  const listAssessees = async ({ variables }) => {
    await mongoClient
      ?.query({
        query: LIST_ASSESSEESS,
        fetchPolicy: 'network-only',
        variables,
      })
      .then((res) => {
        setLoading(false);
        setAsseesses(res?.data);
      })
      ?.catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    const { skip, limit, search } = filter;
    listAssessees({
      variables: {
        filter: {
          skip: (skip - 1) * limit,
          limit,
          search,
          category: filter?.category,
          court: filter?.court,
        },
        sort,
      },
    });
  }, [filter, sort]);

  const deleteCall = async (id) => {
    setLoading(true);
    await mongoClient
      ?.mutate({
        mutation: DELETE_ASSESSEE,
        variables: {
          where: {
            id,
          },
        },
      })
      ?.then(() => {
        const { skip, limit, search } = filter;
        listAssessees({
          variables: {
            filter: {
              skip: (skip - 1) * limit,
              limit,
              search,
              category: filter?.category,
              court: filter?.court,
            },
            sort,
          },
        });
        setLoading(false);
      })
      ?.catch(() => {
        setLoading(false);
      });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setFilter({
      ...filter,
      category: filters?.category?.[0],
      court: filters?.court?.[0],
      skip: pagination?.current,
      limit: pagination?.pageSize,
    });
    setSort({
      sortOn: sorter?.order ? sorter?.field : 'createdAt',
      sortBy: sorter?.order === 'ascend' ? 'ASC' : 'DESC',
    });
  };

  const handleSearchChange = (value) => {
    setFilter({ ...filter, skip: 1, search: value });
  };

  const sortOrder = sort.sortBy === 'DESC' ? 'descend' : 'ascend';

  const handleApplyFilters = (confirm) => {
    confirm();
  };

  const filterPopup = () => ({
    filterMultiple: false,
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <div className="custom-filter-dropdown">
        {Object?.keys(TAX_CATEGORIES)?.map((category) => (
          <div key={category}>
            <Radio
              checked={selectedKeys.includes(category)}
              onChange={(e) => {
                const updatedKeys = e.target.checked ? [category] : [];
                setSelectedKeys(updatedKeys);
              }}
            >
              {TAX_CATEGORIES[category]}
            </Radio>
          </div>
        ))}
        <div className="control-panel">
          <Button
            type="primary"
            size="small"
            onClick={() => handleApplyFilters(confirm)}
            className="ok-button"
          >
            Apply
          </Button>
          <Button
            className="ml-8 reset-button"
            size="small"
            onClick={() => {
              setSelectedKeys([]);
              confirm();
            }}
          >
            Reset
          </Button>
        </div>
      </div>
    ),
    onFilter: (value, record) => record?.category?.includes(value),
  });

  const filterCourtPopup = () => ({
    filterMultiple: false,
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <div className="custom-filter-dropdown">
        {Object?.keys(COURT_LIST)?.map((court) => (
          <div key={court}>
            <Radio
              checked={selectedKeys.includes(court)}
              onChange={(e) => {
                const updatedKeys = e.target.checked ? [court] : [];
                setSelectedKeys(updatedKeys);
              }}
            >
              {COURT_LIST[court]}
            </Radio>
          </div>
        ))}
        <div className="control-panel">
          <Button
            type="primary"
            size="small"
            onClick={() => confirm()}
            className="ok-button"
          >
            Apply
          </Button>
          <Button
            className="ml-8 reset-button"
            size="small"
            onClick={() => {
              setSelectedKeys([]);
              confirm();
            }}
          >
            Reset
          </Button>
        </div>
      </div>
    ),
  });

  const handleDelete = (id) => {
    deleteConfirm({
      centered: true,
      title: 'Are you sure to delete?',
      icon: <ExclamationCircleFilled />,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        deleteCall(id);
      },
      onCancel() {},
    });
  };

  const columns = [
    {
      title: 'Name',
      sorter: true,
      sortOrder: sort.sortOn === 'name' ? sortOrder : null,
      dataIndex: 'name',
    },
    {
      title: 'Categories',
      dataIndex: 'category',
      filters: Object?.keys(TAX_CATEGORIES)?.map((category) => ({
        text: TAX_CATEGORIES?.[category],
        value: category,
      })),
      render(category) {
        return category?.map((item) => TAX_CATEGORIES?.[item])?.join(', ');
      },
      ...filterPopup(),
    },
    {
      title: 'Court',
      dataIndex: 'court',
      render(court) {
        return court?.length > 0 ? court?.map((item) => item)?.join(', ') : '-';
      },
      ellipsis: true,
      ...filterCourtPopup(),
    },
    {
      title: 'Type',
      dataIndex: 'type',
      render(type) {
        return type ?? '-';
      },
    },
    {
      title: 'Initial of Surname or Title',
      dataIndex: 'initialOfSurnameOrTitle',
      render(name) {
        return name || '-';
      },
    },
    {
      title: 'Action',
      dataIndex: '_id',
      fixed: 'right',
      render: (_, { _id: id } = {}) => (
        <div className="category-action">
          <Link to={`${ROUTES.EDIT_ASSESSES}/${id}`}>Edit</Link>
          <Button
            type="link"
            className="action-btn"
            onClick={() => handleDelete(id)}
            danger
          >
            Delete
          </Button>
        </div>
      ),
    },
  ];

  return (
    <>
      <Title className="site-page-header p-0 mb-8 mt-0" level={3}>
        {MODULES.ASSESSES}
        {assesses?.assesseesAdmin?.count
          ? ` (${assesses?.assesseesAdmin?.count})`
          : ''}
      </Title>
      <div className="filter-input  mb-12">
        <SearchComponent className="list-search" getData={handleSearchChange} />
        <div>
          <Button
            className="ml-8"
            type="primary"
            onClick={() => setIsMergeData(true)}
          >
            Merge Data
          </Button>
          <Button
            icon={<PlusCircleOutlined />}
            className="ml-8"
            type="primary"
            onClick={() => {
              navigate(ROUTES?.ADD_ASSESSES);
            }}
          >
            Add Assessee
          </Button>
        </div>
      </div>
      <Table
        columns={columns}
        rowKey={(record) => record?.id}
        dataSource={assesses?.assesseesAdmin?.data}
        pagination={{
          total: assesses?.assesseesAdmin?.count,
          current: filter?.skip,
          pageSize: filter?.limit,
          pageSizeOptions: PAGE_SIZE_OPTIONS,
        }}
        loading={loading}
        scroll={{ y: `calc(100vh - 342px)` }}
        onChange={handleTableChange}
      />
      {isMergeData && (
        <MergeDataModal
          open={isMergeData}
          onCancel={() => setIsMergeData(false)}
        />
      )}
    </>
  );
}

export default AssessesList;
