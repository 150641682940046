import { ArrowLeftOutlined, ClockCircleOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import {
  Avatar,
  Button,
  Card,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Space,
  Tag,
} from 'antd';
import dayjs from 'dayjs';
import { capitalize } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import profilePlaceholder from '../../assets/images/placeholder.jpg';
import {
  PAGE_TYPE,
  ROLE_KEYS,
  ROUTES,
  STANDARD_DATE_FORMAT,
} from '../../common/constants';
import LoaderComponent from '../../components/LoaderComponent';
import ShowOnWeb from '../../components/ShowOnWeb';
import useRouter from '../../hooks/useRouter';
import CallPurchaserList from './CallPurchaserList';
import { UPDATE_CALLS } from './graphql/mutations';
import { GET_CONSULTATION_CALL_DETAILS } from './graphql/queries';

export default function ConsultationCallDetails() {
  const { params, navigate } = useRouter();
  const [form] = Form.useForm();
  const { id } = params;
  const [showOnWeb, setShowOnWeb] = useState({});
  const [valueChange, setValueChange] = useState(false);
  const [interactedWithFeature, setInteractedWithFeature] = useState(null);
  const { data: callDetails, loading } = useQuery(
    GET_CONSULTATION_CALL_DETAILS,
    {
      variables: {
        where: { id },
      },
      fetchPolicy: 'network-only',
      onCompleted(res) {
        setShowOnWeb(res?.consultationCallAdmin?.showOnWeb);
      },
      onError() {},
    },
  );

  const [updateCall, { loading: updating }] = useMutation(UPDATE_CALLS, {
    onCompleted() {
      setValueChange(false);
      setInteractedWithFeature(null);
    },
    onError() {},
  });

  const callDetail = callDetails?.consultationCallAdmin;

  const currentUserRole = useMemo(
    () => callDetail?.creator?.roles.includes(ROLE_KEYS.PREMIUM_CONSULTANT),
    [callDetail?.creator?.roles],
  );

  const onFinish = (values) => {
    const { isFeatured, ...rest } = values;
    updateCall({
      variables: {
        data: {
          ...rest,
          bookingWindow: parseInt(values.bookingWindow, 10),
          ...(interactedWithFeature && { isFeatured }),
        },
        where: {
          id: callDetail.id,
        },
      },
    });
  };

  const onValuesChange = () => {
    setValueChange(true);
  };

  useEffect(() => {
    if (callDetail) {
      form.setFieldsValue({
        bookingWindow: callDetail?.bookingWindow,
        bookingWindowUnit: callDetail?.bookingWindowUnit,
        isFeatured: callDetail?.isFeatured,
        category: callDetail?.category,
      });
    }
  }, [callDetail]);

  if (loading) return <LoaderComponent />;

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={12} lg={16}>
          <Card
            className="ant-body-scroll"
            title={
              <Space className="d-flex justify-between">
                <div className="title">
                  <Button
                    type="text"
                    shape="circle"
                    onClick={() => navigate(ROUTES.CONSULTATION_CALLS)}
                    icon={<ArrowLeftOutlined />}
                  />
                  Consultation Call
                </div>
                {currentUserRole && (
                  <Space>
                    <ShowOnWeb
                      showOnWeb={showOnWeb}
                      pageType={PAGE_TYPE.CONSULTATION_CALL}
                      id={id}
                      placeholder="Select pages to show this call"
                      className="common-ant-form-item"
                      hide={
                        !(
                          callDetail?.visibility !== 'HIDDEN' &&
                          !callDetail?.meetingType?.includes('PHYSICAL')
                        )
                      }
                    />
                  </Space>
                )}
              </Space>
            }
          >
            <div className="ml-24">
              <Space className="d-flex justify-between">
                <h2>{callDetail?.title}</h2>
                <Tag color="processing">
                  {callDetail?.type === 'ONE_TO_ONE' ? '1-to-1' : 'Webinar'}
                </Tag>
              </Space>
              <div className="d-flex justify-between">
                {`Price: ₹ ${callDetail?.price ?? '-'}`}
                <Tag>
                  <ClockCircleOutlined /> {callDetail?.duration} min
                </Tag>
              </div>
              <div className="d-flex flex-vertical gap-16 align-start">
                <p>{`Description ${callDetail?.description}`}</p>
              </div>
              <div className="d-flex flex-vertical gap-16 align-start">
                <p>
                  {`Created on ${dayjs(callDetail?.createdAt).format(
                    STANDARD_DATE_FORMAT,
                  )}`}
                </p>
              </div>
            </div>
            <Divider />
            <div className="p-24">
              <Form
                layout="vertical"
                form={form}
                onFinish={onFinish}
                onValuesChange={onValuesChange}
              >
                <Row gutter={[16, 16]}>
                  <Col span={6}>
                    <Form.Item name="bookingWindow" label="Booking Window">
                      <Input placeholder="Enter number" className="w-full" />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      name="bookingWindowUnit"
                      label="Booking Window Unit"
                    >
                      <Select placeholder="Please select Booking Window Unit">
                        <Select.Option key="minutes">Minutes</Select.Option>
                        <Select.Option key="hours">Hours</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  {callDetail?.type === 'ONE_TO_ONE' && (
                    <Col span={6}>
                      <Form.Item name="category" label="Category">
                        <Select placeholder="Please select Category">
                          <Select.Option key="PERSONAL" value="PERSONAL">
                            Personal
                          </Select.Option>
                          <Select.Option
                            key="PROFESSIONAL"
                            value="PROFESSIONAL"
                          >
                            Professional
                          </Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  )}
                  {currentUserRole &&
                    ((callDetail?.visibility !== 'HIDDEN' &&
                      !callDetail?.meetingType?.includes('PHYSICAL')) ||
                      callDetail?.type === 'WEBINAR') && (
                      <Col span={6}>
                        <Form.Item
                          name="isFeatured"
                          label="Featured Call"
                          valuePropName="checked"
                        >
                          <Checkbox
                            onChange={() => {
                              setInteractedWithFeature(true);
                            }}
                          />
                        </Form.Item>
                      </Col>
                    )}
                  <Col span={24}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={updating}
                      disabled={!valueChange}
                    >
                      Save
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={12} lg={8}>
          <Card className="ant-body-scroll call-creator-card">
            <div className="m-24">
              <Space className="d-flex justify-between">
                <Avatar
                  size={64}
                  src={callDetail?.creator?.profileImage || profilePlaceholder}
                />
                <Card className="booked-count">
                  <div>
                    <p className="m-0">No. of calls booked</p>
                    {callDetail?.creator?.totalBookings}
                  </div>
                </Card>
              </Space>
              <h2 className="d-flex gap-8">
                {capitalize(callDetail?.creator?.firstName)}{' '}
                {callDetail?.creator?.lastName}
              </h2>
              <div>{callDetail?.creator?.city}</div>
            </div>
          </Card>
        </Col>
      </Row>
      <CallPurchaserList callId={id} key={id} />
    </>
  );
}
