import { useLazyQuery } from '@apollo/client';
import { Card, Col, DatePicker, Row, Select, Typography } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import {
  ActiveUsersIcon,
  InActiveUsersIcon,
  UnverifiedUserIcon,
  UsersIcon,
  VerifiedUsersIcon,
} from '../../assets/svg';
import {
  STANDARD_DATE_FORMAT,
  USER_ROLES_FILTERS,
} from '../../common/constants';
import { disableFutureDate } from '../../common/utils';
import LoaderComponent from '../../components/LoaderComponent';
import './dashboard.less';
import USER_ANALYTICS from './graphql/queries';

const { Title } = Typography;
const { RangePicker } = DatePicker;
const today = dayjs().endOf('day');
const monthAgo = today.subtract(30, 'day').startOf('day');

function Dashboard() {
  const [userCounts, setUserCounts] = useState(null);
  const [dateValue, setDateValue] = useState([monthAgo, today]);
  const [filter, setFilter] = useState({
    startDate: monthAgo.format(),
    endDate: today.format(),
    roles: null,
  });

  const [fetchAnalyticsCall, { loading }] = useLazyQuery(USER_ANALYTICS, {
    fetchPolicy: 'network-only',
  });
  const getUserAnalytics = (params = filter) => {
    fetchAnalyticsCall({
      variables: { filter: params },
      onCompleted: (res) => {
        setUserCounts(res?.userCounts);
      },
      onError: () => {},
    });
  };

  useEffect(() => {
    getUserAnalytics();
  }, []);

  const handleDatePicker = (date) => {
    setDateValue([date?.[0], date?.[1]]);
    const finaleStartData =
      date && dayjs(date?.[0])?.startOf('day').toISOString();
    const finalEndDate = date && dayjs(date?.[1])?.endOf('day').toISOString();
    const prepareFilter = {
      ...filter,
      startDate: finaleStartData,
      endDate: finalEndDate,
    };
    setFilter(prepareFilter);
    if (date?.[0] && date?.[1]) {
      getUserAnalytics(prepareFilter);
    }
  };

  const handleUserTypeChange = (value) => {
    const prepareValue = value && value?.length ? value : null;
    const prepareFilter = { ...filter, roles: prepareValue };
    setFilter(prepareFilter);
    getUserAnalytics(prepareFilter);
  };

  return (
    <>
      <Title className="site-page-header p-0 mb-8 mt-0" level={3}>
        Dashboard
      </Title>
      <div className="d-flex gap-12 filter-input mb-12 justify-end">
        <div className="d-flex gap-12">
          <RangePicker
            onChange={handleDatePicker}
            value={dateValue}
            format={STANDARD_DATE_FORMAT}
            disabledDate={disableFutureDate}
          />
          <Select
            placeholder="Select User Type"
            onChange={handleUserTypeChange}
            mode="multiple"
            value={filter?.roles}
            className="role-select-in-calls pr-8 pl-8"
            options={[...USER_ROLES_FILTERS]}
          />
        </div>
      </div>
      <LoaderComponent spinning={loading}>
        <div>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12} md={12} lg={8}>
              <Card className="full-height" hoverable>
                <div className="d-flex w-full h-full flex-horizontal align-center">
                  <div className="p-8">
                    <UsersIcon />
                  </div>
                  <div className="d-flex flex-vertical p-8 w-full h-full">
                    <div className="analytics-card-title">Total Users</div>
                    <div className="analytics-card-description">
                      {userCounts?.totalUsers ?? 0}
                    </div>
                  </div>
                </div>
              </Card>
            </Col>
            <Col xs={24} sm={12} md={12} lg={8}>
              <Card className="full-height" hoverable>
                <div className="d-flex w-full h-full flex-horizontal align-center">
                  <div className="p-8">
                    <VerifiedUsersIcon />
                  </div>
                  <div className="d-flex flex-vertical p-8 w-full h-full">
                    <div className="analytics-card-title">Verified Users</div>
                    <div className="analytics-card-description">
                      {userCounts?.phoneAndEmailVerifiedUsers ?? 0}
                    </div>
                  </div>
                </div>
              </Card>
            </Col>
            <Col xs={24} sm={12} md={12} lg={8}>
              <Card className="full-height" hoverable>
                <div className="d-flex w-full h-full flex-horizontal align-center">
                  <div className="p-8">
                    <InActiveUsersIcon />
                  </div>
                  <div className="d-flex flex-vertical p-8 w-full h-full">
                    <div className="analytics-card-title">Inactive Users</div>
                    <div className="analytics-card-description">
                      {userCounts?.inactiveUsers ?? 0}
                    </div>
                  </div>
                </div>
              </Card>
            </Col>
            <Col xs={24} sm={12} md={12} lg={8}>
              <Card className="full-height" hoverable>
                <div className="d-flex w-full h-full flex-horizontal align-center">
                  <div className="p-8">
                    <ActiveUsersIcon />
                  </div>
                  <div className="d-flex flex-vertical p-8 w-full h-full">
                    <div className="analytics-card-title">Active Users</div>
                    <div className="analytics-card-description">
                      {userCounts?.activeUsers ?? 0}
                    </div>
                  </div>
                </div>
              </Card>
            </Col>
            <Col xs={24} sm={12} md={12} lg={8}>
              <Card className="full-height" hoverable>
                <div className="d-flex w-full h-full flex-horizontal align-center">
                  <div className="p-8">
                    <UnverifiedUserIcon />
                  </div>
                  <div className="d-flex flex-vertical p-8 w-full h-full">
                    <div className="analytics-card-title">Unverified Users</div>
                    <div className="analytics-card-description">
                      {(userCounts?.totalUsers ?? 0) -
                        (userCounts?.phoneAndEmailVerifiedUsers ?? 0)}
                    </div>
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      </LoaderComponent>
    </>
  );
}

export default Dashboard;
