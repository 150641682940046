import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Card, Form, Tag } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { mongoClient } from '../../apollo';
import { ROUTES } from '../../common/constants';
import { getBase64 } from '../../common/utils';
import useRouter from '../../hooks/useRouter';
import SubmissionForm from './components/SubmissionForm';
import { CREATE_SUBMISSION, UPDATE_SUBMISSION } from './graphql/mutations';
import { GET_SUBMISSION, SUBMISSION_PDF_SIGNED_URL } from './graphql/queries';

const CreateEditSubmission = () => {
  const { params, navigate } = useRouter();
  const { id } = params;

  const [form] = Form.useForm();

  const [loading, setLoading] = useState();
  const [file, setFile] = useState({
    docFileObject: null,
    pdfUrl: null,
    isPdfUrlChange: false,
  });
  const [isPublished, setIsPublished] = useState(false);

  const getSubmission = async (submissionId) => {
    await mongoClient
      ?.query({
        query: GET_SUBMISSION,
        fetchPolicy: 'network-only',
        variables: { where: { id: submissionId } },
      })
      .then((res) => {
        const prepareData = {
          title: res?.data?.submissionAdmin?.title,
          description: res?.data?.submissionAdmin?.description ?? '',
          pdfUrl: res?.data?.submissionAdmin?.pdfUrl ?? '',
          region: res?.data?.submissionAdmin?.region,
          category: res?.data?.submissionAdmin?.category,
          issuePeriod: [
            dayjs(res?.data?.submissionAdmin?.startDate),
            dayjs(res?.data?.submissionAdmin?.endDate),
          ],
        };
        form.setFieldsValue(prepareData);
        setFile({
          ...file,
          pdfUrl: res?.data?.submissionAdmin?.pdfUrl ?? '',
        });
        setIsPublished(res?.data?.submissionAdmin?.status === 'PUBLISHED');
      });
  };

  const createUpdateSubmission = async ({ variables }) => {
    setLoading(true);
    await mongoClient
      ?.mutate({
        mutation: id ? UPDATE_SUBMISSION : CREATE_SUBMISSION,
        fetchPolicy: 'network-only',
        variables,
      })
      .then((res) => {
        if (res) {
          navigate(ROUTES.SUBMISSIONS);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const GetSignedUrl = async ({ variables }) => {
    try {
      const data = await mongoClient?.query({
        query: SUBMISSION_PDF_SIGNED_URL,
        fetchPolicy: 'network-only',
        variables,
      });
      return data?.data;
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    if (id) {
      getSubmission(id);
    }
  }, [id]);

  const onFinish = async (values) => {
    setLoading(true);
    const { issuePeriod, pdfUrl, ...rest } = values;
    const variables = {
      data: {
        ...rest,
        startDate: dayjs(values?.issuePeriod?.[0])?.toISOString(),
        endDate: dayjs(values?.issuePeriod?.[1])?.toISOString(),
      },
    };
    if (file.isPdfUrlChange) {
      const fileObj = file.docFileObject;
      const uploadDocPromise = new Promise((resolve, reject) => {
        GetSignedUrl({
          variables: { data: { fileName: fileObj?.name } },
        })
          .then(async ({ getSubmissionPdfFileUploadSignedUrl }) => {
            if (getSubmissionPdfFileUploadSignedUrl) {
              try {
                const response = await fetch(
                  `${getSubmissionPdfFileUploadSignedUrl?.signedUrl}`,
                  {
                    method: 'PUT',
                    body: fileObj,
                  },
                );
                if (response?.status === 200) {
                  variables.data.pdfUrl =
                    getSubmissionPdfFileUploadSignedUrl?.key;
                  resolve();
                }
              } catch (error) {
                reject(error);
              }
            }
          })
          .catch((error) => reject(error));
      });
      await uploadDocPromise;
    }
    if (id) {
      variables.where = { id };
    } else {
      variables.data.status = 'DRAFT';
    }
    createUpdateSubmission({ variables });
  };

  const handleRemoveAttachDoc = () => {
    setFile({
      ...file,
      pdfUrl: null,
      isPdfUrlChange: false,
      docFileObject: null,
    });
  };

  const handleFileChange = async (e) => {
    const fileObj = e?.file?.originFileObj;
    const previewImg = await getBase64(fileObj);
    setFile({
      ...file,
      pdfUrl: previewImg,
      isPdfUrlChange: true,
      docFileObject: fileObj,
    });
    form.setFieldsValue({
      pdfUrl: previewImg,
    });
  };

  const handlePublish = async () => {
    const variables = {
      data: {
        status: 'PUBLISHED',
      },
      where: {
        id,
      },
    };
    await createUpdateSubmission({ variables });
  };

  return (
    <>
      <div className="d-flex align-center justify-between">
        <div className="title">
          <Button
            type="text"
            shape="circle"
            onClick={() => navigate(ROUTES.SUBMISSIONS)}
            icon={<ArrowLeftOutlined />}
          />
          {id ? 'Edit' : 'Add'} Submission
        </div>
        {id &&
          (isPublished ? (
            <Tag color="success" className="mr-0 pb-6 pl-12 pr-12 pt-6">
              <span className="text-16">Published</span>
            </Tag>
          ) : (
            <Button onClick={handlePublish} loading={loading}>
              Publish
            </Button>
          ))}
      </div>
      <Card className="ant-body-scroll">
        <div className="card-body-wrapper">
          <SubmissionForm
            form={form}
            onFinish={onFinish}
            id={id}
            loading={loading}
            file={file}
            handleFileChange={handleFileChange}
            handleRemoveAttachDoc={handleRemoveAttachDoc}
          />
        </div>
      </Card>
    </>
  );
};
export default CreateEditSubmission;
