import { ExclamationCircleFilled, PlusCircleOutlined } from '@ant-design/icons';
import { Badge, Button, Modal, Radio, Table, Typography } from 'antd';
import { capitalize } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { mongoClient } from '../../apollo';
import {
  CATEGORY_OPTIONS,
  JUDGEMENT_AI_STATUS_SELECTION,
  LIMIT,
  MODULES,
  PAGE_SIZE_OPTIONS,
  ROUTES,
  TAX_CATEGORIES,
  TEMPLATE_STATUS_COLOR,
} from '../../common/constants';
import SearchComponent from '../../components/SearchComponent';
import useRouter from '../../hooks/useRouter';
import { DELETE_ACT } from './graphql/mutations';
import { GET_ACTS } from './graphql/queries';

const { Title } = Typography;
const { confirm: deleteConfirm } = Modal;

const ActsList = () => {
  const { navigate } = useRouter();

  const [filter, setFilter] = useState({
    limit: LIMIT,
    skip: 1,
    search: '',
  });
  const [sort, setSort] = useState({ sortBy: 'DESC', sortOn: 'createdAt' });
  const [actsData, setActsData] = useState([]);
  const [loading, setLoading] = useState(true);

  const listActs = async ({ variables }) => {
    setLoading(true);
    await mongoClient
      ?.query({
        query: GET_ACTS,
        fetchPolicy: 'network-only',
        variables,
      })
      .then((res) => {
        setActsData(res?.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteAct = async (id) => {
    setLoading(true);
    const { skip, limit, search } = filter;
    await mongoClient
      ?.mutate({
        mutation: DELETE_ACT,
        variables: {
          where: {
            id,
          },
        },
      })
      .then(() => {
        const prepareFilterValue = {
          skip: (skip - 1) * limit,
          limit,
          search,
        };
        listActs({
          variables: {
            filter: prepareFilterValue,
            sort,
          },
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    const { skip, limit } = filter;
    const prepareFilterValue = {
      ...filter,
      skip: (skip - 1) * limit,
    };
    listActs({
      variables: {
        filter: prepareFilterValue,
        sort,
      },
    });
  }, [filter, sort]);

  const handleDelete = (id) => {
    deleteConfirm({
      centered: true,
      title: 'Are you sure to delete?',
      icon: <ExclamationCircleFilled />,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        deleteAct(id);
      },
      onCancel() {},
    });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setFilter({
      ...filter,
      category: filters?.category?.[0],
      status: filters?.status?.[0],
      skip: pagination?.current,
      limit: pagination?.pageSize,
    });
    setSort({
      sortOn: sorter?.order ? sorter?.field : 'createdAt',
      sortBy: sorter?.order === 'ascend' ? 'ASC' : 'DESC',
    });
  };

  const handleSearchChange = (value) => {
    setFilter({ ...filter, skip: 1, search: value });
  };

  const getFilterProps = (options) => ({
    filterMultiple: false,
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <div className="custom-filter-dropdown">
        {options?.map((option) => (
          <div key={option?.label} className="mt-6 mb-6">
            <Radio
              value={option?.value}
              checked={selectedKeys.includes(option?.value)}
              onChange={(e) => {
                const updatedKeys = e?.target?.checked ? [option?.value] : [];
                setSelectedKeys(updatedKeys);
              }}
            >
              {option?.label}
            </Radio>
          </div>
        ))}
        <div className="control-panel">
          <Button
            type="primary"
            size="small"
            onClick={() => {
              confirm();
            }}
            className="ok-button"
          >
            Apply
          </Button>
          <Button
            className="ml-8 reset-button"
            size="small"
            onClick={() => {
              setSelectedKeys([]);
              confirm();
            }}
          >
            Reset
          </Button>
        </div>
      </div>
    ),
  });

  const columns = [
    {
      title: 'Title',
      sorter: true,
      dataIndex: 'title',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      render(description) {
        return description ?? '-';
      },
    },
    {
      title: 'Region',
      dataIndex: 'region',
    },
    {
      title: 'Issue Period',
      dataIndex: 'issuePeriod',
      render(issuePeriod) {
        return issuePeriod?.join(', ') ?? '-';
      },
    },
    {
      title: 'Category',
      dataIndex: 'category',
      render(category) {
        return TAX_CATEGORIES[category] ?? '-';
      },
      ...getFilterProps(CATEGORY_OPTIONS),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render(status) {
        return (
          <Badge
            count={capitalize(status)}
            color={TEMPLATE_STATUS_COLOR?.[status]}
          />
        );
      },
      ...getFilterProps(JUDGEMENT_AI_STATUS_SELECTION),
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'action',
      render: (_, { id } = {}) => (
        <div className="category-action">
          <Link to={`${ROUTES.EDIT_ACTS}/${id}`}>Edit</Link>
          <Button
            type="link"
            className="action-btn"
            onClick={() => handleDelete(id)}
            danger
          >
            Delete
          </Button>
        </div>
      ),
    },
  ];

  return (
    <>
      <Title className="site-page-header p-0 mb-8 mt-0" level={3}>
        {MODULES.ACTS}
        {actsData?.actsAdmin?.count ? ` (${actsData?.actsAdmin?.count})` : ''}
      </Title>
      <div className="filter-input mb-12">
        <SearchComponent className="list-search" getData={handleSearchChange} />
        <Button
          icon={<PlusCircleOutlined />}
          className="ml-8"
          key="1"
          type="primary"
          onClick={() => {
            navigate(ROUTES?.CREATE_ACTS);
          }}
        >
          Add Act
        </Button>
      </div>
      <Table
        columns={columns}
        rowKey={(record) => record?.id}
        dataSource={actsData?.actsAdmin?.data}
        pagination={{
          total: actsData?.actsAdmin?.count,
          current: filter?.skip,
          pageSize: filter?.limit,
          pageSizeOptions: PAGE_SIZE_OPTIONS,
        }}
        loading={loading}
        scroll={{ y: `calc(100vh - 342px)` }}
        onChange={handleTableChange}
      />
    </>
  );
};
export default ActsList;
