import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import CreateTransaction from './CreateTransaction';
import EditTransaction from './EditTransaction';
import TransactionLogsList from './TransactionLogsList';
import './transaction-logs.less';

const TransactionLogsWrapper = () => (
  <Routes>
    <Route path={ROUTES.MAIN} exact element={<TransactionLogsList />} />
    <Route path={ROUTES.CREATE} exact element={<CreateTransaction />} />
    <Route
      path={`${ROUTES.EDIT}/:userId`}
      exact
      element={<EditTransaction />}
    />
  </Routes>
);
export default TransactionLogsWrapper;
