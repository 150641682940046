import { gql } from '@apollo/client';

const GET_SECTIONS = gql`
  query SectionsAdmin($filter: ListSectionFilter, $sort: [ListSectionSort!]) {
    sectionsAdmin(filter: $filter, sort: $sort) {
      data {
        _id
        label
        slug
        id
        considerCheckArray
        label
      }
      count
    }
  }
`;

export default GET_SECTIONS;
