import { Modal } from 'antd';
import React, { useEffect, useMemo } from 'react';

export default function CallBookingCalendar({
  callType,
  userSlug,
  eventSlug,
  workspaceId,
  handleSuccessBooking,
  showModal,
  setShowModal,
  callData,
}) {
  useEffect(() => {
    const handleMessageFromIframe = (event) => {
      if (event.data) {
        // Forward messages from the iframe to the parent component
        handleSuccessBooking(JSON.parse(event?.data), userSlug);
      }
    };
    // eslint-disable-next-line no-undef
    window.addEventListener('message', handleMessageFromIframe);
    return () => {
      // eslint-disable-next-line no-undef
      window.removeEventListener('message', handleMessageFromIframe);
    };
  }, []);

  const bookingWindowInMinutes = useMemo(() => {
    const bookingWindow = callData?.bookingWindow ?? 0;
    return callData?.bookingWindowUnit === 'hours'
      ? bookingWindow * 60
      : bookingWindow;
  }, [callData]);

  return (
    <Modal
      title="Create Booking"
      centered
      closable
      open={showModal}
      onCancel={() => setShowModal(false)}
      footer={[]}
      wrapClassName="call-booking-modal-wrapper"
      className="modal-container"
    >
      <iframe
        src={`${process.env.REACT_APP_CALENDAR_URL}/booking?type=${callType}&user=${userSlug}&event=${eventSlug}&workspace=${workspaceId}&bookingWindow=${bookingWindowInMinutes}`}
        title="embeded-calendar"
        height="700"
        width="520"
        style={{ border: 'none' }}
      />
    </Modal>
  );
}
