import {
  MenuFoldOutlined,
  MenuOutlined,
  MenuUnfoldOutlined,
} from '@ant-design/icons';
import { Button, Layout } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { Logo } from '../assets/svg';
import { BREAKPOINTS, ROUTES } from '../common/constants';
import AuthFooter from '../components/AuthFooter';
import MobileLogoComponent from '../components/MobileLogoComponent';
import './App.css';
import AppHeader from './components/header/AppHeader';
import Notification from './components/header/Notification';
import UserProfile from './components/header/UserProfile';
import Sidebar from './components/sidebar/Sidebar';

const { Content, Footer, Sider } = Layout;
const App = () => {
  const location = useLocation();
  const [isDesktop, setDesktop] = useState(
    // eslint-disable-next-line no-undef
    window.innerWidth > BREAKPOINTS.tablet,
  );
  const [isActive, setActive] = useState(false);
  const [collapsed, setCollapsed] = useState(false);

  const handleOverlay = () => {
    setActive(!isActive);
  };
  useEffect(() => {
    const updateMedia = () => {
      // eslint-disable-next-line no-undef
      setDesktop(window.innerWidth > BREAKPOINTS.tablet);
    };
    // eslint-disable-next-line no-undef
    window.addEventListener('resize', updateMedia);
    // eslint-disable-next-line no-undef
    return () => window.removeEventListener('resize', updateMedia);
  });

  useEffect(() => {
    // eslint-disable-next-line no-undef
    if ('serviceWorker' in navigator) {
      // eslint-disable-next-line no-undef
      navigator.serviceWorker.ready.then((registration) => {
        registration.update();
      });
    }
  }, [location]);

  return (
    <Layout hasSider className="app-layout-wrapper">
      {!isDesktop && (
        <span
          className={isActive ? 'active overlay-responsive' : 'overlay-disable'}
          onClick={handleOverlay}
        />
      )}
      <Sider
        theme="light"
        collapsed={isDesktop ? collapsed : false}
        className={isActive ? 'close' : null}
        breakpoint="md"
        width={240}
      >
        <Link id="logo" to={ROUTES?.MAIN}>
          {collapsed ? <MobileLogoComponent /> : <Logo />}
        </Link>
        <Sidebar />
      </Sider>
      <Layout className="site-layout">
        <AppHeader>
          <div className="header-wrapper">
            {isDesktop ? (
              <>
                {collapsed ? (
                  <MenuUnfoldOutlined
                    className="trigger"
                    onClick={() => setCollapsed(!collapsed)}
                  />
                ) : (
                  <MenuFoldOutlined
                    className="trigger"
                    onClick={() => setCollapsed(!collapsed)}
                  />
                )}
              </>
            ) : (
              <>
                <Button
                  className="trigger d-flex align-center justify-center"
                  type="text"
                  onClick={handleOverlay}
                  icon={<MenuOutlined />}
                  size="middle"
                />
              </>
            )}
            <div className="d-flex gap-16 align-center">
              <Notification />
              <UserProfile />
            </div>
          </div>
        </AppHeader>
        <Content className="wrapper">
          <Outlet />
        </Content>
        <Footer>
          <AuthFooter />
        </Footer>
      </Layout>
    </Layout>
  );
};

export default App;
