import { Viewer, Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import React from 'react';

const PDFViewer = ({ fileUrl }) => {
  const renderToolbar = (Toolbar) => (
    <Toolbar>
      {(slots) => {
        const {
          CurrentPageInput,
          GoToNextPage,
          GoToPreviousPage,
          NumberOfPages,
          Zoom,
          ZoomIn,
          ZoomOut,
        } = slots;
        return (
          <div className="pdf-viewer-wrapper">
            <div className="pdf-viwer-tool">
              <ZoomOut />
            </div>
            <div className="pdf-viwer-tool">
              <Zoom />
            </div>
            <div className="pdf-viwer-tool">
              <ZoomIn />
            </div>
            <div className="pdf-viwer-tool-goto">
              <GoToPreviousPage />
            </div>
            <div className="pdf-viwer-tool-current">
              <CurrentPageInput />
            </div>
            <div className="pdf-viewr-tool-number">
              &nbsp;/ <NumberOfPages />
            </div>
            <div className="pdf-viwer-tool">
              <GoToNextPage />
            </div>
          </div>
        );
      }}
    </Toolbar>
  );

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    renderToolbar,
  });

  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.js">
      <div className="pdf-viewer">
        <style>
          {`
            .rpv-default-layout__sidebar {
              display: none;
            }
            .rpv-default-layout__sidebar__tab--thumbnails,
            .rpv-default-layout__sidebar__tab--outline,
            .rpv-default-layout__sidebar__tab--attachments,
            .rpv-default-layout__sidebar__tab--comments {
              display: none;
            }
          `}
        </style>
        <Viewer
          fileUrl={fileUrl}
          plugins={[defaultLayoutPluginInstance]}
          renderError={() => (
            <div className="pdf-not-available">
              <p>PDF not available</p>
            </div>
          )}
        />
      </div>
    </Worker>
  );
};

export default PDFViewer;
