import { gql } from '@apollo/client';

export const CREATE_NOTIFICATION = gql`
  mutation CreateNotificationAdmin($data: CreateNotificationInput!) {
    createNotificationAdmin(data: $data) {
      message
    }
  }
`;

export const UPDATE_NOTIFICATION = gql`
  mutation UpdateNotificationAdmin(
    $data: UpdateNotificationInput!
    $where: NotificationIdWhereUniqueInput!
  ) {
    updateNotificationAdmin(data: $data, where: $where) {
      message
    }
  }
`;

export const DELETE_NOTIFICATION = gql`
  mutation DeleteNotificationAdmin($where: NotificationIdWhereUniqueInput!) {
    deleteNotificationAdmin(where: $where) {
      message
    }
  }
`;
