import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import Reports from './Reports';

const ReportsWrapper = () => (
  <Routes>
    <Route path={ROUTES.MAIN} exact element={<Reports />} />
  </Routes>
);
export default ReportsWrapper;
