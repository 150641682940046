import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import QuotationList from './QuotationList';

const QuotationWrapper = () => (
  <Routes>
    <Route path={ROUTES.MAIN} exact element={<QuotationList />} />
  </Routes>
);
export default QuotationWrapper;
