/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const UPDATE_CALLS = gql`
  mutation UpdateConsultationCallAdmin(
    $data: UpdateConsultationCallInput!
    $where: ConsultationCallWhereUniqueInput!
  ) {
    updateConsultationCallAdmin(data: $data, where: $where) {
      message
    }
  }
`;
