import { useLazyQuery, useMutation } from '@apollo/client';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import LoaderComponent from '../../components/LoaderComponent';
import useRouter from '../../hooks/useRouter';
import { VERIFY_EMAIL } from './graphql/mutations';
import { VALIDATE_TOKEN } from './graphql/queries';

const VerifyEmail = () => {
  const { navigate } = useRouter();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const token = searchParams.get('token');
  const [verifyEmailCall, { loading }] = useMutation(VERIFY_EMAIL, {
    onError() {},
  });

  const [validateToken] = useLazyQuery(VALIDATE_TOKEN, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (res?.validateToken && !res?.validateToken?.isValid) {
        navigate(ROUTES?.LOGIN);
      }
    },
  });

  useEffect(() => {
    validateToken({ variables: { data: { token } } });
  }, []);

  useEffect(() => {
    if (token) {
      verifyEmailCall({
        variables: { data: { resetToken: token } },
        onCompleted: () => {
          navigate(ROUTES?.LOGIN);
        },
        onError() {
          navigate(ROUTES?.LOGIN);
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) return <LoaderComponent />;

  return null;
};

export default VerifyEmail;
