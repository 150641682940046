import { useMutation } from '@apollo/client';
import { Avatar, Button, Checkbox, Input, Modal, Radio, Space } from 'antd';
import { capitalize } from 'lodash';
import React, { useMemo, useState } from 'react';
import profilePlaceholder from '../../../assets/images/placeholder.jpg';
import { STATUS_SELECTION, TEMPLATE_STATUS } from '../../../common/constants';
import { UPDATE_EXPERT_SUMMARY } from '../graphql/mutations';

const { TextArea } = Input;

export default function EditExpertSummary({
  modalData,
  refetch,
  handleModalClose,
}) {
  const [data, setData] = useState({
    status: modalData?.status,
    content: modalData?.content,
    forNewsletter: modalData?.forNewsletter,
  });

  const [
    updateExpertSummary,
    { loading: updateExpertSummaryLoading },
  ] = useMutation(UPDATE_EXPERT_SUMMARY, {
    onCompleted: () => {
      handleModalClose();
      refetch();
    },
    onError() {},
  });

  const updateSummary = () => {
    updateExpertSummary({
      variables: {
        data: { ...data },
        where: { id: modalData?.id },
      },
    });
  };

  const handleInput = (e) => {
    const { name, value, checked } = e?.target;
    const updatedData =
      name === 'status' && value === 'UNPUBLISHED'
        ? { ...data, status: value, forNewsletter: false }
        : { ...data, [name]: name === 'forNewsletter' ? checked : value };
    setData(updatedData);
  };

  const getUpdateSelection = (status) => {
    switch (status) {
      case TEMPLATE_STATUS?.DRAFT:
        return STATUS_SELECTION.filter(
          (item) =>
            item?.value !== TEMPLATE_STATUS.REJECTED &&
            item?.value !== TEMPLATE_STATUS.UNPUBLISHED,
        );
      case TEMPLATE_STATUS?.PUBLISHED:
        return STATUS_SELECTION.filter(
          (item) =>
            item?.value === TEMPLATE_STATUS.PUBLISHED ||
            item?.value === TEMPLATE_STATUS.UNPUBLISHED,
        );
      case TEMPLATE_STATUS?.UNPUBLISHED:
        return STATUS_SELECTION.filter(
          (item) =>
            item?.value === TEMPLATE_STATUS.PUBLISHED ||
            item?.value === TEMPLATE_STATUS.UNPUBLISHED,
        );
      default:
        return null;
    }
  };

  const statusList = useMemo(
    () =>
      getUpdateSelection(modalData.status)?.map((status) => ({
        value: `${status?.value}`,
        label: `${status?.label}`,
      })),
    [modalData],
  );

  return (
    <Modal
      // eslint-disable-next-line no-undef
      getContainer={() => document.getElementById('root')}
      title="Edit Expert Summary"
      centered
      closable
      open={modalData}
      onCancel={handleModalClose}
      footer={[
        <Button
          key="submit"
          type="primary"
          onClick={updateSummary}
          loading={updateExpertSummaryLoading}
        >
          Save
        </Button>,
      ]}
    >
      <Space>
        <Avatar src={modalData?.user?.profileImage || profilePlaceholder} />
        <h4>
          {capitalize(modalData?.user?.firstName)}
          {capitalize(modalData?.user?.lastName)}
        </h4>
      </Space>
      <div className="d-flex flex-vertical gap-16 align-start">
        <Radio.Group
          name="status"
          defaultValue={modalData?.status}
          onChange={handleInput}
        >
          {statusList?.map((status) => (
            <Radio key={status?.value} value={status?.value}>
              {status?.label}
            </Radio>
          ))}
        </Radio.Group>
        <TextArea
          name="content"
          defaultValue={modalData?.content}
          autoSize={{
            minRows: 4,
            maxRows: 6,
          }}
          onChange={handleInput}
        />
        <Checkbox
          name="forNewsletter"
          checked={data?.forNewsletter}
          onChange={handleInput}
          disabled={data?.status === TEMPLATE_STATUS.UNPUBLISHED}
        >
          Add to Newsletter
        </Checkbox>
      </div>
    </Modal>
  );
}
