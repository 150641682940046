import { gql } from '@apollo/client';

export const CREATE_SUBMISSION = gql`
  mutation CreateSubmissionAdmin($data: CreateSubmissionInput!) {
    createSubmissionAdmin(data: $data) {
      message
    }
  }
`;

export const UPDATE_SUBMISSION = gql`
  mutation UpdateSubmissionAdmin(
    $data: UpdateSubmissionInput!
    $where: SubmissionIdWhereUniqueInput!
  ) {
    updateSubmissionAdmin(data: $data, where: $where) {
      message
    }
  }
`;

export const DELETE_SUBMISSION = gql`
  mutation DeleteSubmissionAdmin($where: SubmissionIdWhereUniqueInput!) {
    deleteSubmissionAdmin(where: $where) {
      message
    }
  }
`;
