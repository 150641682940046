import {
  ArrowLeftOutlined,
  CloseCircleOutlined,
  DownOutlined,
  DownloadOutlined,
  FileTextOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Alert,
  Button,
  Card,
  Col,
  Dropdown,
  Form,
  Image,
  Input,
  Modal,
  Radio,
  Row,
  Space,
  Upload,
} from 'antd';
import { capitalize } from 'lodash';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Link } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import {
  EDITOR_FORMATS,
  EDITOR_MODULES,
  LIMIT,
  MAX_PRICE_LIMIT,
  PAGE_TYPE,
  ROUTES,
  STATUS_SELECTION,
  TEMPLATE_STATUS,
  TEMPLATE_TYPE,
  USER_TYPE,
} from '../../common/constants';
import {
  beforeUpload,
  beforeUploadDocFile,
  formValidatorRules,
  getBase64,
} from '../../common/utils';
import DebounceSelectComponent from '../../components/DebounceSelectComponent';
import ShowOnWeb from '../../components/ShowOnWeb';
import useRouter from '../../hooks/useRouter';
import { GET_CATEGORIES } from '../category/graphql/queries';
import {
  CREATE_TEMPLATE,
  UPDATE_TEMPLATE,
  UPDATE_TEMPLATE_STATUS,
} from './graphql/mutations';
import {
  FETCH_TEMPLATE,
  GET_TEMPLATE_DOC_DOWNLOAD_URL,
  GET_TEMPLATE_DOC_SIGNED_URL,
  GET_TEMPLATE_IMAGE_SIGNED_URL,
} from './graphql/queries';

const defaultFormValue = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  state: '',
  city: '',
  companyName: '',
  subscribeToNewsLettersAt: false,
  roles: '',
  profession: '',
};
function EditTemplate() {
  const { params, navigate } = useRouter();
  const { id } = params;
  const [form] = Form.useForm();
  const { getCurrentUserRole } = useContext(AppContext);
  const role = getCurrentUserRole() || {};
  const [reasonForm] = Form.useForm();

  const [isSubmitLoader, setIsSubmitLoader] = useState(false);
  const [categoryId, setCategoryId] = useState(null);
  const [reasonPopup, setReasonPopup] = useState(false);

  const [isFieldsChanged, setIsFieldsChanged] = useState(false);

  const [file, setFile] = useState({
    imageFileObject: null,
    previewUrl: null,
    isPreviewUrlChange: false,
    docFileObject: null,
    downloadUrl: null,
    isDownloadUrlChange: false,
  });
  const [options, setOptions] = useState({
    category: [],
    subCategory: [],
    isCategoryEnds: false,
    isSubCategoryEnds: false,
  });

  const unEditableTypeAry = [
    TEMPLATE_STATUS.UNPUBLISHED,
    TEMPLATE_STATUS.REJECTED,
  ];
  const [templateStatus, setTemplateStatus] = useState(null);
  const [showOnWeb, setShowOnWeb] = useState({});
  const [categorySlug, setCategorySlug] = useState('');
  const [subCategorySlug, setSubCategorySlug] = useState('');
  const [templateSlug, setTemplateSlug] = useState('');

  const [fetchTemplateCall] = useLazyQuery(FETCH_TEMPLATE);
  const [getTemplateImageSignedUrlCall] = useLazyQuery(
    GET_TEMPLATE_IMAGE_SIGNED_URL,
  );
  const [getTemplateDocSignedUrlCall] = useLazyQuery(
    GET_TEMPLATE_DOC_SIGNED_URL,
  );
  const [fetchCategories] = useLazyQuery(GET_CATEGORIES);

  const [createTemplateCall] = useMutation(CREATE_TEMPLATE);
  const [updateTemplateCall] = useMutation(UPDATE_TEMPLATE);
  const [updateTemplateStatusCall] = useMutation(UPDATE_TEMPLATE_STATUS);
  const [getTemplateDocDownloadUrlCall] = useLazyQuery(
    GET_TEMPLATE_DOC_DOWNLOAD_URL,
  );

  const fetchOptions = async (
    type,
    e,
    catId = categoryId,
    isScroll = false,
  ) => {
    const skip =
      type === 'SUB_CAT' ? options.subCategory.length : options.category.length;
    let fetchedOptions = [];
    let prepareQuery = {
      filter: {
        limit: LIMIT,
        search: e,
        skip: isScroll ? skip : 0,
      },
      sort: [
        {
          sortBy: 'DESC',
          sortOn: 'createdAt',
        },
      ],
    };
    if (type === 'SUB_CAT') {
      prepareQuery = {
        ...prepareQuery,
        where: {
          id: catId,
        },
      };
    }
    await fetchCategories({
      variables: prepareQuery,
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        const listData = res?.templateCategoriesAdmin?.data;
        let prepareOptions = listData.map((item) => ({
          value: item?.id,
          label: item?.name,
        }));
        prepareOptions = isScroll
          ? [...options.category, ...prepareOptions]
          : prepareOptions;
        if (type === 'SUB_CAT') {
          setOptions({
            ...options,
            subCategory: prepareOptions,
            isSubCategoryEnds: listData.length < LIMIT,
          });
        } else {
          setOptions({
            ...options,
            category: prepareOptions,
            isCategoryEnds: listData.length < LIMIT,
          });
        }
        fetchedOptions = prepareOptions;
      },
      onError: () => {},
    });
    return fetchedOptions;
  };

  const updateTempStatus = (data) => {
    setIsSubmitLoader(true);
    updateTemplateStatusCall({
      fetchPolicy: 'network-only',
      variables: data,
      onCompleted() {
        setTemplateStatus(data?.data?.status);
        setIsSubmitLoader(false);
        setReasonPopup(false);
      },
      onError() {},
    });
  };

  const handleTemplateAuthorization = (authType) => {
    const prepareValue = {
      data: {
        reason: null,
        status: authType?.key,
      },
      where: { id },
    };
    if (authType?.key === TEMPLATE_STATUS.PUBLISHED) {
      updateTempStatus(prepareValue);
    } else if (authType?.key === TEMPLATE_STATUS.UNPUBLISHED) {
      updateTempStatus(prepareValue);
    } else {
      setReasonPopup(true);
    }
  };
  const submitReasonForm = (values) => {
    const prepareValue = {
      data: {
        reason: values.reason,
        status: TEMPLATE_STATUS.REJECTED,
      },
      where: { id },
    };
    updateTempStatus(prepareValue);
  };

  useEffect(() => {
    fetchOptions();
    if (id) {
      fetchTemplateCall({
        fetchPolicy: 'no-cache',
        variables: {
          where: {
            id,
          },
        },
        onCompleted({ templateAdmin }) {
          form?.setFieldsValue({
            previewUrl: templateAdmin?.preview?.url,
            downloadUrl: templateAdmin?.download?.url,
            title: templateAdmin?.title,
            description: templateAdmin?.description,
            categoryId: {
              key: templateAdmin?.category?.id,
              label: templateAdmin?.category?.name,
            },
            subCategoryId: {
              key: templateAdmin?.subCategory?.id,
              label: templateAdmin?.subCategory?.name,
            },
            price: templateAdmin?.price,
            type: templateAdmin?.type,
          });
          setFile({
            ...file,
            previewUrl: templateAdmin?.preview?.url,
            downloadUrl: templateAdmin?.preview?.url,
          });
          setTemplateSlug(templateAdmin?.slug);
          setCategorySlug(templateAdmin?.category?.slug);
          setSubCategorySlug(templateAdmin?.subCategory?.slug);
          setTemplateStatus(templateAdmin?.status);
          setCategoryId(templateAdmin?.category?.id);
          setShowOnWeb(templateAdmin?.showOnWeb);
        },
        onError() {},
      });
    }
  }, []);

  const onFinish = async (values) => {
    setIsSubmitLoader(true);
    const prepareValues = values;
    prepareValues.price = Number(prepareValues?.price);
    if (file.isPreviewUrlChange) {
      const fileObj = file.imageFileObject;
      const uploadImagePromise = new Promise((resolve, reject) => {
        getTemplateImageSignedUrlCall({
          fetchPolicy: 'network-only',
          variables: {
            data: {
              contentType: fileObj.type,
              fileName: fileObj.name,
              fileSize: fileObj.size / 1024 / 1024,
              height: 200,
              width: 200,
            },
          },
          onCompleted: async ({ getTemplatePreviewSignedPutUrl }) => {
            try {
              const response = await fetch(
                `${getTemplatePreviewSignedPutUrl?.signedUrl}`,
                {
                  method: 'PUT',
                  body: fileObj,
                },
              );
              if (response?.status === 200) {
                prepareValues.previewUrl =
                  getTemplatePreviewSignedPutUrl?.fileName;
                resolve();
              }
            } catch (error) {
              reject(error);
            }
          },
          onError(error) {
            setIsSubmitLoader(false);
            reject(error);
          },
        });
      });
      await uploadImagePromise;
    }
    if (file.isDownloadUrlChange) {
      const fileObj = file.docFileObject;
      const uploadDocPromise = new Promise((resolve, reject) => {
        getTemplateDocSignedUrlCall({
          fetchPolicy: 'network-only',
          variables: {
            data: {
              contentType: fileObj.type,
              fileName: fileObj.name,
              fileSize: fileObj.size,
            },
          },
          onCompleted: async ({ getTemplateDocSignedPutUrl }) => {
            try {
              const response = await fetch(
                `${getTemplateDocSignedPutUrl?.signedUrl}`,
                {
                  method: 'PUT',
                  body: fileObj,
                },
              );
              if (response?.status === 200) {
                prepareValues.downloadUrl =
                  getTemplateDocSignedPutUrl?.fileName;
                resolve();
              }
            } catch (error) {
              reject(error);
            }
          },
          onError(error) {
            setIsSubmitLoader(false);
            reject(error);
          },
        });
      });
      await uploadDocPromise;
    }
    if (id) {
      prepareValues.categoryId = prepareValues.categoryId.key
        ? prepareValues.categoryId.key
        : prepareValues.categoryId;
      prepareValues.subCategoryId = prepareValues.subCategoryId.key
        ? prepareValues.subCategoryId.key
        : prepareValues.subCategoryId;
      prepareValues.status = templateStatus;
      await updateTemplateCall({
        variables: {
          where: { id },
          data: prepareValues,
        },
        onCompleted() {
          navigate(ROUTES.TEMPLATE);
        },
        onError() {
          setIsSubmitLoader(false);
        },
      });
    } else {
      createTemplateCall({
        variables: { data: prepareValues },
        onCompleted() {
          navigate(ROUTES.TEMPLATE);
        },
        onError() {
          setIsSubmitLoader(false);
        },
      });
    }
  };

  const { required } = formValidatorRules;

  const handleFileChange = async (e, argType) => {
    const fileObj = e.file.originFileObj;
    const previewImg = await getBase64(fileObj);
    if (argType === 'preview') {
      setFile({
        ...file,
        previewUrl: previewImg,
        isPreviewUrlChange: true,
        imageFileObject: fileObj,
      });
      form.setFieldsValue({
        previewUrl: previewImg,
      });
    } else {
      setFile({
        ...file,
        downloadUrl: previewImg,
        isDownloadUrlChange: true,
        docFileObject: fileObj,
      });
      form.setFieldsValue({
        downloadUrl: previewImg,
      });
    }
  };

  const handleCategoryChange = (e) => {
    fetchOptions('SUB_CAT', '', e.value);
    form.setFieldsValue({ categoryId: e.value });
    form.setFieldsValue({ subCategoryId: null });
    setCategoryId(e.value);
  };

  const handleSubCategoryChange = (e) => {
    form.setFieldsValue({ subCategoryId: e.value });
  };

  const handleDocumentDownload = async () => {
    getTemplateDocDownloadUrlCall({
      fetchPolicy: 'network-only',
      variables: {
        where: {
          id,
        },
      },
      onCompleted({ downloadTemplate }) {
        // eslint-disable-next-line no-undef
        window.open(downloadTemplate.url);
      },
      onError() {},
    });
  };

  const handleScroll = async (event, isCat = true) => {
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};
    const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
    const isEnds = isCat ? options.isCategoryEnds : options.isSubCategoryEnds;
    if (scrolledToBottom && !isEnds) {
      let type = 'SUB_CAT';
      let catId = categoryId;
      if (isCat) {
        type = 'CAT';
        catId = null;
      }
      fetchOptions(type, null, catId, true);
    }
  };

  const getUpdateSelection = (status) => {
    switch (status) {
      case TEMPLATE_STATUS?.DRAFT:
        return STATUS_SELECTION.filter(
          (item) =>
            item.value !== TEMPLATE_STATUS.DRAFT &&
            item.value !== TEMPLATE_STATUS.UNPUBLISHED,
        );
      case TEMPLATE_STATUS?.PUBLISHED:
        return STATUS_SELECTION.filter(
          (item) => item.value === TEMPLATE_STATUS.UNPUBLISHED,
        );
      case TEMPLATE_STATUS?.UNPUBLISHED:
        return STATUS_SELECTION.filter(
          (item) => item.value === TEMPLATE_STATUS.PUBLISHED,
        );
      default:
        return null;
    }
  };

  const dropDownItems = useMemo(
    () =>
      getUpdateSelection(templateStatus)?.map((status) => ({
        key: `${status?.value}`,
        label: `${status?.label}`,
      })),
    [templateStatus],
  );

  const wordCountValidator = (_, value) => {
    if (!value) {
      return Promise.resolve();
    }
    const plainText = value ? value.replace(/<[^>]+>/g, '').trim() : '';
    const wordCount = plainText ? plainText.trim().split(/\s+/).length : 0;
    if (wordCount > 1000) {
      return Promise.reject(
        new Error('Please enter a Description with a maximum of 1000 words.'),
      );
    }
    return Promise.resolve();
  };

  const handleRemoveAttachDoc = () => {
    setFile({
      ...file,
      downloadUrl: null,
      isDownloadUrlChange: false,
      docFileObject: null,
    });
  };

  return (
    <>
      <div className="title">
        <Button
          type="text"
          shape="circle"
          onClick={() => navigate(ROUTES.TEMPLATE)}
          icon={<ArrowLeftOutlined />}
        />
        {id ? 'Edit Template' : 'Add Template'}
      </div>
      <Card className="ant-body-scroll">
        <div className="card-body-wrapper d-flex flex-vertical gap-20 ">
          {id &&
            templateStatus !== TEMPLATE_STATUS?.REJECTED &&
            templateStatus !== TEMPLATE_STATUS?.UNPUBLISHED && (
              <div className="d-flex w-full justify-between align-center">
                <Alert
                  className="template-alert"
                  message="Template Status"
                  type="info"
                  showIcon
                  action={
                    <>
                      <Dropdown
                        menu={{
                          items: dropDownItems,
                          onClick: handleTemplateAuthorization,
                        }}
                        trigger={['click']}
                      >
                        <Button type="primary">
                          <Space>
                            Select Status
                            <DownOutlined />
                          </Space>
                        </Button>
                      </Dropdown>
                    </>
                  }
                />
                {templateStatus === TEMPLATE_STATUS?.PUBLISHED &&
                  (role === USER_TYPE.SUPER_ADMIN ||
                    role === USER_TYPE.ADMIN) && (
                    <Link
                      to={`${process.env.REACT_APP_WEB_URL}/templates/${categorySlug}/${subCategorySlug}/${templateSlug}`}
                      target="_blank"
                    >
                      <Button type="primary" className="ml-16" size="large">
                        View Template
                      </Button>
                    </Link>
                  )}
              </div>
            )}
          <Form
            form={form}
            name="register"
            onFinish={onFinish}
            onValuesChange={() => setIsFieldsChanged(true)}
            initialValues={defaultFormValue}
            layout="vertical"
            disabled={unEditableTypeAry?.includes(templateStatus)}
          >
            <Row gutter={16}>
              <Col xs={24} sm={12} md={24}>
                <Row>
                  <Col xs={24} sm={12} md={10}>
                    <Form.Item
                      name="previewUrl"
                      rules={[
                        { required, message: 'Please upload Preview Image' },
                      ]}
                    >
                      <div className="upload-preview-img">
                        {file?.previewUrl === null ? (
                          <Upload
                            listType="picture-card"
                            showUploadList={false}
                            beforeUpload={(e) => beforeUpload(e, 10)}
                            onChange={(e) => handleFileChange(e, 'preview')}
                            accept=".jpeg,.jpg,.png"
                          >
                            Add Preview Image
                          </Upload>
                        ) : (
                          <div className="remove-img-btn">
                            <Image
                              height={150}
                              width={150}
                              src={file.previewUrl}
                            />
                            {!unEditableTypeAry?.includes(templateStatus) && (
                              <div className="close-icon">
                                <Button
                                  className="custom-remove-btn"
                                  shape="rounded"
                                  type="primary"
                                  danger
                                  onClick={() => {
                                    setFile({ ...file, previewUrl: null });
                                    form.setFieldValue('previewUrl', null);
                                  }}
                                  icon={<CloseCircleOutlined />}
                                />
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={12}>
                    <Form.Item
                      name="downloadUrl"
                      rules={[
                        {
                          required,
                          message: 'Please upload Template Document',
                        },
                      ]}
                    >
                      {!file?.docFileObject?.name && !file.downloadUrl ? (
                        <Upload
                          showUploadList={false}
                          beforeUpload={beforeUploadDocFile}
                          onChange={(e) => handleFileChange(e, 'doc')}
                          accept=".doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                        >
                          <Button icon={<UploadOutlined />}>
                            Upload Document
                          </Button>
                        </Upload>
                      ) : (
                        <div className="file-shower">
                          <FileTextOutlined className="file-preview" />
                          {!unEditableTypeAry?.includes(templateStatus) && (
                            <div className="close-icon">
                              <Button
                                icon={<CloseCircleOutlined />}
                                type="primary"
                                shape="rounded"
                                danger
                                disabled={false}
                                onClick={() => {
                                  handleRemoveAttachDoc();
                                  form.setFieldValue('downloadUrl', null);
                                }}
                              />
                            </div>
                          )}
                          {file.downloadUrl && (
                            <div className="download-icon">
                              <Button
                                icon={<DownloadOutlined />}
                                type="primary"
                                shape="rounded"
                                disabled={false}
                                onClick={() => handleDocumentDownload()}
                              />
                            </div>
                          )}
                        </div>
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item
                  name="title"
                  label="Title"
                  rules={[{ required, message: 'Please enter Title' }]}
                >
                  <Input placeholder="Enter your Title" />
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item
                  name="categoryId"
                  label="Category"
                  rules={[{ required, message: 'Please select Category' }]}
                >
                  <DebounceSelectComponent
                    allowClear
                    showSearch
                    fetchOptions={(e) => fetchOptions('CAT', e)}
                    placeholder="Select Category"
                    onChange={(item) => handleCategoryChange(item)}
                    defaultOptions={options.category}
                    onPopupScroll={(e) => handleScroll(e, true)}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item
                  name="subCategoryId"
                  label="Sub Category"
                  rules={[{ required, message: 'Please select Sub-Category' }]}
                >
                  <DebounceSelectComponent
                    allowClear
                    showSearch
                    fetchOptions={(e) => fetchOptions('SUB_CAT', e)}
                    placeholder="Select Sub Category"
                    onChange={(item) => handleSubCategoryChange(item)}
                    defaultOptions={options.subCategory}
                    onPopupScroll={(e) => handleScroll(e, false)}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item
                  name="price"
                  label="Price"
                  rules={[
                    { required, message: 'Please enter Price' },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (value || value === 0) {
                          if (getFieldValue('type') === TEMPLATE_TYPE.FREE) {
                            form.setFieldValue('price', 0);
                          }
                          if (
                            (getFieldValue('type') === TEMPLATE_TYPE.PAID ||
                              getFieldValue('type') ===
                                TEMPLATE_TYPE.PREMIUM) &&
                            Number(value) <= 0
                          ) {
                            return Promise.reject(
                              new Error(
                                'Minimum price should be Rs.1 for premium or paid template',
                              ),
                            );
                          }
                          if (
                            (getFieldValue('type') === TEMPLATE_TYPE.PAID ||
                              getFieldValue('type') ===
                                TEMPLATE_TYPE.PREMIUM) &&
                            Number(value) > MAX_PRICE_LIMIT
                          ) {
                            return Promise.reject(
                              new Error(
                                `Maximum price should be Rs.${MAX_PRICE_LIMIT} for premium or paid template`,
                              ),
                            );
                          }
                          if (!Number.isInteger(Number(value))) {
                            return Promise.reject(
                              new Error(
                                'Please enter an integer value, no decimal allowed.',
                              ),
                            );
                          }
                          return Promise.resolve();
                        }
                        return Promise.reject();
                      },
                    }),
                  ]}
                >
                  <Input type="number" placeholder="Enter Price" />
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item
                  label="Type"
                  name="type"
                  rules={[
                    { required, message: 'Please select Type' },
                    () => ({
                      validator(_, value) {
                        if (value) {
                          if (value === 'FREE') {
                            form.setFieldValue('price', 0);
                          }
                          return Promise.resolve();
                        }
                        return Promise.reject();
                      },
                    }),
                  ]}
                >
                  <Radio.Group>
                    {Object?.keys(TEMPLATE_TYPE)?.map((type) => (
                      <Radio.Button key={type} value={type}>
                        {capitalize(type)}
                      </Radio.Button>
                    ))}
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24}>
                <Form.Item
                  name="description"
                  label="Description"
                  rules={[
                    { required, message: 'Please enter Description' },
                    { validator: wordCountValidator },
                  ]}
                >
                  <ReactQuill
                    theme="snow"
                    formats={EDITOR_FORMATS}
                    modules={EDITOR_MODULES}
                    placeholder="Enter your Description"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item>
              <Space size={8}>
                <Button
                  onClick={() => navigate(ROUTES.TEMPLATE)}
                  htmlType="submit"
                >
                  Cancel
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={!isFieldsChanged}
                  loading={isSubmitLoader}
                  onClick={() => form.handleSubmit}
                >
                  Save
                </Button>
              </Space>
            </Form.Item>
          </Form>
          {id && (
            <Row gutter={16}>
              {templateStatus === TEMPLATE_STATUS.PUBLISHED && (
                <Col span={10}>
                  <ShowOnWeb
                    showOnWeb={showOnWeb}
                    pageType={PAGE_TYPE.TEMPLATE}
                    id={id}
                    placeholder="Select pages to show this template"
                    className="show-on-web-input"
                  />
                </Col>
              )}
            </Row>
          )}
        </div>
      </Card>
      <Modal
        title="Template Rejection Concern"
        open={reasonPopup}
        onOk={() => reasonForm.submit()}
        confirmLoading={isSubmitLoader}
        onCancel={() => {
          setReasonPopup(false);
          reasonForm.setFieldValue('reason', null);
        }}
      >
        <Form
          form={reasonForm}
          name="register"
          onFinish={submitReasonForm}
          labelCol={{ span: 12 }}
          wrapperCol={{ span: 20 }}
          layout="vertical"
        >
          <Form.Item
            name="reason"
            label="Reason"
            rules={[{ required, message: 'Please enter Reason' }]}
          >
            <Input placeholder="Enter your Reason" />
          </Form.Item>
        </Form>
        <Alert
          description=" Warning!! You won't be able to make changes or publish the template once you reject it."
          type="warning"
          showIcon
        />
      </Modal>
    </>
  );
}

export default EditTemplate;
