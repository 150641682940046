import { useMutation } from '@apollo/client';
import { Form, TreeSelect } from 'antd';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { removeTypenameFromObject } from '../common/utils';
import { UPDATE_SHOW_ON_PAGE } from './graphql/queries';

const { SHOW_PARENT } = TreeSelect;

function ShowOnWeb({
  showOnWeb,
  pageType,
  id,
  className = '',
  placeholder = '',
  hide = false,
}) {
  const [pages, setPages] = useState([]);
  const [treeData, setTreeData] = useState([]);
  const [normalizeShowOnWebObj, setNormalizeShowOnWebObj] = useState({});
  const [updateShowOnWebCall] = useMutation(UPDATE_SHOW_ON_PAGE);

  const debouncedUpdatePages = useCallback(
    debounce((value) => {
      const prepareData = { ...normalizeShowOnWebObj };
      Object.keys(prepareData).forEach((item, index) =>
        Object.keys(prepareData[item]).forEach((child, childIndex) => {
          if (
            value.includes(`0-${index}-${childIndex}`) ||
            value.includes(`0-${index}`)
          ) {
            prepareData[item][child].visible = true;
          } else {
            prepareData[item][child].visible = false;
          }
        }),
      );
      updateShowOnWebCall({
        variables: {
          data: {
            showOnWeb: prepareData,
          },
          where: { id, type: pageType },
        },
        onCompleted() {},
        onError() {},
      });
    }, 2000),
    [normalizeShowOnWebObj, updateShowOnWebCall],
  );

  const updatePages = (value) => {
    setPages(value);
    debouncedUpdatePages(value);
  };

  useEffect(() => {
    const showOnWebData = removeTypenameFromObject(showOnWeb);
    const pageValue = [];
    const prepareTreeData = Object.keys(showOnWebData).map((item, index) => ({
      title: `All pages in ${item} `,
      key: `0-${index}`,
      value: `0-${index}`,
      children: Object.keys(showOnWebData[item]).map((child, childIndex) => {
        if (showOnWebData[item][child].visible) {
          pageValue.push(`0-${index}-${childIndex}`);
        }
        return {
          title: `${child.replace('_', ' ')} in ${item}`,
          key: `0-${index}-${childIndex}`,
          value: `0-${index}-${childIndex}`,
        };
      }),
    }));
    setTreeData(prepareTreeData);
    setNormalizeShowOnWebObj(showOnWebData);
    setPages(pageValue);
  }, []);

  return (
    <>
      {!hide ? (
        <Form.Item label="Pages to show" className={className}>
          <TreeSelect
            onChange={updatePages}
            placeholder={placeholder}
            value={pages}
            treeCheckable
            showCheckedStrategy={SHOW_PARENT}
            treeData={treeData}
          />
        </Form.Item>
      ) : null}
    </>
  );
}

export default ShowOnWeb;
