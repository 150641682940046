import { Button, Col, Form, Input, Row, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { mongoClient } from '../../../apollo';
import LoaderComponent from '../../../components/LoaderComponent';
import { EDIT_ASSESSEE_DETAILS } from '../../assesses/graphql/mutations';

function AssesseeDetails({
  loading,
  initialValues = null,
  styling = { sm: 8, md: 8, lg: 8 },
  loadingAssessee,
  onAssesseeUpdate,
}) {
  const [form] = Form.useForm();
  const [assesseeID, setAssesseeID] = useState();

  useEffect(() => {
    // eslint-disable-next-line no-underscore-dangle
    setAssesseeID(initialValues?.assessee?._id);
  }, [initialValues]);

  const updateAssessee = async (value) => {
    try {
      await mongoClient?.mutate({
        mutation: EDIT_ASSESSEE_DETAILS,
        variables: {
          data: {
            pancard: value,
          },
          where: {
            id: assesseeID,
          },
        },
      });
      onAssesseeUpdate?.();
    } catch (error) {
      return error;
    }
  };
  useEffect(() => {
    form.setFieldsValue({
      assessee: initialValues?.assessee?.name ?? '',
      pancard: initialValues?.assessee?.pancard ?? '',
    });
  }, [initialValues, form]);

  const onFinish = (values) => {
    updateAssessee(values?.pancard);
  };

  if (!initialValues && loadingAssessee) return <LoaderComponent />;
  return (
    <div className="card-body-wrapper">
      {initialValues?.assessee ? (
        <Form
          form={form}
          name="assesseeDetails"
          onFinish={onFinish}
          layout="vertical"
          initialValues={{
            assessee: initialValues?.assessee?.name ?? '',
            pancard: initialValues?.assessee?.pancard ?? '',
          }}
        >
          <Row gutter={16}>
            <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
              <Form.Item name="assessee" label="Assessee">
                <Input disabled placeholder="Assessee" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
              <Form.Item name="pancard" label="Pancard">
                <Input placeholder="Pancard" />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item className="d-flex justify-center">
            <Space size={8}>
              <Button type="primary" htmlType="submit" loading={loading}>
                Save Assessee Details
              </Button>
            </Space>
          </Form.Item>
        </Form>
      ) : (
        <span>Assessee data not found</span>
      )}
    </div>
  );
}

export default AssesseeDetails;
