import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Form } from 'antd';
import React, { useEffect, useState } from 'react';
import { mongoClient } from '../../apollo';
import { ROUTES } from '../../common/constants';
import useRouter from '../../hooks/useRouter';
import JudgeForm from './components/JudgeForm';
import { EDIT_JUDGE_DETAILS } from './graphql/mutations';
import { GET_JUDGE_DETAILS } from './graphql/queries';

export default function EditJudge() {
  const { params, navigate } = useRouter();
  const { id } = params;
  const [form] = Form.useForm();
  const [judgeData, setJudgeData] = useState(null);
  const [loadingJudgeDetails, setLoadingJudgeDetails] = useState(true);
  const [updateLoading, setUpdateLoading] = useState(false);
  const getJudge = async ({ variables }) => {
    await mongoClient
      ?.query({
        query: GET_JUDGE_DETAILS,
        fetchPolicy: 'network-only',
        variables,
      })
      ?.then((res) => {
        setLoadingJudgeDetails(false);
        setJudgeData(res?.data);
      })
      ?.catch(() => {
        setLoadingJudgeDetails(false);
      });
  };

  const updateJudge = async ({ variables }) => {
    await mongoClient
      ?.mutate({
        mutation: EDIT_JUDGE_DETAILS,
        variables,
      })
      ?.then(() => {
        setUpdateLoading(false);
        navigate(ROUTES.JUDGES);
      })
      ?.catch(() => {
        setUpdateLoading(false);
      });
  };

  useEffect(() => {
    if (id) {
      getJudge({ variables: { where: { id } } });
    }
  }, [id]);

  const onFinish = (values) => {
    setUpdateLoading(true);
    if (id) {
      updateJudge({
        variables: {
          where: { id },
          data: { ...values },
        },
      });
    }
  };

  return (
    <>
      <div className="title">
        <Button
          type="text"
          shape="circle"
          onClick={() => navigate(ROUTES.JUDGES)}
          icon={<ArrowLeftOutlined />}
        />
        Edit Judge
      </div>
      <JudgeForm
        form={form}
        onFinish={onFinish}
        loading={updateLoading}
        initialValues={judgeData?.judgeAdmin}
        loadingJudgeDetails={loadingJudgeDetails}
      />
    </>
  );
}
