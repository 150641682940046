import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const EDIT_SUBSCRIPTION_PLAN = gql`
  mutation UpdateSubscriptionPlan(
    $data: UpdateSubscriptionPlanInput!
    $where: SubscriptionPlanAdminWhereUniqueInput!
  ) {
    updateSubscriptionPlan(data: $data, where: $where) {
      message
    }
  }
`;
