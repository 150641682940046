import { gql } from '@apollo/client';

export const LIST_ASSESSEESS = gql`
  query AssesseesAdmin(
    $filter: ListAssesseeFilter
    $sort: [ListAssesseeSort!]
  ) {
    assesseesAdmin(filter: $filter, sort: $sort) {
      count
      data {
        _id
        id
        name
        slug
        type
        pancard
        initialOfSurnameOrTitle
        category
        court
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_ASSESSEE_DETAILS = gql`
  query AssesseeAdmin($where: AssesseeIdWhereUniqueInput!) {
    assesseeAdmin(where: $where) {
      _id
      name
      slug
      type
      pancard
      initialOfSurnameOrTitle
      category
      court
      createdAt
      updatedAt
    }
  }
`;
