import { gql } from '@apollo/client';

const GET_LAWYERS = gql`
  query LawyersAdmin($filter: ListLawyerFilter, $sort: [ListLawyerSort!]) {
    lawyersAdmin(filter: $filter, sort: $sort) {
      count
      data {
        _id
        name
        slug
        noSpaceName
        mergedNoSpaceNames
      }
    }
  }
`;

export default GET_LAWYERS;
