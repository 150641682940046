import { useLazyQuery } from '@apollo/client';
import { Button, Table, Typography } from 'antd';
import { filter } from 'lodash';
import React, { useEffect, useState } from 'react';
import {
  CONSULTATION_CALL_TYPE,
  LIMIT,
  MODULES,
  PAYMENT_DISTRIBUTION_KEYS,
  PAYMENT_TYPE,
} from '../../common/constants';
import SearchComponent from '../../components/SearchComponent';
import DistributionModal from './components/DistributionModal';
import { GET_PAYMENT_DISTRIBUTION_CONFIG } from './graphql/queries';

const { Title } = Typography;

function PaymentDistributionList() {
  const [listFilter, setListFilter] = useState({
    limit: LIMIT,
    skip: 0,
    search: '',
  });
  const [sort, setSort] = useState({});
  const [open, setOpen] = useState(false);
  const [selectedConfig, setSelectedConfig] = useState(null);
  const [isTypeFixed, setIsTypeFixed] = useState(false);
  const [getConfig, { data: config, loading }] = useLazyQuery(
    GET_PAYMENT_DISTRIBUTION_CONFIG,
    {
      fetchPolicy: 'network-only',
      onCompleted(res) {
        const data = res?.configs?.data || [];
        const result = filter(
          data,
          (item) => item?.value === CONSULTATION_CALL_TYPE.FIXED.key,
        );
        setIsTypeFixed(result?.length > 0);
      },
      onError: () => {},
    },
  );

  useEffect(() => {
    getConfig();
  }, []);

  const handleTableChange = (pagination, filters, sorter) => {
    const filterPayload = {
      ...listFilter,
      skip: pagination?.current || 0,
      limit: pagination?.pageSize || LIMIT,
    };
    const sortPayload = {
      ...sort,
      sortOn: sorter?.order ? sorter?.field : undefined,
      sortBy: sorter?.order === 'ascend' ? 'ASC' : 'DESC',
    };

    setListFilter(filterPayload);
    setSort(sortPayload);

    getConfig({
      variables: {
        filter: filterPayload,
        ...(sorter?.order && { sort: [sortPayload] }),
      },
    });
  };

  const handleSearchChange = (value) => {
    setListFilter({ ...listFilter, skip: 0, search: value });
    getConfig({
      variables: {
        filter: { ...listFilter, skip: 0, search: value },
        ...(sort?.sortBy && sort?.sortOn && { sort: [sort] }),
      },
    });
  };

  const sortOrder = sort.sortBy === 'ASC' ? 'ascend' : 'descend';

  const columns = [
    {
      title: 'Description',
      dataIndex: 'key',
      sorter: true,
      sortOrder: sort.sortOn === 'key' ? sortOrder : null,
      width: 500,
      render(key) {
        return PAYMENT_DISTRIBUTION_KEYS[key];
      },
    },
    {
      title: 'Value',
      dataIndex: 'value',
      sorter: true,
      align: 'right',
      sortOrder: sort.sortOn === 'value' ? sortOrder : null,
      render(value) {
        if (
          value === CONSULTATION_CALL_TYPE.FIXED.key ||
          value === CONSULTATION_CALL_TYPE.PERCENT.key
        ) {
          return CONSULTATION_CALL_TYPE?.[value]?.label;
        }
        if (
          value === PAYMENT_TYPE.DEFAULT.key ||
          value === PAYMENT_TYPE.SPECIFIC.key
        ) {
          return PAYMENT_TYPE?.[value]?.label;
        }
        return `${value}%`;
      },
    },
    {
      title: 'Action',
      dataIndex: 'id',
      align: 'center',
      fixed: 'right',
      render: (id, record) => (
        <Button
          type="link"
          onClick={() => {
            setOpen(true);
            setSelectedConfig(record);
          }}
        >
          Edit
        </Button>
      ),
    },
  ];

  return (
    <>
      {open && (
        <DistributionModal
          setOpen={setOpen}
          open={open}
          selectedConfig={selectedConfig}
          getConfig={getConfig}
          isTypeFixed={isTypeFixed}
        />
      )}
      <Title className="site-page-header p-0 mb-8 mt-0" level={3}>
        {MODULES.PAYMENT_DISTRIBUTION}
      </Title>
      <div className="filter-input mb-12">
        <SearchComponent className="list-search" getData={handleSearchChange} />
      </div>
      <Table
        columns={columns}
        rowKey={(record) => record?.id}
        dataSource={config?.configs?.data}
        pagination={false}
        loading={loading}
        scroll={{ y: `calc(100vh - 342px)` }}
        onChange={handleTableChange}
      />
    </>
  );
}

export default PaymentDistributionList;
