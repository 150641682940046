import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  Row,
  Select,
  Space,
} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import React, { useEffect } from 'react';
import {
  COURT_LIST,
  JUDGES_TYPE,
  TAX_CATEGORIES,
} from '../../../common/constants';
import { formValidatorRules } from '../../../common/utils';
import LoaderComponent from '../../../components/LoaderComponent';
import useRouter from '../../../hooks/useRouter';

const { required, name: validateName } = formValidatorRules;

function JudgeForm({
  form,
  onFinish,
  loading,
  initialValues = null,
  loadingJudgeDetails = true,
  styling = { sm: 12, md: 8, lg: 6 },
  isModalView = false,
  handleCancel,
}) {
  const { navigate } = useRouter();

  useEffect(() => {
    form.setFieldsValue({
      isVisible: initialValues?.isVisible,
    });
  }, [initialValues]);

  if (loadingJudgeDetails) {
    return <LoaderComponent />;
  }

  const JudgeFormContent = () => (
    <div className="card-body-wrapper">
      <Form
        form={form}
        name="register"
        onFinish={onFinish}
        layout="vertical"
        initialValues={initialValues}
      >
        <Row gutter={16}>
          <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
            <Form.Item
              name="name"
              label="Name"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: 'Please enter Name',
                },
                validateName,
              ]}
            >
              <Input placeholder="Enter your Name" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
            <Form.Item
              name="noSpaceName"
              label="No Space Name"
              rules={[
                {
                  whitespace: true,
                  message: 'Please enter No Space Name',
                },
              ]}
            >
              <Input placeholder="Enter your No Space Name" />
            </Form.Item>
          </Col>

          <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
            <Form.Item
              name="type"
              label="Type"
              className="user-type-input"
              rules={[
                {
                  required: true,
                  message: 'Please select a Type',
                },
              ]}
            >
              <Select placeholder="Select Type">
                {Object.entries(JUDGES_TYPE).map(([value, label]) => (
                  <Select.Option key={value} value={value}>
                    {label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
            <Form.Item
              name="category"
              label="Category"
              className="user-type-input"
              rules={[
                {
                  required: true,
                  message: 'Please select a Category',
                },
              ]}
            >
              <Select placeholder="Select Category" mode="multiple">
                {Object.entries(TAX_CATEGORIES).map(([value, label]) => (
                  <Select.Option key={value} value={value}>
                    {label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
            <Form.Item
              name="slug"
              label="Slug"
              rules={[{ required, message: 'Please enter Slug' }]}
            >
              <Input placeholder="Enter your Slug" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
            <p className="mt-0 mb-8 feature-label">Merged No Space Names</p>
            <Form.List
              name="mergedNoSpaceNames"
              rules={[
                {
                  validator: async (_, names) => {
                    if (!names || names?.length < 1) {
                      return Promise.reject(
                        new Error(
                          'At least 1 Merged No Space Name must be added',
                        ),
                      );
                    }
                  },
                },
              ]}
            >
              {(fields, { add, remove }, { errors }) => (
                <>
                  {fields?.map(({ key, name, ...restField }) => (
                    <Space
                      className="dynamic-cat-input"
                      key={key}
                      align="baseline"
                    >
                      <Form.Item
                        {...restField}
                        name={[name]}
                        rules={[
                          {
                            required: true,
                            message: 'Please enter Merged No Space Name',
                          },
                        ]}
                      >
                        <Input
                          className="input-cat"
                          placeholder="Enter merged No Space Name"
                        />
                      </Form.Item>
                      <MinusCircleOutlined onClick={() => remove(name)} />
                    </Space>
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add Item
                    </Button>
                  </Form.Item>
                  <Form.ErrorList className="category-error" errors={errors} />
                </>
              )}
            </Form.List>
          </Col>

          <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
            <Form.Item
              name="court"
              label="Court"
              rules={[
                {
                  required: true,
                  message: 'At least 1 Court must be added',
                },
              ]}
            >
              <Select placeholder="Select Court" mode="multiple">
                {Object.entries(COURT_LIST).map(([value, label]) => (
                  <Select.Option key={value} value={value}>
                    {label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24}>
            <Form.Item
              name="description"
              label="Description"
              rules={[
                {
                  whitespace: true,
                  message: 'Please enter Description',
                },
              ]}
            >
              <TextArea type="text" placeholder="Enter your Description" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
            <Form.Item name="isVisible" valuePropName="checked">
              <Checkbox defaultChecked={initialValues?.isVisible}>
                Is Visible
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item className={isModalView ? 'text-end' : null}>
          <Space size={8}>
            <Button
              onClick={() => (!isModalView ? navigate(-1) : handleCancel?.())}
            >
              Cancel
            </Button>
            <Button type="primary" htmlType="submit" loading={loading}>
              Save
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  );

  return (
    <>
      {isModalView ? (
        <JudgeFormContent />
      ) : (
        <Card className="ant-body-scroll">
          <JudgeFormContent />
        </Card>
      )}
    </>
  );
}

export default JudgeForm;
