import { CheckOutlined, PlusOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Avatar, Button, Modal } from 'antd';
import AppContext from 'antd/es/app/context';
import React, { useContext, useEffect, useState } from 'react';
import { ASSIGN_TYPE_ENUM, LIMIT } from '../../../common/constants';
import { avtarPlaceholder } from '../../../common/utils';
import { UPDATE_CHAT_MEMBER_MUTATION } from '../graphql/mutations';
import { FETCH_CONSULTANTS } from '../graphql/queries';

function AddConsultant({
  conversationId,
  serviceId,
  handleClose,
  isOpenConsultantMemberManagePopup,
}) {
  const { currentUser } = useContext(AppContext);
  const userIdFromContext = currentUser?.id;
  const [assignChatMember] = useMutation(UPDATE_CHAT_MEMBER_MUTATION, {
    fetchPolicy: 'network-only',
  });
  const [consultants, setConsultants] = useState([]);
  const [selectedConsultants, setSelectedConsultants] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isEnds, setIsEnds] = useState(false);
  const [filters, setFilters] = useState({
    chatId: conversationId,
    limit: LIMIT,
    search: null,
    skip: 0,
  });

  const [fetchConsultantsCall] = useLazyQuery(FETCH_CONSULTANTS, {
    fetchPolicy: 'network-only',
    onError: () => {},
  });

  const fetchConsultants = (filterObj = filters, isScroll = false) => {
    setLoading(true);
    fetchConsultantsCall({
      variables: {
        where: {
          serviceId,
        },
        filter: filterObj,
      },
      onCompleted: (res) => {
        const consultantsData = res?.consultants;
        let prepareConsultants = consultantsData?.data;
        if (isScroll) {
          prepareConsultants = [...consultants, ...prepareConsultants];
        }
        if (consultantsData?.count === prepareConsultants?.length) {
          setIsEnds(true);
        }
        setConsultants(prepareConsultants);
        setLoading(false);
      },
    });
  };
  useEffect(() => {
    fetchConsultants();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleScroll = async (event) => {
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};
    const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 1;
    if (scrolledToBottom && !isEnds) {
      const prepareFilter = filters;
      prepareFilter.skip = consultants?.length;
      setFilters({ ...{}, ...prepareFilter });
      fetchConsultants(prepareFilter, true);
    }
  };

  useEffect(() => {
    // eslint-disable-next-line no-undef
    const layoutContentHolder = document?.getElementsByClassName(
      'manage-member-list',
    )?.[0];
    if (layoutContentHolder) {
      layoutContentHolder?.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (layoutContentHolder) {
        layoutContentHolder?.removeEventListener('scroll', handleScroll);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consultants?.length]);

  const handleAddMember = (item, type) => {
    let prepareSelectedConsultantArray = selectedConsultants;
    if (type === 'REMOVE') {
      const findIndexValue = prepareSelectedConsultantArray?.findIndex(
        (element) => element?.serviceConsultantId === item?.id,
      );
      if (findIndexValue !== -1) {
        prepareSelectedConsultantArray[findIndexValue].type =
          ASSIGN_TYPE_ENUM.REMOVE;
      }
    } else {
      prepareSelectedConsultantArray = [
        ...prepareSelectedConsultantArray,
        {
          serviceConsultantId: item?.id,
          userId: item?.user?.id,
          type: ASSIGN_TYPE_ENUM.ADD,
        },
      ];
    }
    setSelectedConsultants([...prepareSelectedConsultantArray]);
  };

  const handleAddSelectedConsultants = () => {
    assignChatMember({
      variables: {
        input: {
          chatId: conversationId,
          data: selectedConsultants,
        },
      },
      onCompleted: () => {
        handleClose();
      },
      onError: () => {
        handleClose();
      },
    });
  };

  return (
    <Modal
      title="Add Consultant"
      open={isOpenConsultantMemberManagePopup}
      footer={[
        <Button
          key="cancel"
          className="milestone-popup-btn-width"
          size="small"
          onClick={handleClose}
        >
          Cancel
        </Button>,
        <Button
          key="add_selected"
          className="milestone-popup-btn-width"
          size="small"
          type="primary"
          onClick={() => handleAddSelectedConsultants()}
          disabled={!selectedConsultants?.length || loading}
        >
          Add selected
        </Button>,
      ]}
      width={512}
      onCancel={handleClose}
    >
      <div className="d-flex flex-vertical gap-24 ">
        <div className="d-flex flex-vertical gap-6 manage-member-list">
          {consultants?.length > 0 &&
            consultants?.map((item) => (
              <>
                <div
                  key={item?.id}
                  className="d-flex flex-horizontal justify-between align-center pr-2"
                >
                  <div className="d-flex flex-horizontal gap-16">
                    <div className="d-flex align-center">
                      {item?.user?.profileImage ? (
                        <Avatar
                          className="p-4"
                          size={40}
                          src={
                            <img
                              width={40}
                              height={40}
                              src={item?.user?.profileImage}
                              alt={`${item?.user?.firstName} ${item?.user?.lastName}`}
                            />
                          }
                        />
                      ) : (
                        <Avatar size={40}>
                          {avtarPlaceholder(
                            item?.user?.firstName ?? '',
                            item?.user?.lastName ?? '',
                          )}
                        </Avatar>
                      )}
                    </div>
                    <div className="d-flex align-center">{`${item?.user?.firstName} ${item?.user?.lastName}`}</div>
                  </div>
                  <div className="d-flex align-center">
                    {item?.chatMembers?.length ||
                    selectedConsultants.some(
                      (elem) =>
                        elem?.serviceConsultantId === item?.id &&
                        elem?.type !== ASSIGN_TYPE_ENUM.REMOVE,
                    ) ? (
                      <Button
                        icon={<CheckOutlined />}
                        size="small"
                        className="center-icon-btn p-16"
                        /* this code can be used in future */
                        // onClick={() => handleAddMember(item, 'REMOVE')}
                        disabled={userIdFromContext === item?.user?.id}
                      />
                    ) : (
                      <Button
                        icon={<PlusOutlined />}
                        size="small"
                        className="center-icon-btn p-16"
                        onClick={() => handleAddMember(item, 'ADD')}
                        disabled={userIdFromContext === item?.user?.id}
                      />
                    )}
                  </div>
                </div>
              </>
            ))}
        </div>
      </div>
    </Modal>
  );
}

export default AddConsultant;
